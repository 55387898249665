import React from "react";
import Text from "../../components/text/Text";
import { FullPage } from "../../components/containers/Page";
import { StyledBlock, WhiteListForm } from "./WhiteList.Components";
import Button, { ButtonGroup } from "../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import ProgressStepBar from "../../components/progress/ProgressStepBar";
import WhiteListViewModel from "./WhiteList.VM";

const WhiteList = () => {
  const navigate = useNavigate();

  const { viewModel } = WhiteListViewModel();
  return (
    <FullPage width="true" parentMargin="0 -10%">
      <WhiteListForm>
        <StyledBlock className="fullsize">
          <div className="form marginTop">
            <ProgressStepBar currentStep={5}></ProgressStepBar>{" "}
            <div className="whiteList">
              <h1>
                <Text value="HEALTH_CAMPAIGN_TITLE" bold />
              </h1>
              <Text value="HEALTH_CAMPAIGN_TEXT" />{" "}
            </div>
            <div className="bottomTextBtns">
              <Text value="ALL_INFORMATION_SAVED"></Text>
              <ButtonGroup spaceBetween>
                <Button
                  styleType={5}
                  type="button"
                  width="48%"
                  onClick={() => {
                    navigate(viewModel.nextStep());
                  }}
                >
                  <Text value="LATER_CONTINUE" />
                </Button>
                <Button
                  width="48%"
                  onClick={async (event) => {
                    event.preventDefault();
                    viewModel.finish();
                  }}
                >
                  <Text value="NEXT" />
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </StyledBlock>{" "}
      </WhiteListForm>
    </FullPage>
  );
};

export default WhiteList;
