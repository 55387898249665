import styled from "styled-components";

export const StyledSideBar = styled.div`
  .tabSpace {
    margin: 5px 60px;
  }
  .button {
    cursor: pointer;
  }
  .bold {
    font-family: bold;
  }
`;