import { useContext, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { WebServiceContext } from "../../global/WebService/WebServiceState";
import { StorageContext } from "../../global/Storage/StorageState";
import { validEmail } from "../../utils/inviteesUtils";
import { getStepURL, validateDate } from "../../components/progress/ProgressStepBar";

const InviteesViewModel = () => {
  const navigate = useNavigate();
  const { clientWebService, error, setError } = useContext(WebServiceContext);
  const { storageProvider } = useContext(StorageContext);
  const [data, setData] = useState([]);

  const [isBusy, setIsBusy] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [success, setSuccess] = useState("");
  const [onlyShowIncorrect, setOnlyShowIncorrect] = useState(false);

  const clientGlobalId = storageProvider.project.clientGlobalId;
  const projectGlobalId = storageProvider.project.globalId;
  const configurationSteps = storageProvider.project.configurationSteps.data.sort((a, b) => a.order - b.order) || [];
  const currentOrder = configurationSteps.find((x) => x.name === "Invitees" || x.name === "Genodigden").order;

  useEffect(() => {
    setData(onlyShowIncorrect ? storageProvider.employees.filter((x) => !validEmail(x.email)) : storageProvider.employees);
  }, [onlyShowIncorrect, storageProvider.employees]);

  const project = storageProvider.project;

  const nextStep = useCallback(() => {
    return getStepURL(configurationSteps.find((x) => x.order === currentOrder + 1).name);
  }, [configurationSteps, currentOrder]);

  useEffect(() => {
    setShowFilter(storageProvider.employees.some((x) => !validEmail(x.email)));
  }, [storageProvider.employees]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await clientWebService.getInvitees(clientGlobalId, projectGlobalId);
      if (result) {
        storageProvider.setEmployees(result);
      }
    };

    if (!isBusy) {
      setIsBusy(true);
      fetchData().finally(() => setIsBusy(false));
    }
  }, []);

  const toggleShowIncorrect = () => {
    setOnlyShowIncorrect(!onlyShowIncorrect);
  };

  const onUpdate = (old, newObj) => {
    if (!old && !newObj) {
      return false;
    }
    if (newObj.firstname) {
      newObj.email = old.email;
    } else if (newObj.email) {
      newObj.firstname = old.firstname;
    }
    if (newObj.firstname === old.firstname && newObj.email === old.email) {
      return;
    }

    let updatedEmployees = [...storageProvider.employees];
    const index = updatedEmployees.findIndex((x) => x === old);
    updatedEmployees[index] = { ...old, ...newObj };

    storageProvider.setEmployees(updatedEmployees);

    if (validEmail(newObj.email) && !validEmail(old.email)) {
      postEmp(updatedEmployees.filter((x) => validEmail(x.email)));
    }
  };

  const onRemove = (employee) => {
    if (!validEmail(employee.email)) {
      storageProvider.setEmployees(storageProvider.employees.filter((x) => x !== employee));
    } else {
      clientWebService.deleteInvitee(employee, () => storageProvider.setEmployees(storageProvider.employees.filter((x) => x !== employee)), project.globalId);
    }
  };

  const postEmp = async (emp) => {
    if (!emp.length) return;
    const response = await clientWebService.postInvitees(
      emp.filter((obj) => delete obj.cell).filter((x) => !x.globalId),
      clientGlobalId,
      projectGlobalId
    );

    if (response.length) {
      setSuccess("SUCCESS");

      storageProvider.setEmployees((prevEmployees) => [
        ...prevEmployees,
        ...response.map((newEmp) => {
          const existingEmp = prevEmployees.find((emp) => emp.email === newEmp.email);
          return existingEmp ? { ...existingEmp, ...newEmp } : newEmp;
        }),
      ]);
    }
  };

  const addEmployee = (rows) => {
    setError(null);
    let dirtyRows = rows.map((x) => ({ ...x }));
    let updatedEmployees = [...dirtyRows.filter((x) => !validEmail(x.email)), ...storageProvider.employees.filter((x) => validEmail(x.email))];
    storageProvider.setEmployees(updatedEmployees);
    postEmp(dirtyRows.filter((x) => validEmail(x.email)));
  };



  const deleteInvitees = async (transformedInvitees) => {
    if (!transformedInvitees.length) return;
    const remainingInvitees = transformedInvitees.filter((invitee) => {
      const employee = storageProvider.employees.find((emp) => emp.email === invitee);

      if (employee) {
        storageProvider.setEmployees((prevEmployees) => prevEmployees.filter((emp) => emp.email !== invitee));
        return false;
      }
      return true;
    });

    const toDeleteInvitees = transformedInvitees.filter((invitee) => !storageProvider.employees.some((emp) => emp.email === invitee));

    if (!remainingInvitees.length) return;
    else if (toDeleteInvitees.length) {
      const response = await clientWebService.deleteInvitees(toDeleteInvitees, clientGlobalId, projectGlobalId);
      if (response.status === 204) {
        setSuccess("SUCCESS_DELETED");
        storageProvider.setEmployees((prevEmployees) => prevEmployees.filter((emp) => !transformedInvitees.includes(emp.globalId)));
      }
    }
  };

  const finish = async () => {
    const steps = storageProvider.project.configurationSteps.data;
    if (validateDate(steps.find((x) => x.name === "Invitees" || x.name === "Genodigden").created)) {
      navigate(nextStep());
      return;
    }

    const response = await clientWebService.finishStep(project.globalId, project.configurationSteps.data.find((x) => x.name === "Invitees" || x.name === "Genodigden").globalId);
    if (response.globalId) {
      const index = steps.findIndex((step) => step.globalId === response.globalId);
      if (index !== -1) {
        steps[index] = response;
      }

      storageProvider.project.configurationSteps.data = steps;
      storageProvider.setProject(storageProvider.project);
      navigate(nextStep());
      setIsBusy(false);
    }
  };

  return {
    viewModel: {
      data,
      error,
      isBusy,
      onUpdate,
      onRemove,
      toggleShowIncorrect,
      onlyShowIncorrect,
      addEmployee,
      showFilter,
      success,
      nextStep,
      finish,
      deleteInvitees,
    },
  };
};

export default InviteesViewModel;
