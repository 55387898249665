import { useState } from "react";

import axios from "axios";

import { doApiCall, handleResponse } from "../../../utils/services";

function useClientWebService(appProvider, baseUrl, domain, createHeaders, setError) {
  const uri = baseUrl.replace("{domain}", domain);
  const [isBusy, setIsBusy] = useState("");

  async function registration(email) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.post(
        `${uri}/client/registration/new`,
        {
          email: email,
        },
        {
          headers: createHeaders(),
        }
      );
    });
    if (handleResponse(result, setError)) {
      return result.status;
    }
    return null;
  }
  async function getUsername(rfid) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.get(`${uri}/client/registration/${rfid}`, {
        headers: createHeaders(),
      });
    });
    if (handleResponse(result, setError)) {
      return result.data;
    }
    return null;
  }

  async function register(username, password, acceptsPrivacy, newsletter) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.post(
        `${uri}/client`,
        { userName: username, password: password, acceptsPrivacy: acceptsPrivacy, newsletter: newsletter },
        {
          headers: createHeaders(),
        }
      );
    });
    if (handleResponse(result, setError)) {
      return result.data;
    }
    return null;
  }

  async function getDefaultQuestions() {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.get(`${uri}/Question/default/`, {
        headers: createHeaders(),
      });
    });
    if (result && result.status && handleResponse(result, setError)) {
      return result.data;
    }
    return null;
  }

  async function getAllProjects(token) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.get(`${uri}/project/user/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": "nl",
        },
      });
    });
    if (handleResponse(result, setError)) {
      return result.data;
    }
    return null;
  }

  async function postAnswers(answers, index, projectQuestionIdentifier, projectIdentifier) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.post(
        `${uri}/Question/project/${projectIdentifier}/${projectQuestionIdentifier}/answer`,
        {
          ...{ index: index },
          ...answers,
        },

        {
          headers: createHeaders(),
        }
      );
    });
    if (handleResponse(result, setError)) {
      return result.data;
    }
    return null;
  }

  async function editAnswers(answer, projectId, projectQuestionGlobalId, globalId) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.put(
        `${uri}/Question/project/${projectId}/${projectQuestionGlobalId}/answer/${globalId}`,

        answer.metadata,
        {
          headers: createHeaders(),
        }
      );
    });
    if (handleResponse(result, setError)) {
      return result.data;
    }
    return null;
  }

  async function deleteQ(questionId, projectId) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.delete(
        `${uri}/Question/project/${projectId}/${questionId}`,

        {
          headers: createHeaders(),
        }
      );
    });
    if (result.status === 204) {
      return "DELETE_SUCCESS";
    }
    return null;
  }

  async function deleteAnswer(answer, projectId) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.delete(`${uri}/Question/project/${projectId}/${answer.projectQuestionGlobalId}/answer/${answer.globalId}`, {
        headers: createHeaders(),
      });
    });
    if (result.status === 204) {
      return "DELETE_SUCCESS";
    }
    return null;
  }
  async function getProjectQuestions(projectId) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.get(`${uri}/Question/project/${projectId}`, {
        headers: createHeaders(),
      });
    });
    if (result && result.status && handleResponse(result, setError)) {
      return result.data;
    }
    return null;
  }

  async function postDefaultQuestion(questionId, projectId) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.post(
        `${uri}/Question/${questionId}/project/${projectId}`,
        {},
        {
          headers: createHeaders(),
        }
      );
    });
    if (handleResponse(result, setError)) {
      return result.data;
    }
    return null;
  }

  async function postCustomQuestion(metadata, projectId) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.post(
        `${uri}/Question/project/${projectId}`,
        {
          metadata,
        },
        {
          headers: createHeaders(),
        }
      );
    });
    if (handleResponse(result, setError)) {
      return result.data;
    }
    return null;
  }
  async function getPackages(numberOfParticipant, cultureCode) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.get(`${uri}/client/list/quote/entry/default?cultureCode=${cultureCode}&ParticipantCount=${numberOfParticipant}`, {
        headers: createHeaders(),
      });
    });
    if (handleResponse(result, setError)) {
      return result.data.data;
    }
    return null;
  }

  async function getAllQuotes(clientGlobalId) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.get(`${uri}/client/list/quote/entry/${clientGlobalId}`, {
        headers: createHeaders(),
      });
    });
    if (handleResponse(result, setError)) {
      return result.data.data;
    }
    return null;
  }

  async function getQuoteData(quoteGlobalId) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.get(`${uri}/client/quote/${quoteGlobalId}`, {
        headers: createHeaders(),
      });
    });
    if (handleResponse(result, setError)) {
      return result.data.data;
    }
    return null;
  }
  async function putQuoteAccept(quoteGlobalId) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.put(`${uri}/client/quote/acceptconditions/${quoteGlobalId}`, {
        headers: createHeaders(),
      });
    });
    if (handleResponse(result, setError)) {
      return result ? result.data.data : false;
    }
    return null;
  }

  async function putOfferCancel(quoteGlobalId) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.delete(`${uri}/client/quote/${quoteGlobalId}`, {
        headers: createHeaders(),
      });
    });
    if (handleResponse(result, setError)) {
      return result ? result.data.data : false;
    }
    return null;
  }
  async function getPackagesByCode(code, cultureCode) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.get(`${uri}/client/list/quote/entry/byCode?entryCode=${code}&cultureCode=${cultureCode}`, {
        headers: createHeaders(),
      });
    });
    if (handleResponse(result, setError)) {
      return result ? result.data.data : false;
    }
    return null;
  }
  async function getInvitees(quoteGlobalId, clientGlobalId) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.get(`${uri}/Client/list/invitee?ClientGlobalId=${clientGlobalId}`, {
        headers: createHeaders(),
      });
    });
    setError(null);
    if (result && handleResponse(result, setError)) {
      return result.data.data;
    }
    return null;
  }
  async function postInvitees(data, clientGlobalId, projectGlobalId) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.post(
        `${uri}/client/${clientGlobalId}/invitee/project/${projectGlobalId}`,
        {
          data: data,
        },
        {
          headers: createHeaders(),
        }
      );
    });
    if (handleResponse(result, setError)) {
      return result ? result.data.data : false;
    }
    return null;
  }
  async function postState(projectGlobalId, state) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.post(
        `${uri}/project/${projectGlobalId}/state`,
        {
          state: state,
        },
        {
          headers: createHeaders(),
        }
      );
    });
    if (handleResponse(result)) {
      return result.data;
    }
    return null;
  }

  async function getShipments(clientGlobalId, projectGlobalId) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.get(`${uri}/Shipment/client/${clientGlobalId}/project/${projectGlobalId}`, {
        headers: createHeaders(),
      });
    });

    if (handleResponse(result, setError)) {
      return result.data;
    }
    return null;
  }
  async function newShipment(clientGlobalId, projectGlobalId, data) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.post(`${uri}/Shipment/client/${clientGlobalId}/project/${projectGlobalId}`, data, {
        headers: createHeaders(),
      });
    });
    setError(null);
    if (handleResponse(result, setError)) {
      return result.data;
    }
    return null;
  }

  async function deleteShipment(clientGlobalId, projectGlobalId, id) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.delete(`${uri}/Shipment/${id}/client/${clientGlobalId}/project/${projectGlobalId}`, {
        headers: createHeaders(),
      });
    });
    if (result.status === 204) {
      return "DELETE_SUCCESS";
    }
    return null;
  }
  async function getProjectData(projectGlobalId) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.get(`${uri}/project/${projectGlobalId}/statistics`, {
        headers: createHeaders(),
      });
    });
    setError(null);
    if (handleResponse(result, setError)) {
      return result.data;
    }
    return null;
  }

  async function finishStep(projectIdentifier, configurationStepGlobalId) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.post(
        `${uri}/project/${projectIdentifier}/configuration-step/${configurationStepGlobalId}`,
        {},
        {
          headers: createHeaders(),
        }
      );
    });
    if (handleResponse(result, setError)) {
      return result.data;
    }
    return null;
  }

  return {
    registration,
    register,
    getPackages,
    getPackagesByCode,
    putOfferCancel,
    putQuoteAccept,
    getInvitees,
    postInvitees,
    getAllQuotes,
    getQuoteData,
    getDefaultQuestions,
    getProjectQuestions,
    postAnswers,
    editAnswers,
    postDefaultQuestion,
    postCustomQuestion,
    deleteQ,
    deleteAnswer,
    getAllProjects,
    postState,
    getShipments,
    newShipment,
    deleteShipment,
    getUsername,
    getProjectData,
    finishStep,
  };
}

export default useClientWebService;
