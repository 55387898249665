import React, { useContext, useState } from "react";
import styled from "styled-components";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";

export default function Dropdownlink({ title, children, dropdown }) {
  const [isVisible, setVisible] = useState(false);

  return (
    <StyledDropdownLink>
      <div className="menu">
        <div className="menu-item">
          <div
            className="menu_title"
            onClick={() => {
              setVisible(!isVisible);
              dropdown(isVisible);
            }}
          >
            {title}
          </div>

          {isVisible && <div className="submenu"> {children}</div>}
        </div>
      </div>
    </StyledDropdownLink>
  );
}

const StyledDropdownLink = styled.div`
  .menu_title {
    display: flex;
  }
`;
