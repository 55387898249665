import { useState } from "react";

import styled, { keyframes } from "styled-components";

import Text from "../text/Text";

export const Seperator = styled.hr`
  width: 100%;
  color: var(--color-grey);
`;
const Accordion = (data) => {
  const [open, setOpen] = useState(false);
  return (
    <Container margin={data.margin} backgroundColor={data.backgroundColor} open={open} padding={data.padding}>
      <Title
        position={data.position}
        active={data.active}
        open={open}
        onClick={() => setOpen(!open)}
        color={data.color}
        icon={data.icon}
        iconAfter={data.children && data.iconAfter}
        padding={data.padding}
        backgroundColor={data.backgroundColor}
      >
        {typeof data.title === "string" ? <Text text={data.title} /> : data.title}
      </Title>

      {open && (
        <Content key="Content" open={open}>
          {data.children}
        </Content>
      )}

      {data.seperator && <Seperator />}
    </Container>
  );
};

export default Accordion;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${(props) => (props.backgroundColor ? props.backgroundColor : "rgb(255, 255, 255)")};
  padding: ${(props) => props.padding};
  align-self: stretch;
  margin: ${(props) => (props.margin ? props.margin : "16px 0px")};
  width: 100%;
  border: none;
  @media (max-width: 768px) {
    width: 100%;
  }
  :hover {
    border-color: var(--color-light-grey);
  }
`;

const Title = styled.div`
  text-align: left;
  width: 100%;
  display: flex;
  padding: ${(props) => props.padding};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.position ? props.position : "space-around")};
  color: ${(props) => props.color};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "white")};
  cursor: pointer;
  font-weight: bold;

  ${(props) =>
    props.icon &&
    `::before {
                content: ' ';   
                background-image: url(${props.icon});
                background-position:center;
                background-size:25px 25px;
                width:25px;
                height:25px;
                border-radius:50%;
                background-color: ${props.color};
                font-size:18px;
                font-family: fontAwesome;
                float:left;
                margin-right:15px;
                color:white;
                text-align:center;
            }`};

  ${(props) =>
    props.iconAfter &&
    `::after {
   content: url(data:image/svg+xml,%3Csvg%20id%3D%22noun-arrow-down-2424963-0060A8%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2213.864%22%20height%3D%227.771%22%20viewBox%3D%220%200%2013.864%207.771%22%3E%0A%20%20%3Cpath%20id%3D%22noun-arrow-down-2424963-0060A8-2%22%20data-name%3D%22noun-arrow-down-2424963-0060A8%22%20d%3D%22M13.329%2C119.5a.841.841%2C0%2C0%2C0-.594%2C1.435l6.09%2C6.09a.855.855%2C0%2C0%2C0%2C.594.246.83.83%2C0%2C0%2C0%2C.594-.246l6.081-6.082a.837.837%2C0%2C1%2C0-1.169-1.2h0l-5.5%2C5.506-5.506-5.506a.843.843%2C0%2C0%2C0-.594-.246Z%22%20transform%3D%22translate%28-12.49%20-119.5%29%22%20fill%3D%22%23${
     props.open ? `e7336f` : `012856`
   }%22%2F%3E%0A%3C%2Fsvg%3E%0A);
   font-size: 24px;
    font-family: fontAwesome;
    transform: rotate(${props.open ? "180" : "0"}deg);
    margin-top: ${props.open ? "20px" : "-5px"};
    float: right;
  };`}
  ${(props) =>
    props.active &&
    `
  background-color:var(--color-pink);
  color:white;
  
  `}
`;

const Content = styled.div`
  animation: ${(props) => (props.open ? SlideDown : SlideUp)} 1.3s ease;
  overflow: hidden;
  height: auto;
  color: var(--color-font-black);
  white-space: pre-line;
  width: 100%;
`;

const SlideDown = keyframes`
    from {
        max-height: 0;
    }
    to {
        max-height:1000px;
    }
`;

const SlideUp = keyframes`
    from {
        max-height: 1000px;
    }
    to {
        max-height:0;
    }
`;
