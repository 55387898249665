import React, { useContext } from "react";
import styled from "styled-components";
import Text from "../../components/text/Text";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";

const Error = ({ value }) => {
  if (!value) return "";

  return (
    <Container>
      <Text value={value} />
    </Container>
  );
};

export default Error;

const Container = styled.div`
  display: flex;
  width: ${(props) => props.width};
  font-size: 12px;
  flex-direction: row;
  align-items: center;
  position: static;
  margin-bottom: 1em;
  align-items: self-start;
  color: ${(props) => (props.color ? props.color : "var(--color-error)")};
  background-color: #ecc2c2e5;
  border-radius: 2px;
  padding: 5px;
  span {
    font-weight: 400;
    margin: auto 10px;
  }
  img {
    margin: 5px 0px 0px 15px;
    width: 8px;
    background: ${(props) => (props.notValid ? "#ff000012" : "")};
    font-size: 12px;
  }

  border-radius: ${(props) => (props.input ? "var(--corner-radius-small-bottom)" : "var(--corner-radius-small")};
`;

export const ErrorValidation = (props) => {
  const { configurationProvider } = useContext(ConfigurationContext);
  if (!props.value) return "";
  return (
    <Container {...props}>
      <img src={configurationProvider.cdn + "client/close.svg"} alt="check" />

      <Text value={props.value} />
    </Container>
  );
};
