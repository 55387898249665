import React, { useContext } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import "../vendor/css/font-awesome.min.css";

import styled from "styled-components";

import ProtectedRoute from "../components/routes/ProtectedRoute";

import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Login from "./login/Login";
import Home from "./home/Home";

import { AppContext } from "../global/App/AppState";

import ScrollToTop from "../hooks/ScrollToTop";
import Macroreport from "./macroreport/Macroreport";
import Offer from "./offer/offer";
import Registration from "./registration/Registration";
import Register from "./register/Register";
import Code from "./code/Code";
import Company from "./company/Company";
import Modules from "./modules/Modules";
import Accepted from "./accepted/accepted";
import Invitees from "./invitees/Invitees";
import ForgotPassword from "./forgotpassword/ForgotPassword";

import ResetPassword from "./resetpassword/ResetPassword";
import { UserContext } from "../global/User/UserState";
import SidebarNew from "../components/sidebar/SideBarNew";
import Dashboard from "./dashboard/Dashboard";

import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./errorPage/errorPage";
import Bloodpressure from "./bloodpressure/Bloodpressure";
import ProjectQuestions from "./projectquestions/ProjectQuestions";
import ContactDetails from "../components/contactdetails/contactdetails";
import WelcomePage from "./welcome/WelcomePage";
import Communication from "./communication/Communication";
import FinishPage from "./finish/FinishPage";
import RegisterUser from "./registeruser/RegisterUser";
import WhiteList from "./whitelist/WhiteList";
function ReactErrorBoundary(props) {
  return (
    <ErrorBoundary
      key={window.location.pathname}
      FallbackComponent={ErrorPage}
      onError={(error) => {
        console.error(error);
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
}

const App = () => {
  const { appProvider } = useContext(AppContext);

  const { userProvider } = useContext(UserContext);
  new URLSearchParams(window.location.search).forEach(function (value, key) {
    appProvider.setParameter(key, value);
  });

  return (
    <Container border="0" cellSpacing="0">
      <tbody>
        <tr>
          <td colSpan={3}>
            <Header />
          </td>
        </tr>
        <tr>
          <BrowserRouter>
            <td className={userProvider.roles.includes("Client") || userProvider.roles.includes("ClientManager") ? "sidebar" : ""}>
              {(userProvider.roles.includes("Client") || userProvider.roles.includes("ClientManager")) && <SidebarNew className="sdbar" />}
            </td>
            <td>
              <Content>
                <ScrollToTop>
                  <ReactErrorBoundary>
                    <Routes>
                      <Route path="/" element={<Login />} />
                      <Route path="registration" element={<Registration />} />
                      <Route path="company" element={<Company />} />

                      <Route path="modules" element={<Modules />} />
                      <Route path="code" element={<Code />} />
                      <Route path="login" element={<Login />} />
                      <Route path="forgotpassword" element={<ForgotPassword />} />

                      <Route path="resetpassword" element={<ResetPassword />} />
                      <Route path="register" element={<Register />} />

                      <Route path="password/create" element={<RegisterUser />} />

                      <Route
                        path="whitelist"
                        element={
                          <ProtectedRoute roles={["ClientManager", "SysAdmin", "Client"]}>
                            {" "}
                            <WhiteList />
                          </ProtectedRoute>
                        }
                      />
                      <Route path="/" element={<Login />} />
                      <Route
                        path="/controle"
                        element={
                          <ProtectedRoute roles={["ClientManager", "SysAdmin", "Client"]}>
                            <FinishPage />
                          </ProtectedRoute>
                        }
                      />

                      <Route
                        path="welcome"
                        element={
                          <ProtectedRoute roles={["ClientManager", "SysAdmin", "Client"]}>
                            <WelcomePage />
                          </ProtectedRoute>
                        }
                      />

                      <Route
                        path="onboarding"
                        element={
                          <ProtectedRoute roles={["ClientManager", "SysAdmin", "Client"]}>
                            <Communication />
                          </ProtectedRoute>
                        }
                      />

                      <Route
                        path="communication"
                        element={
                          <ProtectedRoute roles={["ClientManager", "SysAdmin", "Client"]}>
                            <Communication />
                          </ProtectedRoute>
                        }
                      />

                      {/* <Route
                        path="invitees"
                        element={
                          <ProtectedRoute roles={[Client]}>
                            <Invitees />
                          </ProtectedRoute>
                        }
                      /> */}
                      <Route
                        path="invitees"
                        element={
                          <ProtectedRoute roles={["ClientManager", "SysAdmin", "Client"]}>
                            <Invitees />{" "}
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="bloodpressure"
                        element={
                          <ProtectedRoute roles={["ClientManager", "SysAdmin", "Client"]}>
                            <Bloodpressure />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="projectquestions"
                        element={
                          <ProtectedRoute roles={["ClientManager", "SysAdmin", "Client"]}>
                            <ProjectQuestions />
                          </ProtectedRoute>
                        }
                      />

                      {/* <Route path="projectquestions" element={<ProjectQuestions />} /> */}
                      <Route path="dashboard" element={<Dashboard />} />
                      <Route
                        path="home"
                        element={
                          <ProtectedRoute roles={["ClientManager", "SysAdmin", "Client"]}>
                            <Home />
                          </ProtectedRoute>
                        }
                      />
                      <Route path="offer" element={<Offer />} />
                      <Route path="accepted" element={<Accepted />} />
                      <Route
                        path="macroreport"
                        element={
                          <ProtectedRoute roles={["ClientManager", "SysAdmin", "Client"]}>
                            <Macroreport />
                          </ProtectedRoute>
                        }
                      />
                    </Routes>
                  </ReactErrorBoundary>
                </ScrollToTop>
              </Content>
            </td>
            <td>{(userProvider.roles.includes("Client") || userProvider.roles.includes("ClientManager")) && <ContactDetails className="sdbar" />}</td>
            <td />
          </BrowserRouter>
        </tr>
        {/* <tr /> */}
        <tr>
          <td colSpan={3}>
            <Footer />
          </td>
        </tr>
      </tbody>
    </Container>
  );
};

export default App;

const Container = styled.table`
  width: 100%;
  max-width: 100%;
  min-height: 100%;

  tr:first-child {
    height: 0;
  }

  // tr:nth-child(2),
  // tr:nth-child(4) {
  //   height: 16px;
  // }

  tr:nth-child(2) td:nth-child(2) {
    overflow: hidden;
    padding: 7%;
    margin: 16px;
  }

  tr:nth-child(3) td:nth-child(2) > img {
    position: absolute;
    width: 20%;
    left: auto;
    right: 18px;
    z-index: 1;
    top: 88px;
  }

  tr:nth-child(3) td:first-child,
  tr:nth-child(3) td:last-child {
    width: 16px;
  }

  tr:last-child {
    height: 0;
  }

  td {
    padding: 0px;
    vertical-align: top;
  }
  .sidebar {
    z-index: 1000;
    width: calc(28px + 1px);
    border: 3px solid #f3f4f4;
  }
`;

const Content = styled.div`
  position: relative;
  min-height: 240px;
  // z-index: 2;
`;
