import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WebServiceContext } from "../../global/WebService/WebServiceState";

const ResetPasswordViewModel = () => {
  const navigate = useNavigate();

  const { identityWebService, error } = useContext(WebServiceContext);
  const [username, setUsername] = useState("");
  const [date, setDate] = useState(false);
  const [pageError, setPageError] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  const [state, setState] = useState({ password: "", passwordCheck: "" });
  const [notEqual, onEqual] = useState(false);
  const [valid, onValidationChange] = useState(true);

  async function sendPasswords(token) {
    if (state.password !== state.passwordCheck) {
      onEqual(true);
      return;
    } else onEqual(false);

    if (!isBusy) {
      // storageProvider.clear();
      const response = await identityWebService.resetPassword(username, `${state.password}`, token);
      if (response.dateTime) {
        setDate(response.dateTime);
        navigate("/login");
      }
      // if (userProvider.initRoles(await identityWebService.postLogin(username, password))) navigate("/home");
      // else setPageError("ERROR_NO_ROLES");

      setIsBusy(false);
    }
  }

  return {
    viewModel: {
      username: username,
      setUsername: setUsername,
      sendPasswords: sendPasswords,
      date: date,
      onValidationChange: onValidationChange,
      notEqual: notEqual,
      onEqual: onEqual,
      valid: valid,
      state: state,
      setState: setState,
      pageError: pageError,
      setPageError: setPageError,
      error: error,
      isBusy: isBusy,
    },
  };
};

export default ResetPasswordViewModel;
