import styled from 'styled-components';
import React from "react";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  mix-blend-mode: normal;

  width: 100%;
`;

const isFunction = (functionToCheck) => {
  var getType = {};
  return functionToCheck && getType.toString.call(functionToCheck) === "[object Function]";
};

const Form = (props) => {
  const onSubmit = (event) => {
    event.preventDefault();

    if (isFunction(props.onSubmit)) {
      props.onSubmit(event);
    }
  };

  return (
    <StyledForm onSubmit={onSubmit} {...props}>
      {props.children}
    </StyledForm>
  );
};

export default Form;


