import React, { useState, useEffect } from "react";
import Error from "../../components/containers/Error";
import Page from "../../components/containers/Page";
import FlexBox from "../../components/containers/FlexBox";
import Wizard from "../../components/wizard/wizard";
import Intro from "../../components/intro/Intro";
import Text from "../../components/text/Text";
import Input from "../../components/inputs/Input";
import Button, { ButtonPlus } from "../../components/buttons/Button";
import Password from "../../components/inputs/password";
import Checkbox from "../../components/inputs/Checkbox";
import CodeViewModel from "./Modules.VM";
import { Background, ChooseModules, MinDiv, ModulesFrame, Price, SelectedModules, Type } from "./Modules.Components";
import MarginBox from "../../components/containers/MarginBox";
import { Back } from "../../components/back/back";
import Accordion from "../../components/accordion/Accordion";
import Title from "../../components/text/Title";
import { DataTable } from "../../components/report/Tables";
const getTablePrices = (products) => {
  let total = Math.round(products.reduce((sum, { price, productCount }) => sum + price * productCount, 0) * 100) / 100;

  return (
    <div className="Table">
      <div className="TableHead">
        <div className="TableRow HeadValue">
          {["PRODUCT", "NUMBER", "PRICE_PER_PIECE", "TOTAL_PRICE_PRODUCT"].map((item, index) => (
            <div className="TableCell ">{<Text value={item} />}</div>
          ))}
        </div>
      </div>
      <div className="TableBody">
        {products.map((p) => (
          <div className="TableRow">
            <div className="TableCell">
              {" "}
              <Accordion iconAfter margin position="space-between" title={p.productName} elements={"elements"} />
            </div>
            <div className="TableCell">{p.productCount}</div>
            <div className="TableCell">{p.price}</div>
            <div className="TableCell">{Math.round(p.productCount * p.price * 100) / 100}</div>
          </div>
        ))}
      </div>
      <div className="TableFooter">
        <div className="TableRow total">
          <div className="TableCell">
            <Text value={"TOTAL"} />
          </div>
          <Price padding="0 20px;" center size="20px">
            <div> € {total}</div>
            <div> exclusive BTW</div>
          </Price>
        </div>
        <div className="TableRow">
          <Button width="40%">
            <Text value="NEXT" />
          </Button>
        </div>
      </div>
    </div>
  );
};
const getDifferentModules = (selected, viewModel) => {
  const getTitleModules = (title, type) => {
    return (
      <div>
        <h2>
          {" "}
          <Text color="var(--color-primary)" value={title} />
        </h2>{" "}
        {/* type is not from backend */}
        {/* <Type>{type}</Type> */}
      </div>
    );
  };
  if (selected.globalId)
    return (
      <ModulesFrame>
        {selected.products.data.map((x, i) => (
          <div className="box" key={i}>
            {" "}
            <Accordion padding="10px" iconAfter position="space-between" backgroundColor="#ffffff00" title={getTitleModules(x.productName, "Online vragenlijst")} />
            <div className="flex">
              {" "}
              <Price left small width="200px">
                <div> € {x.price}</div>
                <div> per medewerker</div>
              </Price>
              <ButtonPlus selected={viewModel.products.filter((z) => z.globalId === x.globalId).length} onClick={() => viewModel.selectProducts(x)} height="40px"></ButtonPlus>
            </div>
          </div>
        ))}
      </ModulesFrame>
    );
};
function Modules() {
  const { viewModel } = CodeViewModel();
  const textSteps = ["ACCOUNT", "COMPANY_DETAILS", "MODULES"];
  return (
    <div>
      {" "}
      <Page minHeight="auto">
        {" "}
        <Wizard done={100} textSteps={textSteps}></Wizard>
      </Page>
      <Background></Background>
      <MinDiv width="100%">
        <div className="center">
          <div className="packages">
            {viewModel.packages.length > 0 &&
              viewModel.packages.map((pck) => (
                <div key={pck.globalId}>
                  <h3>{pck.packageName}</h3>
                  <div className="content">
                    <div>
                      {" "}
                      {pck.products.data.map((product) => (
                        <Accordion padding="5px 0" iconAfter position="space-between" margin="0px 0px 5px 0px" seperator backgroundColor="#ffffff00" title={product.productName} />
                      ))}
                      {pck.description}
                    </div>
                    <div>
                      {" "}
                      <Price>
                        {/* price not correct */}
                        <div> € 111</div>
                        <div> per medewerker</div>
                      </Price>
                      <Button selected={pck.globalId === viewModel.selected.globalId} onClick={() => viewModel.selectPackage(pck.globalId)}>
                        <Text value={pck.globalId === viewModel.selected.globalId ? "SELECTED" : "SELECT"} />{" "}
                      </Button>
                    </div>
                  </div>
                </div>
              ))}

            {/* <div className="highDiv">
              <div className="mostChoosen">
                <div className="label">Most choosen</div>
                <h3>Title of Div</h3>
                <div className="content">
                  <Accordion padding="10px" iconAfter position="space-between" margin="0px 0px 16px 0px" seperator backgroundColor="#ffffff00" title="x" />
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took
                  a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially
                  unchanged.
                  <Price>
                    <div> € 111</div>
                    <div> per medewerker</div>
                  </Price>
                  <Button>Choose </Button>
                </div>
              </div>
            </div> */}
            {/* <div>
              <h3>Title of Div</h3>
              <div className="content">
                <Accordion padding="10px" iconAfter position="space-between" margin="0px 0px 16px 0px" seperator backgroundColor="#ffffff00" title="x" />
                <Price>
                  <div> € 111</div>
                  <div> per medewerker</div>
                </Price>
                <Button>
                  <Text value={"Choose"} />
                </Button>
              </div>
            </div> */}
          </div>
        </div>
      </MinDiv>
      {viewModel.selected && (
        <div>
          <Page width="70%">
            <ChooseModules>
              <div>
                <Title>
                  <Text value={"PRODUCT_TITLE"} />
                </Title>
                <Text value={"PRODUCT_TEXT"} />
              </div>
            </ChooseModules>
            {getDifferentModules(viewModel.selected, viewModel)}
          </Page>
          <Background height="100%"></Background>
          <MinDiv>
            <Page width="70%">
              <SelectedModules>
                <div className="selected_modules_title">
                  <Title>
                    <Text value={"SELECTED_PRODUCTS"} />
                  </Title>
                </div>
                {getTablePrices(viewModel.products)}
              </SelectedModules>
            </Page>
          </MinDiv>
        </div>
      )}
    </div>
  );
}

export default Modules;
