import Text from "../text/Text";
import { Progress, ProgressDone } from "../../pages/home/Home.Components";

const StyledLineData = ({ colors, data, benchmark }) => {
  return data.map((x, y) => (
    <div key={x.name + y} className={benchmark ? "pair" : ""}>
      {benchmark && <div className="num">{y + 1}</div>}
      <div className="fullWidth">
        <div className="cat">
          <div className="text">
            <Text value={x.name} />
          </div>{" "}
          <div className="fullWidth flex">
            <Progress done={x.percent}>
              <ProgressDone color={colors[y] ? colors[y] : colors[0]} />
            </Progress>
            <span className="percent">{x.percent}%</span>

            <span>({x.count})</span>
          </div>
        </div>
        {benchmark && (
          <div className="cat benchmark">
            <div className="text">
              <Text value={"Genodingen"} />
            </div>

            <Progress benchmark done={x.percent}>
              <ProgressDone benchmark color={colors[y] ? colors[y] : colors[0]} />
            </Progress>
            <span className="percent">{x.percent}</span>
          </div>
        )}
      </div>
    </div>
  ));
};

export default StyledLineData;
