import React, { useContext } from "react";
import Text from "../../components/text/Text";
import { FullPage } from "../../components/containers/Page";
import FinishPageViewModel from "./FinishPage.VM";
import Moment from "moment";
import { FinishPageForm, StyledBlock } from "./FinishPage.Components";
import Button from "../../components/buttons/Button";
import ProgressStepBar from "../../components/progress/ProgressStepBar";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";
import { useNavigate } from "react-router-dom";
import { LocalizationContext } from "../../global/Localization/LocalizationState";
import { StorageContext } from "../../global/Storage/StorageState";

export const overViewSteps = [
  { name: "TOTAL_EMPLOYEES", url: "/invitees", value: "invitees", nl: "Genodigden", en: "Invitees" },
  { name: "EXTRA_QUESTIONS", url: "/projectquestions", value: "questions", nl: "Extra vragen", en: "Extra questions" },
  { name: "TOTAL_BLOODPRESSURE", url: "/bloodpressure", value: "bloodpressure", subname: "BLOODPRESSURE_DISTRIBUTION", nl: "Bloeddrukmeters", en: "Bloodpressure meters" },
  { name: "SELECTED_INTERVENTIONS", url: "/interventions", value: "interventions", nl: "Geselecteerde interventies", en: "Selected interventions" },
  { name: "CHECKPOINT_LOCATIONS", url: "/checkpoints", value: "checkpoints", nl: "Checkpoints", en: "Checkpoints" },
];
const FinishPage = () => {
  const { viewModel } = FinishPageViewModel();
  const { configurationProvider } = useContext(ConfigurationContext);
  const navigate = useNavigate();
  const { storageProvider } = useContext(StorageContext);
  const configurationSteps = storageProvider.project.configurationSteps || [];
  const { localizationProvider } = useContext(LocalizationContext);

  function findTotalQuantity(data) {
    return data.reduce((sum, item) => {
      if (item.products && item.products.data) {
        const productQuantities = item.products.data.map((product) => product.quantity || 0);
        return sum + productQuantities.reduce((acc, quantity) => acc + quantity, 0);
      }
      return sum;
    }, 0);
  }

  const getObject = (obj, name) => {
    switch (name) {
      case "questions":
        return <>{obj[name].map((x, i) => x.isModified && <p key={x.globalId + i}> {x.metadata.find((i) => i.cultureCode === localizationProvider.locale.toUpperCase()).text}</p>)}</>;
      case "bloodpressure":
        return (
          <>
            {<span> {findTotalQuantity(obj[name])}</span>}
            {obj[name].map((x, id) => (
              <p key={x.street + x.houseNumber + id}>
                <Text value="TO_ADDRESS" /> {`${x.street} ${x.houseNumber} ${x.houseNumberExt ? x.houseNumberExt : ""} ${x.zipCode} ${x.city}`}
              </p>
            ))}
          </>
        );

      case "invitees":
        return <>{obj[name].length}</>;
      default:
        break;
    }
  };
  const getRowData = (name) => {
    const step = overViewSteps.find((x) => x.nl.toLowerCase() === name.toLowerCase() || x.en.toLowerCase() === name.toLowerCase());

    if (step)
      return (
        <div className="three-columns" key={step.name}>
          <div>
            <b> {<Text value={step.name}></Text>}</b>
            {step.subname && <b> {<Text value={step.subname}></Text>}</b>}
          </div>
          <div> {viewModel[step.value] && typeof viewModel[step.value] === "object" ? getObject(viewModel, step.value) : viewModel[step.value]}</div>
          <div className="modify flex" onClick={() => navigate(step.url)}>
            <img src={configurationProvider.cdn + "client/edit.svg"} alt="edit" />

            <Text value="CHANGE_VALUES" />
          </div>
        </div>
      );
  };

  return (
    <FullPage width="true" parentMargin="0 -10%">
      <FinishPageForm>
        <StyledBlock className="fullsize">
          <div className="form marginTop">
            <ProgressStepBar currentStep={6} />
            <h1 className="margin30">
              <Text value="ALL_DONE_TITLE" />
            </h1>
            <Text value="ALL_DONE_TEXT_1" /> <span className="bold"> {viewModel.project && Moment(viewModel.project.startDate).format("DD-MM-YYYY")} </span> <Text value="ALL_DONE_TEXT_2" />
          </div>

          <div className="divider"></div>
          <div className="overview">
            <div className="finalOverview">
              <h1 className="margin30">
                <Text value="FINAL_OVERVIEW" />
              </h1>
              {configurationSteps.data.map((step) => getRowData(step.name))}
              {/* {overViewSteps.map((step) => (
                <div className="three-columns" key={step.name}>
                  <div>
                    <b> {<Text value={step.name}></Text>}</b>
                    {step.subname && <b> {<Text value={step.subname}></Text>}</b>}
                  </div>
                  <div> {viewModel[step.value] && typeof viewModel[step.value] === "object" ? getObject(viewModel, step.value) : viewModel[step.value]}</div>
                  <div className="modify flex" onClick={() => navigate(step.url)}>
                    <img src={configurationProvider.cdn + "client/edit.svg"} alt="edit" />

                    <Text value="CHANGE_VALUES" />
                  </div>
                </div>
              ))} */}
              <div className="bottomText">
                {" "}
                <Text value="CONFIRMATION_AFTER_FINISH" />
              </div>{" "}
              <Button
                width="40%"
                onClick={async (event) => {
                  event.preventDefault();
                  viewModel.finish(viewModel.project.globalId);
                }}
              >
                <Text value="FINISH" />
              </Button>
            </div>
          </div>
        </StyledBlock>
      </FinishPageForm>
    </FullPage>
  );
};

export default FinishPage;
