import React, { useState } from "react";
import styled from "styled-components";
import { ErrorMessage, SuccessMessage } from "../../components/messages/messages";
import Modal from "../../components/modals/Modal";
import Text from "../../components/text/Text";
import Button, { ButtonGroup } from "../../components/buttons/Button";
import { TextField } from "@mui/material";
import QuantityInput from "../../components/increment/increment";
import { TwoCol } from "./Bloodpressure.Components";

const LabelButton = styled.label`
  display: flex;
  height: 50px;
  background-color: #0060a8;
  border-radius: 0px 5px 5px 0px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  font-size: 18px;
  min-width: 180px;
  text-align: center;
  line-height: 20px;
  margin-right: 15px;
  padding: 15px 28px;
  justify-content: space-between;
  width: 100%;
  position: relative;
  .excelinput {
    position: fixed;
    top: -1000px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
const AddBloodpressure = ({ addShipment, error }) => {
  const [state, setState] = useState({
    firstName: null,
    middleName: "",
    lastName: null,
    street: null,
    houseNumber: null,
    houseNumberExt: null,
    zipCode: null,
    city: null,
    country: "Nederland",
    request: false,
    description: "",
  });
  const [amount, setAmount] = useState(0);
  const [errorPage, setErrorPage] = useState(false);
  const changeValue = (func) => {
    if (amount >= 10) return;
    if (func === 0) {
      setAmount(amount - 1);
    } else setAmount(amount + 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.description.length > 200) {
      setErrorPage("TOO_LONG_TEXT");
      return;
    }
    setErrorPage(false);
    setState({ ...state, request: true });
    if (state.firstName && state.lastName && state.street && state.houseNumber && state.zipCode && state.city) {
      addShipment(state.firstName, state.middleName, state.lastName, state.street, state.houseNumber, state.houseNumberExt, state.zipCode, state.city, state.country, state.description, amount);

      setState({ ...state, showModal: false, status: true, firstName: "", middleName: "", lastName: "", street: "", houseNumber: "", houseNumberExt: "", zipCode: "", city: "", description: "" });
      setAmount(null);
    } else return;
  };
  const onKeyDown = (event) => {
    if (event.keyCode === 32) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
  };

  const checkdescription = (e) => {
    if (/^[a-zA-Z0-9 .,_:@()%+'\\-]*$/.test(e.key) || state.description.length > 200) {
      return;
    }
    e.preventDefault();
  };
  const renderModal = () => {
    const { message } = state;
    return (
      <Modal onClose={() => setState({ ...state, showModal: false, message: "", request: false })}>
        <form onSubmit={(e) => handleSubmit(e)}>
          {" "}
          <h1 className="margin20">
            <Text value="ADD_LOCATION_BLOODPRESSURE_TITLE" />
          </h1>
          <div className="margin20">
            {" "}
            <Text value="ADD_LOCATION_BLOODPRESSURE_TEXT" />
          </div>
          <TwoCol>
            <div>
              {" "}
              <TextField
                style={{ width: "70%" }}
                id="filled-basic"
                label="First name"
                name={"firstName"}
                required
                variant="filled"
                onChange={(e) => {
                  setState({ ...state, ...{ [e.target.name]: e.target.value } });
                }}
              />
              <TextField
                style={{ width: "30%" }}
                id="filled-basic"
                label="Middle name"
                name="middleName"
                variant="filled"
                onChange={(e) => {
                  setState({ ...state, ...{ [e.target.name]: e.target.value } });
                }}
              />
              <TextField
                style={{ width: "100%" }}
                id="filled-basic"
                required
                label="Last name"
                name="lastName"
                variant="filled"
                onChange={(e) => {
                  setState({ ...state, ...{ [e.target.name]: e.target.value } });
                }}
              />
              <br />
              <TextField
                style={{ width: "50%" }}
                id="filled-basic"
                label="Street"
                required
                name="street"
                variant="filled"
                onChange={(e) => {
                  setState({ ...state, ...{ [e.target.name]: e.target.value } });
                }}
              />
              <TextField
                style={{ width: "30%" }}
                id="filled-basic"
                label="House number"
                name="houseNumber"
                required
                variant="filled"
                onChange={(e) => {
                  setState({ ...state, ...{ [e.target.name]: e.target.value } });
                }}
              />
              <TextField
                style={{ width: "20%" }}
                id="filled-basic"
                label="House number ext"
                name="houseNumberExt"
                variant="filled"
                onChange={(e) => {
                  setState({ ...state, ...{ [e.target.name]: e.target.value } });
                }}
              />
              <br />
              <TextField
                style={{ width: "30%" }}
                id="filled-basic"
                label="Postcode"
                name="zipCode"
                variant="filled"
                onChange={(e) => {
                  setState({ ...state, ...{ [e.target.name]: e.target.value.toUpperCase() } });
                }}
                required
                inputProps={{ maxLength: 6, pattern: "^[1-9][0-9]{3}(?!sa|sd|ss)[a-zA-Z]{2}$", onKeyDown: onKeyDown }}
              />
              <TextField
                style={{ width: "70%" }}
                id="filled-basic"
                label="City"
                variant="filled"
                name="city"
                required
                onChange={(e) => {
                  setState({ ...state, ...{ [e.target.name]: e.target.value } });
                }}
              />
              <TextField style={{ width: "100%" }} id="filled-basic" label="Country" value={state.country} />
            </div>
            <div>
              {" "}
              <TextField className="hidden" style={{ width: "100%" }} />
              <br />
              <TextField className="hidden" style={{ width: "100%" }} />
              <TextField className="hidden" style={{ width: "100%" }} />
              <QuantityInput name="amount" id="filled-basic" variant="filled" value={amount} changeValue={changeValue} onChange={(e) => setAmount(e.target.value)} />
              <TextField
                style={{ width: "100%" }}
                id="filled-basic"
                name="description"
                label="Description"
                variant="filled"
                error={errorPage === "TOO_LONG_TEXT"}
                onChange={(e) => {
                  setState({ ...state, ...{ [e.target.name]: e.target.value } });
                }}
                inputProps={{ onKeyDown: checkdescription }}
                helperText="DESCRIPTION_HELP_TEXT"
              />
            </div>
          </TwoCol>
          {state.request && (
            <>
              {" "}
              {message && (
                <SuccessMessage>
                  {message} <Text value="SUCCESFULLY_ADDED" />
                </SuccessMessage>
              )}
              {error && (
                <ErrorMessage>
                  <Text value={error} />
                </ErrorMessage>
              )}
            </>
          )}
          {errorPage && (
            <ErrorMessage>
              <Text value={errorPage} />
            </ErrorMessage>
          )}
          <ButtonGroup spaceBetween>
            <Button styleType={5} type="button" width="18%" onClick={() => setState({ ...state, showModal: false, message: "" })}>
              <Text value="CANCEL" />
            </Button>
            &nbsp;
            <Button>
              <Text value="ADD_LOCATION" />
            </Button>
          </ButtonGroup>
        </form>
      </Modal>
    );
  };

  const onManualEntry = () => {
    setState({ ...state, showModal: true }, () => this.nameInput);
  };

  const { showModal } = state;
  return (
    <div>
      {showModal && renderModal()}
      <LabelButton className="button" icon="f067" onClick={onManualEntry}>
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="#ffffff">
          <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
        </svg>
        <Text value="ADD_LOCATION" />
      </LabelButton>
    </div>
  );
};

export default AddBloodpressure;
