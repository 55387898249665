import styled from "styled-components";
import Form from "../../components/containers/Form";

export const CompanyForm = styled(Form)`
  background-color: var(--color-primary);
  color: var(--color-white);
  margin: 12px 0 12px 0;
  padding: 32px;
  width: 50%;

  h3 {
    font-size: 20px;
  }
  > div:last-child {
    margin-left: auto;
  }
  .flexInputs {
    @media (min-width: 820px) {
      display: flex;
    }
  }
  @media (max-width: 820px) {
    width: 100%;
  }
`;
