import React, { useContext, useState } from "react";

import useIdentityWebService from './Identity/useIdentityWebService';
import useClientWebService from "./Client/useClientWebService";
import { ConfigurationContext } from "../Configuration/ConfigurationState";
import { AppContext } from "../App/AppState";
import { LocalizationContext } from "../Localization/LocalizationState";
export const WebServiceContext = React.createContext();
export const WebServiceProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [successResetPassword, setSuccessResetPassword] = useState(false);
  const [authorization, setAuthorization] = useState({});
    const [info, setInfo] = useState({});
    const { configurationProvider } = useContext(ConfigurationContext);
    const { appProvider } = useContext(AppContext);
    const { localizationProvider } = useContext(LocalizationContext);

    const { postLogin, forgotPassword, resetPassword } = useIdentityWebService(
      appProvider,
      configurationProvider.identityBaseUrl,
      configurationProvider.domains.identity,
      setAccessToken,
      createHeaders,
      setError,
      setSuccessResetPassword,
      setDataValues
    );

    const {
      register,
      registration,
      getPackages,
      getDefaultQuestions,
      getProjectQuestions,
      getPackagesByCode,
      putQuoteAccept,
      postDefaultQuestion,
      postCustomQuestion,
      putQuoteCancel,
      getQuoteData,
      getInvitees,
      postInvitees,
      getAllQuotes,
      postAnswers,
      editAnswers,
      deleteQ,
      deleteAnswer,
      getAllProjects,
      postState,
      getShipments,
      newShipment,
      deleteShipment,
      getUsername,
      getProjectData,
      finishStep,
    } = useClientWebService(appProvider, configurationProvider.baseUrl, configurationProvider.domains.client, createHeaders, setError);

    const provider = {
      identityWebService: {
        postLogin: postLogin,
        forgotPassword: forgotPassword,
        resetPassword: resetPassword,
      },
      clientWebService: {
        register: register,
        registration: registration,
        getDefaultQuestions: getDefaultQuestions,
        getProjectQuestions: getProjectQuestions,
        getPackages: getPackages,
        getPackagesByCode: getPackagesByCode,
        putQuoteAccept: putQuoteAccept,
        putQuoteCancel: putQuoteCancel,
        getQuoteData: getQuoteData,
        getInvitees: getInvitees,
        postInvitees: postInvitees,
        getAllQuotes: getAllQuotes,
        postAnswers: postAnswers,
        postDefaultQuestion: postDefaultQuestion,
        postCustomQuestion: postCustomQuestion,
        editAnswers: editAnswers,
        deleteQ: deleteQ,
        deleteAnswer: deleteAnswer,
        getAllProjects: getAllProjects,
        postState: postState,
        getShipments: getShipments,
        newShipment: newShipment,
        deleteShipment: deleteShipment,
        getUsername: getUsername,
        getProjectData: getProjectData,
        finishStep: finishStep,
      },
      successResetPassword,
      info,
      error,
      setError,
    };

    function setDataValues(value) {
      setInfo({ ...info, ...value });
    }

  function setAccessToken(value) {
    setAuthorization({
      jwt: value,
    });
  }

  function createHeaders() {
    return {
      Authorization: `Bearer ${authorization.jwt}`,
      "Accept-Language": localizationProvider.locale,
    };
  }

  return <WebServiceContext.Provider value={provider}>{children}</WebServiceContext.Provider>;
};