export const validEmail = (email) =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+.[a-zA-Z]{1,9}))$/.test(email);
export const validName = (name) => /^[a-zA-Z \u00C0-\u00ff\-\.]{0,200}$/.test(name);

export const getUnique = (arr, comp) => {
  const unique = arr
    .map((e) => e[comp])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
};

export const namePattern = "^[a-zA-Z \u00C0-\u00ff-.]{0,200}$";
export const emailPattern = "^[a-zA-Z0-9_+&*-]+(?:.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+.)+[a-zA-Z]{2,10}$";
export const phonePattern = "[0-9]{10}";
