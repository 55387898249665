import React from "react";
import { TextField } from "@mui/material";
import styled from "styled-components";

const StyledIncrement = styled.div`
  display: flex;
  * {
    margin-right: 0;
  }
  input {
    text-align: center;
  }
  label {
    margin: -10px 10px;
  }
`;
const StyledTextField = styled(TextField)({
  "& .MuiInputLabel-root": {
    right: 0,
    textAlign: "center",
  },
});
const minus =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy44MjEiIGhlaWdodD0iMi40MzkiIHZpZXdCb3g9IjAgMCAxMy44MjEgMi40MzkiPgogIDxwYXRoIGlkPSJub3VuLXBsdXMtMTA0MTA5Ni1FNzMzNkYiIGQ9Ik0zNDQuMjcxLDM0OC43NTFhMS4yNjIsMS4yNjIsMCwwLDAtMS4yMTksMS4yMTljMCwuNjEsMS4yMTksMS4yMTksMS4yMTksMS4yMTloMTEuMzgyczEuMjE5LS41NDYsMS4yMTktMS4yMTlhMS4yMTksMS4yMTksMCwwLDAtMS4yMTktMS4yMTlaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzQzLjA1MiAtMzQ4Ljc1MSkiIGZpbGw9IiNlNzMzNmYiLz4KPC9zdmc+Cg";
const plus =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy44MjEiIGhlaWdodD0iMTMuODIxIiB2aWV3Qm94PSIwIDAgMTMuODIxIDEzLjgyMSI+CiAgPHBhdGggaWQ9Im5vdW4tcGx1cy0xMDQxMDk2LUU3MzM2RiIgZD0iTTM0OS45NjIsMzQzLjA2YTEuMjE5LDEuMjE5LDAsMCwwLTEuMjE5LDEuMjJ2NC40NzFoLTQuNDcxYTEuMjE5LDEuMjE5LDAsMSwwLDAsMi40MzloNC40NzF2NC40NzFhMS4yMTksMS4yMTksMCwxLDAsMi40MzksMFYzNTEuMTloNC40NzFhMS4yMTksMS4yMTksMCwwLDAsMC0yLjQzOWgtNC40NzF2LTQuNDcxQTEuMjIsMS4yMiwwLDAsMCwzNDkuOTYyLDM0My4wNloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zNDMuMDUyIC0zNDMuMDYpIiBmaWxsPSIjZTczMzZmIi8+Cjwvc3ZnPgo";

const min = 0;
const max = 10;
export default function QuantityInput({ onChange, changeValue, value }) {
  return (
    <StyledIncrement>
      <React.Fragment>
        <div onClick={() => changeValue(0)}>
          <img src={minus} alt="min" />{" "}
        </div>
        <StyledTextField
          id="standard-number"
          label="Value"
          type="numeric"
          InputLabelProps={{
            shrink: true,
          }}
          value={value}
          variant="standard"
          inputProps={{
            style: {
              height: "28px",
              width: "60px",
            },
            min,
            max,
          }}
          onChange={onChange}
        />
        <div onClick={() => changeValue()}>
          <img src={plus} alt="plus" />
        </div>
      </React.Fragment>
    </StyledIncrement>
  );
}
