import { useContext } from "react";
import styled from "styled-components";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";

import LanguageToggleButton from "../buttons/LanguageToggleButton";
import TextSizeButtons from "../buttons/TextSizeButtons";

const Header = () => {
  const { configurationProvider } = useContext(ConfigurationContext);
  return (
    <Container>
      <a href="/">
        <img src={configurationProvider.cdn + "logo/logo-niped.svg"} alt="Niped Logo" />
      </a>
      <div>
        <TextSizeButtons />
        <LanguageToggleButton />
      </div>
    </Container>
  );
};

export default Header;

const Container = styled.header`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  z-index: 1000;
  flex-direction: row;
  padding: 30px;
  box-shadow: 4px 6px 35px #0000001f;
  a img {
    width: 165px;
  }

  div:first-of-type {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
  }
`;
