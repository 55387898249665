import styled from "styled-components";

import Form from "../../components/containers/Form";

export const ProjectQuestionsForm = styled(Form)`
  .bottomTextBtns {
    margin-top: 20px;
    width: 50%;
    float: right;
    text-align: right;
    font-style: italic;
    @media (max-width: 400px) {
      width: 100%;
    }
  }
  .ABCorder {
    margin-top: 20px;
    overflow: auto;
    li {
      width: 90%;
    }
    li > div {
      display: inline-block;
      vertical-align: middle;
      margin: 10px;
    }
    div {
      display: flex;
      align-items: center;
    }
    .delete-icon {
      width: 40px;
      margin-left: 20px;
      cursor: pointer;
      :hover .small {
        opacity: 1;
        transition: 1s ease-out 100ms;
      }
    }
    .copy {
      display: block;
      .small {
        font-size: 10px;
        opacity: 0;
      }
    }
  }
  .bold {
    font-family: bold;
  }
  .box {
    background: white;
  }
  .back {
    margin: 20px 0;
  }
  h1,
  h3,
  h4 {
    color: var(--color-primary);
  }

  h3 {
    font-size: 20px;
  }

  .form {
    padding: 10px 20%;
  }

  @media (max-width: 1200px) {
    .form {
      padding: 10px 10%;
    }
  }

  .marginTop {
    margin-top: 10px;
  }
  .marginMinTop {
    margin-top: -5%;
  }
  .margin30 {
    margin: 30px 0;
  }
  .add_location_button {
    display: flex;
    .button {
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }
  .two-columns {
    // width: 90%;
    display: flex;
    flex-wrap: wrap;
  }
  .two-columns > * {
    width: 100%;
    padding: 1rem;
    margin: 10px;
    margin-left: 0;
    margin-top: 0;
  }
  @media (max-width: 1400px) {
    .two-columns {
      width: 80%;
    }
  }

  @media (min-width: 768px) {
    .two-columns > .question {
      width: calc(94% / 2);
    }
  }

  @media (min-width: 780px) and (max-width: 1500px) {
    .prev::before {
      position: absolute;
      margin: -25px 10px;
      content: " ";
      border-left: 2px solid rgb(0, 0, 0);
      border-bottom: 2px solid rgb(0, 0, 0);
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
      cursor: pointer;
      display: inline-table;
    }

    .next::before {
      content: " ";
      position: absolute;
      margin: -30px;
      border-right: 2px solid #000;
      border-top: 2px solid #000;
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
      cursor: pointer;
      display: block;
    }
  }
  .progressContact {
    // width: 90%;
  }
  .addAnwerButton {
    button {
      margin: auto 0;
      color: black;
    }
    background: #e0e0e0;
    box-shadow: 0px 3px 3px #00000026;
    border: 1px solid #00000017;
    border-radius: 24px;
    cursor: pointer;
    width: 35%;
    height: 40px;
    font-family: bold;
    :hover {
      opacity: 0.75;
    }
  }
  .flex {
    display: flex;
  }
  .spaceBetween {
    justify-content: space-between;
  }
`;
export const StyledBlock = styled.div`
  background-color: var(--color-back-grey);
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const TwoCol = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: block;
  };
  h3{ font-family:bold;}
  .question_title{min-height: 130px;
  }
  .bottom{vertical-align: bottom;
    display: table-cell;}
    > div {
      :first-child,:last-child
       {
       width:49%;

  @media (max-width: 768px) {width:100%;}
      };
      a{margin:0 5px; color: var(--color-pink); text-decoration:underline;};
     
`;
