import React, { useContext, useState } from "react";
import styled from "styled-components";
import Text from "../../components/text/Text";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CreateIcon from "@mui/icons-material/Create";
import { TablePagination } from "@mui/material";
import { LocalizationContext } from "../../global/Localization/LocalizationState";

// const useStyles = makeStyles({
//   root: {
//     width: "100%",
//     overflowX: "auto",
//   },
//   table: {
//     minWidth: 650,
//   },
// });
const StyledTable = styled.div`
  border: 1px solid #cccccc94;
  border-radius: 4px;
  // width:90%;
  .table-row {
    display: flex;
  }
  .bottomBorder {
    border-top: 1px solid #cccccc52;
  }
  background: var(--color-white);
  .table-cell {
    display: flex;
    justify-content: space-between;
    width: 50%;
    padding: 2px 14px;
  }
  .questionLang {
    padding: 12px 14px;
  }
  .iconQuestion {
    cursor: pointer;
    margin: auto 3px;
    font-size: 10px;
  }
  .emptySquare{
    width:21px;
  }
  .grey {
    background-color: rgba(204, 204, 204, 0.32);
  }
  .answers,
  .titleQuestion {
    cursor: default;
  }
  .bold {
    font-family: bold;
  }
  .normal {
    font-family: "CENTURY GOTHIC";
  }
  .pagination {
    display: flow;
  }
`;

const ExpandableTableRow = ({ answersNl, answersEn, name, editQuestion, eng, id, deleteQuestion }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  return (
    <>
      <div className={`table-row bottomBorder ${id % 2 === 0 ? "grey" : ""}`}>
        <div className="table-cell">
          <div className="titleQuestion"> {name}</div>

          <div className="flex">
            <div className="iconQuestion" onClick={editQuestion}>
              <CreateIcon fontSize="inherit" />
            </div>{" "}
            {(
              <div className="iconQuestion" onClick={() => setIsExpanded(!isExpanded)}>
             {answersNl? isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />:<div className="emptySquare"/>}
              </div>
            )}
          </div>
        </div>

        <div className="table-cell">
          <div className="titleQuestion"> {eng}</div>
          <div className="iconQuestion" onClick={deleteQuestion}>
            <div>{"x"}</div>
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className={`table-row answers ${id % 2 === 0 ? "grey" : ""}`}>
          <div className="table-cell">{answersNl}</div>
          <div className="table-cell">{answersEn}</div>
        </div>
      )}
    </>
  );
};

export default function ProjectTable({ rows, editQuestion, deleteQuestion }) {
  const { localizationProvider } = useContext(LocalizationContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      <StyledTable>
        <div className="table-head">
          <div className="table-row questionLang">
            <div className="table-cell titleQuestion bold">
              <Text value="QUESTION_NL" />
            </div>
            <div className="table-cell titleQuestion bold">
              <Text value="QUESTION_EN" />
            </div>
          </div>
        </div>
        <div className="table-body">
          {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map(
            (row, i) =>
              row.globalId && (
                <ExpandableTableRow
                  id={i}
                  key={row.globalId}
                  eng={row.metadata.find((q) => q.cultureCode === "EN").text}
                  name={row.metadata.find((q) => q.cultureCode === "NL").text}
                  editQuestion={() => editQuestion(row.defaultGlobalId)}
                  deleteQuestion={() => deleteQuestion(row.globalId, row.defaultGlobalId)}
                  answersNl={
                    row.answerOptions &&
                    row.answerOptions.length > 0 && (
                      <ol type="A" className="bold" key={"answerNl" + i + row.questionGlobalId}>
                        {row.answerOptions.map((answer, index) => (
                          <li key={answer.value + index}>
                            <div className="normal"> {answer.metadata.find((q) => q.cultureCode === "NL").text}</div>
                          </li>
                        ))}
                      </ol>
                    )
                  }
                  answersEn={
                    row.answerOptions &&
                    row.answerOptions.length > 0 && (
                      <ol type="A" className="bold" key={"answerEN" + i + row.globalId}>
                        {row.answerOptions.map((answer, index) => (
                          <li key={answer.value + index}>
                            <div className="normal"> {answer.metadata.find((q) => q.cultureCode === "EN").text}</div>
                          </li>
                        ))}
                      </ol>
                    )
                  }
                ></ExpandableTableRow>
              )
          )}
        </div>
        <TablePagination
          className="pagination"
          component="div"
          count={rows.length}
          page={page}
          value={page}
          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) => (localizationProvider.locale === "nl" ? `${from}-${to} van ${count}` : `${from}-${to} of total ${count}`)}
          labelRowsPerPage={localizationProvider.locale === "nl" ? "Rijen per pagina" : "Rows per page"}
        />
      </StyledTable>
    </>
  );
}
