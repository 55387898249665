import React, {useContext} from 'react';
import styled from 'styled-components';

import {LocalizationContext} from '../../global/Localization/LocalizationState';
import {AppContext} from '../../global/App/AppState';

import {Medium, Large} from '../../core/enums/TextSize'

const Text = ({ text, value, fallbackValue, whiteSpace, color, fontSize, ref, underline, bold }) => {
  const { localizationProvider } = useContext(LocalizationContext);
  const { appProvider } = useContext(AppContext);

  let content = value ? localizationProvider.getLocalization(value, fallbackValue) : text;

  if (content) content = content.replace(/[\n\r]/g, "<br />");

  return <Container underline={underline} ref={ref} color={color} textSize={appProvider.textSize} whiteSpace={whiteSpace} bold={bold} dangerouslySetInnerHTML={{ __html: content }}></Container>;
};

export default Text;

export const GetLocalizationValue = (value, lang) => {
  const { localizationProvider } = useContext(LocalizationContext);
  let content = localizationProvider.getLocalizationByLang(value, lang);
  return content;
};


const Container = styled.span`
  word-break: break-word;
  text-decoration:${(props) => props.underline && "underline"};
  overflow-wrap: break-word;
  color: ${(props) => props.color};
  font-family: ${(props) => props.bold?"bold":''};
  font-size: ${(props) => {
    switch (props.textSize) {
      case Medium:
        return "1.25em;";
      case Large:
        return "1.5em;";
      default:
        return "1em;";
    }
  }};   
  white-space: ${(props) => props.whiteSpace || "normal"};
}`;

export const Bullet = styled.div`
margin: 7px 0;
button{color: var(--color-pink); text-decoration: underline;
  cursor: pointer; border:none;}
  .bold{font-weight:bold};
:before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 15px;
    display: inline-block;
    background-color: ${props=>props.color? props.color:"var(--color-pink)"};
    vertical-align: middle;
}
`