import styled from "styled-components";
export const Block = styled.div`
  @media (min-width: 768px) {
    display: flex;
  }

  margin: ${(props) => props.margin};
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
  padding: 5px;
`;
