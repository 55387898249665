import React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";
import Text from "../../components/text/Text";

const ErrorWrapper = styled.div`
  display: flex;
  // align-items: center;
  // justify-content: center;
  margin: 10% -10%;
  padding: 10%;
  background-color: #f6f6f6;
  img#back {
    margin-right: 10px;
  }
  img#plug {
    width: 150px;
    margin-right: 50px;
  }
  line-height: 18px;
  h1 {
    line-height: 115%;
    margin-bottom: 20px;
  }
  color: var(--color-primary);
  a:hover {
    text-decoration: none;
  }
`;
const StyledLink = styled.div`
  margin: 24px 0px;
  display: flex;
  color: var(--color-pink);
  cursor: pointer;
`;

const ErrorPage = () => {
  const { configurationProvider } = useContext(ConfigurationContext);
  return (
    <ErrorWrapper>
      <img id="plug" src={configurationProvider.cdn + "client/plug.svg"} alt={"plug"} />
      <div className="error_text">
        <h1>
          <Text value={"SOMETHING_WENT_WRONG"} />
        </h1>
        <Text value={"TRY_LATER"} />
        <a href={window.location.pathname === "/home" ? "/" : "home"}>
          <StyledLink>
            <img id="back" src={configurationProvider.cdn + "client/back_link.svg"} alt={"back"} />
            <Text value={"BACK_TO_HOME"} />
          </StyledLink>
        </a>
      </div>
    </ErrorWrapper>
  );
};

export default ErrorPage;
