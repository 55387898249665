import React, {useContext} from 'react';
import styled from 'styled-components';

import {AppContext} from '../../global/App/AppState';

import {Small, Medium, Large} from '../../core/enums/TextSize'

const TextSizeButtons = () => {

    const { appProvider } = useContext(AppContext);

    return (
        <Container>
            <span
                onClick={() => {
                    appProvider.setTextSize(Small);
                }} 
            >A</span>
            <span
                onClick={() => {
                    appProvider.setTextSize(Medium);
                }} 
            >A</span>
            <span
                onClick={() => {
                    appProvider.setTextSize(Large);
                }} 
            >A</span>
        </Container>
    )
}
export default TextSizeButtons;

const Container = styled.div`
  flex: 0 0 auto;
  order: 0;
  -webkit-box-flex: 0;
  margin: 0px 40px;
  align-items: baseline !important;

  span {
    font-size: 1.25em;
    padding: 3px;
    font-weight: bold;
    cursor: pointer;
    color: var(--color-primary);
  }

  span:first-child {
    font-size: 1em;
  }

  span:last-child {
    font-size: 1.5em;
  }
`;