import React, { useState } from "react";
import Error from "../../components/containers/Error";
import Page from "../../components/containers/Page";
import FlexBox from "../../components/containers/FlexBox";
import Wizard from "../../components/wizard/wizard";
import Intro from "../../components/intro/Intro";
import Text from "../../components/text/Text";
import Input from "../../components/inputs/Input";
import Button from "../../components/buttons/Button";
import Password from "../../components/inputs/password";
import Checkbox from "../../components/inputs/Checkbox";
import CodeViewModel from "./Company.VM";
import { CodeForm, CompanyForm } from "./Company.Components";
import MarginBox from "../../components/containers/MarginBox";
import { Back } from "../../components/back/back";

const Company = () => {
  const { viewModel } = CodeViewModel();
  const [state, setState] = useState({
    invalid: false,
  });
  const textSteps = ["ACCOUNT", "COMPANY_DETAILS", "MODULES"];
  return (
    <Page>
      {" "}
      <Wizard done={60} textSteps={textSteps}></Wizard>
      <FlexBox gap={"0px"}>
        <Intro minHeight="350px" title="COMPANY_TITLE" text="COMPANY_TEXT" />

        <CompanyForm
          onSubmit={async (event) => {
            event.preventDefault();
            viewModel.sendCompanyDetails();
          }}
        >
          <h1>
            <Text value="FILL_COMPANY_TITLE" />
          </h1>
          <MarginBox bottom={"20px"}>
            <Text value="FILL_COMPANY_TEXT" />
          </MarginBox>
          <div className="flexInputs">
            <Input
              placeholder="PLACEHOLDER_FIRSTNAME"
              value={viewModel.firstName}
              pattern="[A-Za-z]+"
              onChange={(e) => {
                if (!viewModel.isBusy) viewModel.setFirstName(e.target.value);
              }}
            />
            <Input
              marginLeft
              placeholder="PLACEHOLDER_LASTNAME"
              value={viewModel.lastName}
              pattern="[A-Za-z]+"
              onChange={(e) => {
                if (!viewModel.isBusy) viewModel.setLastName(e.target.value);
              }}
            />
          </div>{" "}
          <Input
            placeholder="PLACEHOLDER_PHONE"
            pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
            value={viewModel.phone}
            onChange={(e) => {
              if (!viewModel.isBusy) viewModel.setPhone(e.target.value);
            }}
          />
          <Input
            placeholder="PLACEHOLDER_EMAIL"
            pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}"
            value={viewModel.email}
            required={false}
            onChange={(e) => {
              if (!viewModel.isBusy) viewModel.setEmail(e.target.value);
            }}
          />
          <Input
            placeholder="PLACEHOLDER_COMPANY_NAME"
            value={viewModel.companyName}
            onChange={(e) => {
              if (!viewModel.isBusy) viewModel.setCompanyName(e.target.value);
            }}
          />
          <div className="flexInputs">
            <Input
              placeholder="PLACEHOLDER_TOTAL_EMPLOYEES"
              value={viewModel.employeesTotal}
              onChange={(e) => {
                if (!viewModel.isBusy) viewModel.setEmployeesTotal(e.target.value);
              }}
            />
            <Input
              marginLeft
              placeholder="PLACEHOLDER_PO_NUMBER"
              value={viewModel.poNumber}
              required={false}
              onChange={(e) => {
                if (!viewModel.isBusy) viewModel.setPoNumber(e.target.value);
              }}
            />
          </div>{" "}
          <Error value={viewModel.pageError} />
          <Error value={viewModel.error} />
          <Button width="45%">
            <Text value="NEXT" />
          </Button>
        </CompanyForm>
      </FlexBox>
    </Page>
  );
};

export default Company;
