import { useContext, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Text from "../text/Text";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";

export default function SelectVariants({ title, values, selectedValue, setValue, wide }) {
  const [open, setOpen] = useState(false);
  const { configurationProvider } = useContext(ConfigurationContext);


  const validValues = values.map((i) => i.value);
  const currentValue = validValues.includes(selectedValue) ? selectedValue : "";

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      {" "}
      <FormControl sx={{ m: 1, minWidth: "80%", margin: wide ? "0 20px" : 0 }}>
        {wide ? (
          <InputLabel id="demo-controlled-open-select-label" sx={{ background: "white" }}>
            <Text value={title} />
          </InputLabel>
        ) : (
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleOpen}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: wide ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <img width="20px" src={configurationProvider.cdn + "client/projects.svg"} alt="i" />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>
          // <div onClick={handleOpen}>Open</div>
        )}

        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={currentValue}
          label="value"
          onChange={handleChange}
          sx={!wide ? { visibility: "hidden", width: 0, height: 0 } : {}}
        >
          {values.map((i) => (
            <MenuItem key={i.name + i.value} value={i.value}>
              {i.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
