import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import styled from "styled-components";

const StyledDonut = styled.div`
  display: flex;
  overflow: auto;
  .statDonut {
    margin: auto;
  }
  .donut {
    width: 80px;
    height: 140px;
  }
  .donutpercent {
    text-align: left;
    margin: auto 0;
    .item {
      justify-content: space-between;
      white-space: nowrap;
    }
    div {
      margin: auto 10px;
      line-height: 30px;
      font-size: 16px;
    }
    .bold {
      font-family: "bold";
    }
  }
`;
const RoundColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;
ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: 28,
  plugins: {
    legend: {
      display: false,
      position: "left",
      labels: {
        usePointStyle: "circle",
        padding: 30,
        font: {
          size: 1,
        },
      },
    },
  },
};

const DoughnutChart = ({ chartData }) => {
  const data = {
    responsive: true,
    labels: chartData.labels.map(String),
    datasets: [
      {
        data: chartData.datasets.data,
        backgroundColor: ["#e7336f", "#0060a8", "#22447f", "#ccc"],
        borderWidth: 1,
        borderRadius: 20,
        borderColor: ["#e7336f", "#0060a8", "#22447f", "#ccc"],
        spacing: 7,
      },
    ],
  };
  return (
    <StyledDonut>
      <div className="flex statDonut">
        <div className="donutpercent">
          {chartData.datasets.data.map((item, key) => (
            <div key={"item" + key} className="flex item">
              <div className="flex">
                <RoundColor color={data.datasets[0].backgroundColor[key]} />
                <div> {chartData.labels[key]}</div>
              </div>
              <div className="bold"> {item}%</div>
            </div>
          ))}
        </div>
        <div className="donut">
          <Doughnut data={data} options={options} />
        </div>
      </div>
    </StyledDonut>
  );
};

export default DoughnutChart;
