import styled from 'styled-components';

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  ${(props) => (props.minWidth ? `min-width:${props.minWidth}` : `width: ${props.width ? props.width : "100%"}`)};

  margin: ${(props) => (props.margin ? props.margin : "10px")};
  height: 100%;
  .footer_text {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
  .backgroundGrey {
    background-color: var(--color-back-grey);
    padding: 15px;
    width: 100%;
    .years > div {
      padding: 20px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 10px;
  }
`;



export default Grid;