import styled from 'styled-components';

import Form from '../../components/containers/Form';

export const LoginForm = styled(Form)`
  background-color: var(--color-primary);
  color: var(--color-white);
  margin: 12px 0 12px 0;
  padding: 32px;
  @media (min-width: 820px) {
    width: 50%;
  }

  h3 {
    font-size: 16px;
  }

  .margin10 {
    margin-top: 10px;
  }
  .button {
    cursor: pointer;
  }
  .whiteLink {
    cursor: pointer;
    display: flex;
    float: right;
    margin: 5px 0;
    text-decoration: underline;
  }
  .separator {
    width: 100%;
    border-bottom: 1px solid white;
    margin: 20px 0;
  }
`;