import { useContext, useState } from "react";
import { WebServiceContext } from "../../global/WebService/WebServiceState";

const RegistrationViewModel = () => {
  const [success, setMessage] = useState("");
  const { clientWebService, error, isBusy } = useContext(WebServiceContext);
  const [emailMismatch, setEmailMismatch] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [repeatEmail, setRepeatEmail] = useState("");

  function deleteLastSpace(str) {
    return str.replace(/\s+$/, "");
  }
  const validateEmail = (email) => {
    const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
  };
  async function sendEmails() {
    setEmail(deleteLastSpace(email));
    setRepeatEmail(deleteLastSpace(repeatEmail));
    if (!validateEmail(email)) {
      setEmailError("NOT_CORRECT_EMAIL_FORMAT");
      return;
    }
    if (email !== repeatEmail) {
      setEmailMismatch("ERROR_EMAIL_MISMATCH");
    } else {
      const response = await clientWebService.registration(email);
      if (response === 201) {
        setMessage("SUCCESS");
        setTimeout(() => setMessage(""), 15000);
        // navigate("/home");
      }
    }

    // if (userProvider.initRoles(await identityWebService.postLogin(username, password))) navigate("/home");
    // else setPageError("ERROR_NO_ROLES");
  }

  return {
    viewModel: {
      sendEmails: sendEmails,
      email: email,
      repeatEmail: repeatEmail,
      emailError: emailError,
      setEmailError: setEmailError,
      repeatEmailError: repeatEmail,
      setEmail: setEmail,
      setRepeatEmail: setRepeatEmail,
      success: success,
      setMessage: setMessage,
      emailMismatch: emailMismatch,
      setEmailMismatch: setEmailMismatch,
      isBusy: isBusy,
      error: error,
    },
  };
};

export default RegistrationViewModel;
