import styled from "styled-components";

import Form from "../../components/containers/Form";
import Button from "../../components/buttons/Button";

export const InviteesForm = styled(Form)`
  .back {
    margin: 20px 0;
  }
  h1 {
    color: var(--color-primary);
  }

  h3 {
    font-size: 20px;
  }

  .form {
    padding: 10px 20%;
  }
  .marginTop {
    margin-top: 10px;
  }
  .emplOverview {
    z-index: 0;
    .invitees_buttons {
      margin-top: 30px;
      width: 50%;
      float: right;
      text-align: right;
      > :first-child {
        font-style: italic;
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
`;

export const StyledBlock = styled.div`
  background-color: var(--color-back-grey);
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
export const DownloadSection = styled.div`
  margin: 10px -6%;
  padding: 0px 6%;
  position: relative;
  .margin20 {
    margin-bottom: 20px;
  }
  a:hover {
    text-decoration: none;
  }

  .download_button {
    display: flex;
    background-color: var(--color-button-primary);
    border: 1px solid;
    border-color: var(--color-button-primary);
    color: var(--color-white);
    opacity: ${(props) => (props.disabled ? "0.5" : "1")};
    height: 50px;
    mix-blend-mode: normal;
    overflow: hidden;
    width: 250px;
    cursor: pointer;
    margin: 10px 0px;
    margin-right: auto;
    font-size: 16px;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 0 10px;
    position: static;
    border-radius: var(--corner-radius-small);
    align-self: stretch;
    -webkit-box-flex: 0;
    flex-grow: 0;
  }
`;
export const UploadSection = styled.div`
  margin: -10px -6% 0;
  margin-bottom: 100px;
  margin-top: 40px;
  padding: 0px 6%;
  position: relative;
  .margin20 {
    margin-bottom: 20px;
  }
`;

export const OverviewEmployees = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 6px 35px #0000001f;
  margin: -10%;

  @media (min-width: 1200px) {
    margin: -5%;
  }

  padding: 5%;
  .margin20 {
    margin-bottom: 20px;
  }
`;

export const DeleteImg = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
  filter: ${(props) => (props.$filter ? props.$filter : "grayscale(1)")};
`;
export const DeleteSelectedButton = styled(Button)`
  display: flex;
  width: 200px;
  justify-content: space-between;
`;
