import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StorageContext } from "../../global/Storage/StorageState";
import { WebServiceContext } from "../../global/WebService/WebServiceState";

const CodeViewModel = () => {
  const navigate = useNavigate();
  const { storageProvider } = useContext(StorageContext);
  const { clientWebService, error } = useContext(WebServiceContext);
  const [code, setCode] = useState("");

  const [isBusy, setIsBusy] = useState(false);

  async function sendCode() {
    if (!isBusy) {
      const fetchData = await clientWebService.getPackagesByCode(code, "NL");
      setIsBusy(true);
      storageProvider.setPackages(fetchData);

      if (fetchData.length) {
        navigate("/modules");
      }

      setIsBusy(false);
    }
  }

  return {
    viewModel: {
      sendCode: sendCode,
      packages: storageProvider.packages,
      code: code,
      setCode: setCode,
      isBusy: isBusy,
      error: error,
    },
  };
};

export default CodeViewModel;
