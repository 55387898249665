import React, { useState, useContext, useEffect } from "react";
import Text, { GetLocalizationValue } from "../../components/text/Text";
import { FullPage } from "../../components/containers/Page";
import { CommunicationForm, StyledBlock, StyledBullet, TwoCol } from "./Communication.Components";
import Button, { ButtonGroup } from "../../components/buttons/Button";
import ProgressStepBar, { getStepURL } from "../../components/progress/ProgressStepBar";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";
import { useNavigate } from "react-router-dom";
import Dropdownlink from "../../components/dropdownlink/dropdownlink";
import CommunicationViewModel from "./Communication.VM";
import { LocalizationContext } from "../../global/Localization/LocalizationState";

const Communication = () => {
  const { configurationProvider } = useContext(ConfigurationContext);

  const { localizationProvider } = useContext(LocalizationContext);
  const [language, setLanguage] = useState("nl");

  const navigate = useNavigate();

  useEffect(() => {
    setLanguage(localizationProvider.locale);
  }, [localizationProvider.locale]);

  const { viewModel } = CommunicationViewModel();
  return (
    <FullPage width="true" parentMargin="0 -10%">
      <CommunicationForm>
        <StyledBlock className="fullsize">
          <div className="form marginTop">
            <ProgressStepBar currentStep={1}></ProgressStepBar>{" "}
            <div
              className="download_language"
              onClick={() => {
                setLanguage(language === "nl" ? "en" : "nl");
              }}
            >
              <img src={configurationProvider.cdn + `icons/${language === "nl" ? "en" : "nl"}.svg`} alt="time" />
              <br></br>
              <Text value={"CHANGE_DOWNLOAD_FILES_LANGUAGE_" + language.toUpperCase()} />
            </div>
            <div className="communication">
              <div>
                <img width={"17px"} src={configurationProvider.cdn + "client/clock_cl.svg"} alt="time" /> <Text value="DATE_TO_START_1" />
              </div>
              <br />
              <TwoCol>
                <div>
                  <h1>
                    <Text value="HEALTH_CAMPAIGN_TITLE" bold />
                  </h1>
                  <Text value="HEALTH_CAMPAIGN_TEXT" />
                  <br />
                  <h3>
                    <Text value="TEXTS" bold />
                  </h3>

                  <div>
                    <h4>
                      <Text value="CHECKBOX" bold />
                    </h4>
                    {Array(4)
                      .fill()
                      .map((x, i) => (
                        <StyledBullet href={"https://niped.nl/" + GetLocalizationValue("HEALTH_CHECKBOX_" + i, language)} target="_blank" rel="noreferrer">
                          <img src={configurationProvider.cdn + "client/text.svg"} alt="b" />
                          <div className="link pink">
                            <Text value={"HEALTH_LINK_CHECKBOX_" + i} />
                          </div>
                        </StyledBullet>
                      ))}
                    <Dropdownlink
                      title={
                        <StyledBullet>
                          <img src={configurationProvider.cdn + "client/text.svg"} alt="b" />
                          <div className="pink link">
                            <Text value="HEALTH_LINK_CHECKBOX_4" />
                          </div>{" "}
                        </StyledBullet>
                      }
                    >
                      <div className="tabSpace">
                        <Text value="HEALTH_LINK_CHECKBOX_4_DESCRIPTION" />
                      </div>
                    </Dropdownlink>

                    <h4>
                      <Text value="CHECKPOINT" bold />
                    </h4>
                    {Array(3)
                      .fill()
                      .map((x, i) => (
                        <StyledBullet href={"https://niped.nl/" + GetLocalizationValue("HEALTH_CHECKPOINT_" + i, language)} target="_blank" rel="noreferrer">
                          <img src={configurationProvider.cdn + "client/text.svg"} alt="b" />
                          <div className="link pink">
                            <Text value={"HEALTH_LINK_CHECKPOINT_" + i} />
                          </div>
                        </StyledBullet>
                      ))}
                  </div>
                  <br />

                  <div>
                    <h3>
                      <Text value="MATERIALS" bold />
                    </h3>
                    <Text value="MATERIALS_TEXT"> </Text>
                    {Array(3)
                      .fill()
                      .map((x, i) => (
                        <StyledBullet href={"https://niped.nl/" + GetLocalizationValue("MATERIALS_" + i, language)} target="_blank" rel="noreferrer">
                          <img src={configurationProvider.cdn + "client/file.svg"} alt="b" />{" "}
                          <div className="link pink">
                            <Text value={"MATERIALS_LINK_" + i} />
                          </div>
                        </StyledBullet>
                      ))}

                    <Dropdownlink
                      title={
                        <StyledBullet>
                          <img src={configurationProvider.cdn + "client/folder.svg"} alt="p" />
                          <div className="link pink">
                            <Text value="MATERIALS_LINK_3" />
                          </div>
                        </StyledBullet>
                      }
                    >
                      <div className="tabSpace ">
                        <Text value="RECEPT_TEXT" />
                      </div>{" "}
                      {Array(5)
                        .fill()
                        .map((x, i) => (
                          <StyledBullet className="pink link tabSpace" href={"https://niped.nl/" + GetLocalizationValue("RECEPT_" + i, language)} target="_blank" rel="noreferrer">
                            <img src={configurationProvider.cdn + "client/file.svg"} alt="p" />

                            <Text value={"RECEPT_LINK_" + i} />
                          </StyledBullet>
                        ))}
                    </Dropdownlink>
                  </div>
                </div>
                <div className="sidePic">
                  <img src={"https://niped.nl/wp-content/uploads/2023/09/Toolkit-mockup.jpg"} alt="communication_banner" />
                </div>
              </TwoCol>
            </div>
            <div className="communication">
              <TwoCol>
                {" "}
                <div className="sidePic">
                  <img src={"https://niped.nl/wp-content/uploads/2023/09/leidinggevenden-op-hoogte-brengen-poster.jpg"} alt="banner3" />
                </div>
                <div>
                  <div>
                    <img width={"17px"} src={configurationProvider.cdn + "client/clock_cl.svg"} alt="time" /> <Text value="DATE_TO_START_2" />
                  </div>
                  <div className="OneCol">
                    <h1>
                      <Text value="FINAL_CALL_TITLE" bold />
                    </h1>
                    <Text value="FINAL_CALL_TEXT"> </Text>
                    <h3>
                      <Text value="TEXTS" bold />
                    </h3>

                    <StyledBullet href={"https://niped.nl/" + GetLocalizationValue("FINAL_CALL_0", language)} target="_blank" rel="noreferrer">
                      <img src={configurationProvider.cdn + "client/text.svg"} alt="p" />
                      <div className="link pink">
                        <Text value={"FINAL_CALL_LINK_0"} />
                      </div>
                    </StyledBullet>
                    <Dropdownlink
                      title={
                        <StyledBullet>
                          <img src={configurationProvider.cdn + "client/text.svg"} alt="p" />
                          <div className="link pink">
                            <Text value="FINAL_CALL_LINK_1" />
                          </div>
                        </StyledBullet>
                      }
                    >
                      <div className="tabSpace pink">
                        <Text value="FINAL_CALL_WHAT" />
                      </div>
                      <div className="tabSpace">
                        <Text value={"FINAL_CALL_LINK_1_DESCRIPTION"} />{" "}
                      </div>
                    </Dropdownlink>
                  </div>
                </div>
              </TwoCol>
            </div>
            <div className="communication">
              <TwoCol>
                <div>
                  <div>
                    <img width={"17px"} src={configurationProvider.cdn + "client/clock_cl.svg"} alt="time" /> <Text value="DATE_TO_START_3" />
                  </div>
                  <div className="OneCol">
                    <h1>
                      <Text value="CLOSURE_TITLE" bold />
                    </h1>
                    <Text value="CLOSURE_TEXT"> </Text>
                    <h3>
                      <Text value="TEXTS" bold />
                    </h3>

                    {Array(2)
                      .fill()
                      .map((x, i) => (
                        <StyledBullet href={"https://niped.nl/" + GetLocalizationValue("CLOSURE_" + i, language)} target="_blank" rel="noreferrer">
                          <img src={configurationProvider.cdn + "client/text.svg"} alt="p" />
                          <div className="link pink">
                            <Text value={"CLOSURE_LINK_" + i} />
                          </div>
                        </StyledBullet>
                      ))}
                  </div>
                </div>{" "}
                <div className="sidePic">
                  <img src={"https://niped.nl/wp-content/uploads/2023/10/gesprek-buiten-vitaal.jpg"} alt="banner4" />
                </div>
              </TwoCol>
            </div>{" "}
            <div className="mainMaterial">
              <h1>
                <Text value="MAIN_MATERIAL" />
              </h1>
              <Text value="MAIN_MATERIAL_DESCRIPTION" />
              <StyledBullet href={"https://niped.nl/kennisbank"}>
                <img className="folder" src={configurationProvider.cdn + "client/folder.svg"} alt="p" />
                <div className="link pink">
                  <Text value="TIP_MAIN_MATERIAL" />
                </div>
              </StyledBullet>
              <div>
                <h3>
                  <Text value="DOWNLOAD_IMG_1" />
                </h3>
                <div className="downloadImgs">
                  <a href="https://niped.nl/wp-content/uploads/2021/10/PGC-logo.png" target="_blank" rel="noreferrer">
                    <img width={"40%"} src={configurationProvider.cdn + "client/PGC-logo.png"} alt="l" />
                  </a>
                  <a href="https://niped.nl/wp-content/uploads/2021/10/PGC-logo-light.png" target="_blank" rel="noreferrer">
                    <img width={"40%"} src={configurationProvider.cdn + "client/PGC-logo-light.png"} alt="l" />
                  </a>
                </div>
              </div>
              <div>
                <h3>
                  <Text value="DOWNLOAD_IMG_2" />
                </h3>
                <div className="downloadImgs">
                  <a href="https://niped.nl/wp-content/uploads/2021/12/gezondheidsomgeving-op-mobiel.jpg" target="_blank" rel="noreferrer">
                    <img width={"40%"} src={configurationProvider.cdn + "client/gezondheidsomgeving-op-mobiel.png"} alt="l" />
                  </a>
                  <a href="https://niped.nl/wp-content/uploads/2023/03/Checkbox-gezondheidscheck-van-niped.png" target="_blank" rel="noreferrer">
                    <img width={"40%"} src={configurationProvider.cdn + "client/Checkbox-gezondheidscheck-van-niped.png"} alt="l" />
                  </a>
                </div>{" "}
              </div>
              <div>
                <h3>
                  <Text value="DOWNLOAD_IMG_3" />
                </h3>
                <Text value="DOWNLOAD_IMG_3_DESCRIPTION" />
                <br />
                <div className="downloadImgs">
                  <a href="https://niped.nl/wp-content/uploads/2021/10/Bloeddrukinstructie.png" target="_blank" rel="noreferrer">
                    <img width={"40%"} src={configurationProvider.cdn + "client/Bloeddrukinstructie.png"} alt="l" />
                  </a>

                  <a href="https://niped.nl/wp-content/uploads/2021/10/Meetwaarden_A5.png" target="_blank" rel="noreferrer">
                    <img width={"40%"} src={configurationProvider.cdn + "client/Meetwaarden_A5.png"} alt="l" />
                  </a>
                </div>{" "}
              </div>
              <div>
                <h3>
                  <Text value="DOWNLOAD_IMG_4" />
                </h3>

                <div className="downloadImgs">
                  <a href="https://niped.nl/wp-content/uploads/2021/09/Nudging-1.png" target="_blank" rel="noreferrer">
                    <img width={"40%"} src={configurationProvider.cdn + "client/Nudging-1.png"} alt="l" />
                  </a>
                </div>
              </div>{" "}
            </div>{" "}
            <div className="bottomTextBtns">
              <Text value="ALL_INFORMATION_SAVED"></Text>
              <ButtonGroup spaceBetween>
                <Button
                  styleType={5}
                  type="button"
                  width="48%"
                  onClick={() => {
                    navigate(viewModel.nextStep());
                  }}
                >
                  <Text value="LATER_CONTINUE" />
                </Button>
                <Button
                  width="48%"
                  onClick={async (event) => {
                    event.preventDefault();
                    viewModel.finish();
                  }}
                >
                  <Text value="SEND" />
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </StyledBlock>{" "}
      </CommunicationForm>
    </FullPage>
  );
};

export default Communication;
