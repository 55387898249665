import styled from "styled-components";

export const SvgIcon = styled.div`
  ${(props) =>
    props.bottom &&
    `position: absolute;
    bottom: 0;
    left: 0;`}
  .svg-plus {
    height: 40px;
    stroke: white;
  }
  .trash {
    height: 20px;
    fill: var(--color-pink);
  }
  .download {
    transform: rotate(180deg);
  }
`;
