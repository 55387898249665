import { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";

import { AppContext } from "../../global/App/AppState";
import { StorageContext } from "../../global/Storage/StorageState";
import { useEffect } from "react";
import { WebServiceContext } from "../../global/WebService/WebServiceState";

const HomeViewModel = () => {
  const navigate = useNavigate();
  const { storageProvider } = useContext(StorageContext);
  const { error, setError, clientWebService } = useContext(WebServiceContext);
  const { appProvider } = useContext(AppContext);
  const [stats, setStats] = useState({});
  const [isBusy, setIsBusy] = useState(false);

  const [dependancies] = useState({
    isBusy: isBusy,
    clientWebService: clientWebService,
    project: storageProvider.project,
  });

  useEffect(() => {
    if (storageProvider.project) {
      const fetchData = async function (clientWebService) {
        let data = await clientWebService.getProjectData(storageProvider.project.globalId);
        // const temporaryGUID = "07AE9A80-D27B-46C9-9AB0-9350C07E8790";
        // let data = await clientWebService.getProjectData(temporaryGUID);

        setStats(data);
      };

      if (!dependancies.isBusy) {
        setIsBusy(true);
        fetchData(dependancies.clientWebService);
        setIsBusy(false);
      }
      return () => {};
    }
  }, [dependancies, storageProvider.project.globalId]);

  function onNavigate(page) {
    navigate(`/${page}`);
  }

  return {
    viewModel: {
      onNavigate: onNavigate,
      sections: appProvider.sections,
      stats: stats,
    },
  };
};

export default HomeViewModel;
