import styled from 'styled-components';

const Page = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  min-height: ${(props) => (props.minHeight ? props.minHeight : "447px")};

  width: ${(props) => (props.width ? props.width : "40%")};
  margin: ${(props) => (props.parentMargin ? props.parentMargin : "auto")};
  .title {
    margin: 15px 23%;
  }


  @media (min-width: 1024px) {
    width:820px;
  }

  @media (max-width: 1024px) {
    width: 80%;
  }

  .fullsize {
    margin: ${(props) => props.minPadding && props.minPadding};
    width: auto;
  }
  .carousel {
    margin: auto;
  }
  .flex {
    display: flex;
  }
  .spaceBetween {
    justify-content: space-between;
  }
`;

export default Page;


export const FullPage = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;

  margin: ${(props) => (props.parentMargin ? props.parentMargin : "auto")};
  .title {
    margin: 15px 23%;
  }


  .fullsize {
    margin: ${(props) => props.minPadding && props.minPadding};
    width: auto;
  }
  .carousel {
    margin: auto;
  }
  .flex {
    display: flex;
  }
  .spaceBetween {
    justify-content: space-between;
  }
`;
