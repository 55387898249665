import styled from "styled-components";

import Form from "../../components/containers/Form";

export const CommunicationForm = styled(Form)`
  .download_language {
    img {
      width: 20px;
    }
    cursor: pointer;
    text-align: center;
    margin: 30px 0;
  }
  .link {
    margin: 5px 5px;
  }
  .bottomTextBtns {
    margin-top: 20px;
    width: 50%;
    float: right;
    text-align: right;
    font-style: italic;
  }
  .bold {
    font-family: bold;
  }
  .box {
    background: white;
  }
  .back {
    margin: 20px 0;
  }
  h1,
  h3,
  h4 {
    color: var(--color-primary);
  }

  h3 {
    font-size: 20px;
  }

  .form {
    padding: 10px 20%;
  }

  @media (max-width: 1200px) {
    .form {
      padding: 10px 10%;
    }
  }

  .marginTop {
    margin-top: 10px;
  }
  .marginMinTop {
    margin-top: -5%;
  }
  .margin30 {
    margin: 30px 0;
  }
  .add_location_button {
    display: flex;
    .button {
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }
  .flex {
    display: flex;
  }
  .spaceBetween {
    justify-content: space-between;
  }
  .mainMaterial {
    padding: 50px;
    background-color: #fff;
    box-shadow: 1px 1px 25px 1px rgba(15, 0, 0, 0.1);
    .folder {
      width: 20px;
    };
     .downloadImgs>a{
    margin-right: 10px;
    cursor:pointer;}
    
    h3{margin: 20px 0}
}
   
  }
`;
export const StyledBlock = styled.div`
  background-color: var(--color-back-grey);
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    width: 80%;
  }
  .OneCol {
    margin: 15px 0;
  }
  .tabSpace {
    margin-left: 35px;
    margin-top: 10px;
  }
  .communication {
    margin: 80px 0;
  }
  .bottomTextBtns {
    margin-top: 20px;
    width: 50%;
    float: right;
    text-align: right;
    font-style: italic;
    @media (max-width: 400px) {
      width: 100%;
    }
  }
`;
export const StyledBullet = styled.a`
 display flex;
 img{margin-right: 10px;}
 margin:10px 0;
 
`;

export const TwoCol = styled.div`
  display: flex;
  justify-content: space-between;
  img {
    width: 20px;
  }
  @media (max-width: 768px) {
    display: block;
  }
  h3 {
    font-family: bold;
  }
  .question_title {
    height: 70px;
  }
  .bottom {
    vertical-align: bottom;
    display: table-cell;
  }
  > div {
    :first-child,
    :last-child {
      width: 49%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
    a {
      margin: 00px 5px;
      color: var(--color-pink);
      text-decoration: underline;
    }
  }
  .sidePic {
    img {
      width: 100%;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
  }
`;
