import React, { useState, useContext } from "react";
import styled from "styled-components";
import Form from "../../components/containers/Form";
import ExcelUpload from "../../components/excelupload/excelupload";
import { ErrorMessage, SuccessMessage, SuccessValidation } from "../../components/messages/messages";
import Modal from "../../components/modals/Modal";
import Text from "../../components/text/Text";
import Button, { ButtonGroup, UploadButton } from "../../components/buttons/Button";
import Checkbox from "../../components/inputs/Checkbox";
import { emailPattern, namePattern } from "../../utils/inviteesUtils";
import Input from "../../components/inputs/Input";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";
import { ErrorValidation } from "../../components/containers/Error";

const LabelButton = styled.label`
  height: 50px;
  background-color: #0060a8;
  border-radius: 0px 5px 5px 0px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  font-size: 18px;
  min-width: 180px;
  text-align: center;
  line-height: 50px;
  margin-right: 15px;
  padding: 15px;
  position: relative;
  .excelinput {
    position: fixed;
    top: -1000px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }

  ${(props) =>
    props.icon
      ? `
    
    padding-right:45px;

    :before {
        font-family: fontAwesome;
        content: ${props.icon};
        position:absolute;
        right:15px;
         }
    `
      : ``};
`;
const AddEmployee = (props) => {
  const [state, setState] = useState({
    cell: "",
    firstname: "",
    lastname: "",
    email: "",
    showModal: false,
    message: "",
  });

  const onChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  const onSubmit = () => {
    props.onAdd([{ firstname: state.firstname, lastname: state.lastname, email: state.email }]);

    setState({ ...state, firstname: "", lastname: "", email: "", message: state.email });
  };

  const onExcelUpload = (data) => {
    props.onAdd(data);
  };

  const renderModal = () => {
    const { name, email, message } = state;
    return (
      <Modal onClose={() => setState({ ...state, showModal: false, message: "" })}>
        <Form onSubmit={onSubmit}>
          {message && (
            <SuccessMessage>
              {message} <Text value="EMAIL_ADDED_TO_LIST" />
            </SuccessMessage>
          )}
          <Text value="NAME_EMPLOYEE" />
          <Input backgroundColor={"#f4f4f4"} required ref={(input) => (this.nameInput = input)} type="text" onChange={onChange("name")} value={name} pattern={namePattern} />
          <Text value="EMAIL" />
          <Input backgroundColor={"#f4f4f4"} required type="text" onChange={onChange("email")} value={email} pattern={emailPattern} />
          <ButtonGroup spaceBetween>
            <Button styleType={2} type="button" width="18%" onClick={() => setState({ ...state, showModal: false, message: "" })}>
              <Text value="CLOSE" />
            </Button>
            &nbsp;
            <Button>
              <Text value="ADD_EMPLOYEE" />
            </Button>
          </ButtonGroup>
        </Form>
      </Modal>
    );
  };

  const onManualEntry = () => {
    setState({ ...state, showModal: true }, () => this.nameInput);
  };

  const { showModal } = state;

  const { showFilter } = props;
  return (
    <div>
      {showModal && renderModal()}
      {/* <LabelButton icon={`url(${props.icon})`}>
        <Text value="UPLOAD_EXCEL" />
        <ExcelUpload onError={props.onError} sheet="Genodigde" onChange={onExcelUpload} />
      </LabelButton> */}
      {/* <LabelButton icon="f067" onClick={onManualEntry}>
        <Text value="MANUAL_ENTRY" />
      </LabelButton> */}{" "}
      <ExcelUpload onError={props.onError} sheet="Genodigde" onChange={onExcelUpload} />
      {/* <ErrorValidation width="300px" value={props.error} />
      <SuccessValidation width="300px" value={props.success} /> */}
    </div>
  );
};

export default AddEmployee;
