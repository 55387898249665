// import React, { useState } from "react";
// import styled from "styled-components";
// import { SuccessMessage } from "../../components/messages/messages";
// import Modal from "../../components/modals/Modal";
// import Text from "../../components/text/Text";
// import Button, { ButtonGroup } from "../../components/buttons/Button";
// import { TextField } from "@mui/material";
// import QuantityInput from "../../components/increment/increment";
// import { TwoCol } from "./ProjectQuestions.Components";

// const LabelButton = styled.label`
//   display: flex;
//   height: 30px;
//   width:30px;
//   background-color: #0060a8;
//   border-radius: 0px 5px 5px 0px;
//   color: white;
//   font-size: 16px;
//   cursor: pointer;
//   text-align: center;
//   line-height: 20px;
//   padding: 8px;
//   justify-content: space-between;
//   position: relative;
//   .excelinput {
//     position: fixed;
//     top: -1000px;
//   }

// `;

// const Question = styled.div`
// cursor: pointer;
// background: #FFFFFF 0% 0% no-repeat padding-box;
// box-shadow: 4px 6px 35px #0000001A;
//   display: flex;
//   padding: 10px;
//   justify-content: space-between;
//   width: 100%;
// `;
// const AddQuestion = (showModal) => {
//   const [state, setState] = useState({
//     cell: "",
//     tav: "",
//     street: "",
//     number: "",
//     postcode: false,
//     city: "",
//     total: "",
//     description: "",
//   });

//   // const onChange = (name) => (event) => {
//   //   setState({ ...state, [name]: event.target.value });
//   // };

//   const onSubmit = (e) => {
//     e.preventDefault();
//     setState({ showModal: true, status: true, street: "" });
//   };

//   const renderModal = () => {
//     const { message } = state;
//     return (
//       <Modal onClose={() => setState({ ...state, showModal: false, message: "" })} className="modal">
//         {" "}
//         {message && (
//           <SuccessMessage>
//             {message} <Text value="SUCCESFULLY_ADDED" />
//           </SuccessMessage>
//         )}
//         <h1 className="margin20">
//           <Text value="ADD_LOCATION_BLOODPRESSURE_TITLE" />
//         </h1>
//         <div className="margin20">
//           {" "}
//           <Text value="ADD_LOCATION_BLOODPRESSURE_TEXT" />
//         </div>
//         <TwoCol>
//           <div>
//             {" "}
//             <TextField style={{ width: "100%" }} id="filled-basic" label="T.a.v" variant="filled" />
//             <br />
//             <TextField
//               style={{ width: "70%" }}
//               id="filled-basic"
//               label="Street"
//               name="street"
//               variant="filled"
//               value={state.street}
//               onChange={(e) => {
//                 setState({ ...state, ...{ [e.target.name]: e.target.value } });
//               }}
//             />
//             <TextField style={{ width: "30%" }} id="filled-basic" label="Number" variant="filled" />
//             <br />
//             <TextField style={{ width: "30%" }} id="filled-basic" label="Postcode" variant="filled" />
//             <TextField style={{ width: "70%" }} id="filled-basic" label="City" variant="filled" inputProps={{ pattern: "^[a-zA-Z]" }} />
//           </div>
//           <div>
//             {" "}
//             <TextField className="hidden" style={{ width: "100%" }} />
//             <br />
//             <QuantityInput />
//             <TextField style={{ width: "100%" }} id="filled-basic" label="Description" variant="filled" helperText="DESCRIPTION_HELP_TEXT" />
//           </div>
//         </TwoCol>
//         <ButtonGroup spaceBetween >
//           <Button styleType={5} type="button" width="18%" onClick={() => setState({ ...state, showModal: false, message: "" })}>
//             <Text value="CANCEL" />
//           </Button>
//           &nbsp;
//           <Button onClick={(e) => onSubmit(e)}>
//             <Text value="ADD_LOCATION" />
//           </Button>
//         </ButtonGroup>
//       </Modal>
//     );
//   };

//   const onManualEntry = () => {
//     setState({ ...state, showModal: true }, () => this.nameInput);
//   };

//   const { showModal } = state;
//   return (
//     <>
//       {showModal && renderModal()}
//       <Question onClick={onManualEntry} className="question">
//         <div className="question">aaa</div>
//         <LabelButton className="button" icon="f067" >
//           <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="#ffffff">
//             <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
//           </svg>
//         </LabelButton>
//       </Question>
//     </>
//   );
// };

// export default AddQuestion;

import React from "react";
import styled from "styled-components";

const LabelButton = styled.label`
  display: flex;
  height: 30px;
  width: 30px;
  background-color: #0060a8;
  border-radius: 0px 5px 5px 0px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  line-height: 20px;
  padding: 8px;
  justify-content: space-between;
  position: relative;
  .excelinput {
    position: fixed;
    top: -1000px;
  }
`;

const Question = styled.div`
  cursor: pointer;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 4px 6px 35px #0000001a;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  line-height: 35px;
`;
const AddQuestion = ({ addQuestion, id, name }) => {

  return (
    <>
      <Question onClick={()=>addQuestion(id)} className="question">
        <div className="question">{name}</div>
        <LabelButton className="button" icon="f067">
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" fill="#ffffff">
            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
          </svg>
        </LabelButton>
      </Question>
    </>
  );
};

export default AddQuestion;
