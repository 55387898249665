import React, { useState, useContext, useCallback } from "react";
import { ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Text from "../text/Text";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";
import { fontStyle } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import SelectVariants from "../inputs/selectInput";
import moment from "moment";
import Dropdownlink from "../dropdownlink/dropdownlink";
import { getStepURL, steps } from "../progress/ProgressStepBar";
import { StyledSideBar } from "./sidebar.Components";
import { StorageContext } from "../../global/Storage/StorageState";
import { getDirectionOnboarding } from "../../pages/login/Login.VM";

const drawerWidth = 300;

const Circle = styled("div")(() => ({
  background: "#F3F4F4",
  width: "40px",
  height: "40px",
  borderRadius: "50%",
  color: "#333333",
  textAlign: "center",
  verticalAlign: "middle",
  lineHeight: "40px",
}));

const DrawerHeader = styled("div", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  margin: open ? "0 10px" : "0 auto",
  padding: "20px",
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme, margin) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  height: "900px",

  overflow: "hidden",
  // marginTop: margin,
  position: "relative",
  background: "#ffffff00",
  border: "0px solid #F3F4F4",
});

const closedMixin = (theme, margin) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: "hidden",
  height: "900px",

  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  background: "#ffffff00",
  position: "relative",
  // marginTop: margin,
  border: "0px solid #F3F4F4",
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open, margin }) => ({
  width: drawerWidth,
  // boxShadow: "inset 0 32px 20px -20px rgb(156 154 154 / 24%)",
  flexShrink: 0,
  height: "800px",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, margin),
    "& .MuiDrawer-paper": openedMixin(theme, margin),
  }),
  ...(!open && {
    ...closedMixin(theme, margin),
    "& .MuiDrawer-paper": closedMixin(theme, margin),
  }),
}));

const getIcon = (index) => {
  const icons = ["home_menu.svg", "projects_menu.svg", "insights_menu.svg", "settings_menu.svg", "invitees_menu.svg"];
  return icons[index] || "";
};

const MenuItem = ({ item, index, open, current, setCurrent, navigate, configurationProvider, visible, isVisible, steps }) => (
  <ListItem key={item.title} disablePadding sx={{ display: "block" }}>
    {item.dropdownMenu && item.dropdownMenu.length && open ? (
      <Dropdownlink
        dropdown={isVisible}
        title={
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              // px: 2.5,
              margin: "0 10px",
              color: current === item.value.toLowerCase() && !visible ? "var(--color-pink)" : "",
              span: {
                fontFamily: "Bold",
              },
            }}
            onClick={() => setCurrent(item.title.toLowerCase())}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                filter: current === item.value.toLowerCase() && !visible ? "brightness(0) saturate(100%) invert(34%) sepia(62%) saturate(3630%) hue-rotate(320deg) brightness(92%) contrast(96%)" : "",
              }}
            >
              <img width="20px" src={`${configurationProvider.cdn}client/${getIcon(index)}`} alt="icon" />
            </ListItemIcon>

            <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />

            <ListItemIcon
              sx={{
                justifyContent: "center",
                transform: current === item.value.toLowerCase() && !visible ? "rotate(270deg)" : "rotate(90deg)",
                filter: current === item.value.toLowerCase() && !visible ? "brightness(0) saturate(100%) invert(34%) sepia(62%) saturate(3630%) hue-rotate(320deg) brightness(92%) contrast(96%)" : "",
              }}
            >
              <img width="8px" src={`${configurationProvider.cdn}client/arrowGrey.svg`} alt="icon" />
            </ListItemIcon>
          </ListItemButton>
        }
      >
        {current === item.value.toLowerCase() &&
          steps.map((step, stepIndex) => (
            <ListItemButton key={step.name + "drp" + stepIndex} onClick={() => navigate(getStepURL(step.name))}>
              <div className="tabSpace">
                <Text value={step.name} color={current === step.name.toLowerCase() ? "red" : ""} />
              </div>
            </ListItemButton>
          ))}
      </Dropdownlink>
    ) : (
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          // px: 2.5,
          margin: "0 10px",
          color: current === item.value.toLowerCase() ? "var(--color-pink)" : "",
          span: {
            fontFamily: "Bold",
          },
        }}
        onClick={() => {
          setCurrent(item.value.toLowerCase());
          navigate(item.value.toLowerCase());
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
            filter: current === item.value.toLowerCase() ? "brightness(0) saturate(100%) invert(34%) sepia(62%) saturate(3630%) hue-rotate(320deg) brightness(92%) contrast(96%)" : "",
          }}
        >
          <img width="20px" src={`${configurationProvider.cdn}client/${getIcon(index)}`} alt="icon" />
        </ListItemIcon>

        <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    )}
  </ListItem>
);

const Profile = ({ open }) => (
  <List className="profile">
    <ListItem key="profile" disablePadding sx={{ display: "block" }}>
      <ListItemButton sx={{ minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5, padding: "20px" }}>
        <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" }}>
          <Circle>NP</Circle>
        </ListItemIcon>
        <ListItemText
          primary={
            <div>
              First Last Name <br />
              email@niped.nl
            </div>
          }
          sx={{ opacity: open ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  </List>
);

export default function MiniDrawer() {
  const [current, setCurrent] = useState("");
  const [visible, setVisible] = useState(null);
  const { configurationProvider } = useContext(ConfigurationContext);

  const { storageProvider } = useContext(StorageContext);

  const configurationSteps = storageProvider.project.configurationSteps || [];
  const [open, setOpen] = useState(false);
  // const scrollPosition = useScrollPosition();
  // const [margin, setMargin] = useState(140);
  // const [scroll, setScroll] = useState(0);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (margin === 140 && scrollPosition > 80) setMargin(0);
  //   else if (margin === 0 && scrollPosition < 80) setMargin(140);
  //   if (scrollPosition > 600) setScroll((scrollPosition - 550) / 10);
  // }, [margin, scrollPosition]);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);
  // const height = useMemo(() => (margin === 0 ? `${90 - scroll}%` : "84%"), [margin, scroll]);
  const isVisible = useCallback((value) => setVisible(value), []);

  return (
    <StyledSideBar>
      <ThemeProvider theme={fontStyle}>
        <Drawer variant="permanent" open={open} margin={140}>
          <DrawerHeader open={open} sx={{ justifyContent: "space-between" }}>
            {open && <Text fontSize="22px" value="ORG_NAME" color={"var(--color-tertiary)"} bold />}
            <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen} sx={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}>
              <img width="8px" src={`${configurationProvider.cdn}client/arrowGrey.svg`} alt="toggle" />
            </IconButton>
          </DrawerHeader>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", textAlign: "center", height: "100%" }}>
            <div>
              <SelectVariants
                selectedValue={storageProvider.project && storageProvider.project.globalId}
                setValue={(value) => storageProvider.setProject(storageProvider.projects.find((x) => x.globalId === value))}
                title="PROJECT"
                values={convertValueName(storageProvider.projects)}
                wide={open}
              />
              <List sx={{ paddingTop: "30px" }}>
                {[
                  {
                    title: "Home",
                    value:
                      storageProvider.project && storageProvider.project.states.data.some((item) => item.state === 2)
                        ? "Home"
                        : storageProvider.project
                        ? getDirectionOnboarding(storageProvider.project)
                        : "Communication",
                  },
                  { title: "Onboarding", value: "Onboarding", dropdownMenu: configurationSteps.data },
                  { title: "Inzicht", value: "Inzicht", dropdownMenu: takenSteps },
                  { title: "Settings", value: "Settings" },
                ].map((item, index) => (
                  <MenuItem
                    key={item.title}
                    item={item}
                    index={index}
                    open={open}
                    current={current}
                    setCurrent={setCurrent}
                    navigate={navigate}
                    configurationProvider={configurationProvider}
                    visible={visible}
                    isVisible={isVisible}
                    steps={item.dropdownMenu || null}
                  />
                ))}
              </List>
            </div>
            <div className="bottomAccount">
              <Profile open={open} />
            </div>
          </Box>
        </Drawer>
      </ThemeProvider>
    </StyledSideBar>
  );
}
const convertValueName = (array) =>
  array.map((item) => ({
    value: item.globalId,
    startDate: moment(item.startDate).format("DD/MM/YYYY"),
    name: item.name,
  }));

export const takenSteps = [
  { name: "Overview", time: "5", url: "/overview" },
  { name: "Data", time: "5", url: "/macroreport" },
  { name: "Work", time: "5", url: "/work" },
];
