import React from "react";
import Text, { Bullet } from "../../components/text/Text";
import { FullPage } from "../../components/containers/Page";
import WelcomeViewModel from "./WelcomePage.VM";
// import Moment from "moment";
import { WelcomePageForm, StyledBlock } from "./WelcomePage.Components";
import Button from "../../components/buttons/Button";

const WelcomePage = () => {
  const { viewModel } = WelcomeViewModel();
  return (
    <FullPage width="true" parentMargin="0 -10%">
      <WelcomePageForm>
        <StyledBlock className="fullsize">
          <div className="form marginTop">
            <h1 className="margin30">
              <Text value="WELCOME" />, {viewModel.user && viewModel.user.username}
            </h1>
            <div className="margin30">
              {" "}
              <Text value="WELCOME_TEXT" />
            </div>

            <h3>
              <Text value="WELCOME_STEP_TITLE" bold />
            </h3>
            <div>
              <Bullet>
                <Text value="STEP_TEXT_1" />
              </Bullet>
              <Bullet>
                <Text value="STEP_TEXT_2" />
              </Bullet>
              <Bullet>
                <Text value="STEP_TEXT_3" />
                {/* <span className="bold"> {viewModel.project && Moment(viewModel.project.startDate).format("DD-MM-YYYY")} </span> */}
              </Bullet>
            </div>
            <div className="margin30">
              <Text value="OPEN_STEP_1" />
            </div>

            <div className="bottomTextBtns">
              <Button
                width="48%"
                onClick={async (event) => {
                  event.preventDefault();
                  viewModel.start(viewModel.project.globalId);
                }}
              >
                <Text value="GO_TO_STEP_1" />
              </Button>
            </div>
          </div>
        </StyledBlock>
      </WelcomePageForm>
    </FullPage>
  );
};

export default WelcomePage;
