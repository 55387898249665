import React, {useState} from "react";

import Modal from "../../components/modals/Modal";

export const ModalContext = React.createContext();

export const ModalProvider = ({children}) => {

    const [modal, setModal] = useState(null);

    const provider = {
        modalProvider: {
            showModal,
            hideModal
        }
    };

    function showModal(title, text, elements, onConfirm, onCancel, confirmButtonText, cancelButtonText, onClose, style) {
        setModal({
            title: title,
            text: text,
            elements: elements,
            onConfirm: onConfirm,
            onCancel: onCancel,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            onClose: onClose || hideModal,
            style:style
        });
    }

    function hideModal() {
        setModal(null);
    }

    return (
        <ModalContext.Provider value={provider}>
            {(!modal) ? '' :
                <Modal title={modal.title} text={modal.text} elements={modal.elements} onConfirm={modal.onConfirm} onCancel={modal.onCancel} onClose={modal.onClose} confirmButtonText={modal.confirmButtonText} cancelButtonText={modal.cancelButtonText} style={modal.style} />
            }
            {children}
        </ModalContext.Provider>
    )
};