import React, { useContext } from "react";
import Text from "../text/Text";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { StorageContext } from "../../global/Storage/StorageState";
export const steps = [
  { name: "Communication", time: "5", url: "/communication" },
  { name: "Invitees", time: "5", url: "/invitees" },
  { name: "XQuestions", time: "5", url: "/projectquestions" },
  { name: "Bloodpressure", time: "5", url: "/bloodpressure" },
  { name: "WhiteList", time: "5", url: "/whitelist" },
  // { name: "Interventions", time: "5", url: "/interventions" },
  // { name: "Checkpoints", time: "5", url: "/checkpoints" },
  { name: "Check", time: "5", url: "/controle" },
];
const StyledProgressBar = styled.div`
  display: flex;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 4px 6px 15px;
  margin-top: -60px;

  .content-block {
    text-align: center;
    cursor: pointer;
    width: ${100 / steps.length}%;
    padding: 20px 0;
  }
  .active {
    background-color: #e7336f14;
  }

  .active > .number-circle {
    background-color: var(--color-pink);
  }

  .passed > .number-circle {
    background-color: var(--color-green);
  }

  .number-circle {
    border: 4px solid #f3f4f4;
    width: 35px;
    height: 35px;
    background-color: #9e9e9e;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: -35px;
    font-size: 13px;
  }

  .content-info h2 {
    margin: 10px 0 5px;
    font-size: 13px;
  }

  .content-info p {
    font-size: 14px;
  }
  .content-time > div:first-child {
    justify-content: center;
    img {
      margin: 0 5px;
    }
  }

  @media (max-width: 768px) {
    width: 0;
    height: 20px;
    visibility: hidden;
  }
`;

const checkmark =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi42OTciIGhlaWdodD0iOS40NSIgdmlld0JveD0iMCAwIDEyLjY5NyA5LjQ1Ij4KICA8cGF0aCBpZD0iUGF0aCIgZD0iTTQuNTM1LDkuNDUsMCw0LjkxNSwxLjI3OSwzLjYzNyw0LjUzNSw2Ljg4MywxMS40MTgsMCwxMi43LDEuMjg4WiIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4=";
const ContentBlock = ({ number, headline, text, onClick, active, passed }) => (
  <div className={`content-block ${active ? "active" : passed ? "passed" : ""} `} onClick={onClick}>
    <div className="number-circle">{passed ? <img src={checkmark} alt="_" /> : number}</div>
    <div className="content-info">
      <b>
        <Text value={headline.toUpperCase() + "_STEP"} />
      </b>
      <div className="content-time">{text} </div>
    </div>
  </div>
);

export const getStepURL = (name) => {
  const value = {
    Communicatie: "/communication",
    Genodigden: "/invitees",
    "Extra vragen": "/projectquestions",
    Bloeddrukmeters: "/bloodpressure",
    "Whitelisten mail": "/whitelist",
    Controle: "/controle",
  };
  return value[name];
};

export const validateDate = (date) => {
  if (!date) return false;

  const year = new Date(date).getFullYear();
  return year > 2000;
};

const ProgressStepBar = ({ currentStep }) => {
  const navigate = useNavigate();
  const { configurationProvider } = useContext(ConfigurationContext);

  const { storageProvider } = useContext(StorageContext);

  const configurationSteps = storageProvider.project.configurationSteps || [];

  return (
    <StyledProgressBar steps={configurationSteps.data}>
      {configurationSteps.data
        .sort((a, b) => a.order - b.order)
        .map((step, index) => (
          <ContentBlock
            onClick={() => navigate(getStepURL(step.name))}
            number={index + 1}
            key={step.name + index}
            headline={step.name.replace(/\s+/g, "")}
            text={
              <div className="flex">
                <img src={configurationProvider.cdn + "icons/clock.svg"} alt="t" /> {step.durationInMinutes} <Text value={"MIN"} />
              </div>
            }
            active={index + 1 === currentStep}
            passed={validateDate(step.created)}
          />
        ))}
    </StyledProgressBar>
  );
};

export default ProgressStepBar;
