import React from "react";
import * as XLSX from "xlsx";
import { UploadButton } from "../buttons/Button";

const ExcelExtractor = (props) => {
  const extractColumns = (sheet) => {
    const cells = Object.keys(sheet);

    return cells.map((cell) => {
      if (cell.startsWith("!") || cell.startsWith("B") || cell === "A1") return false;

      //  if (!sheet) {
      //       props.onError("NO_SHEET");

      //       return;
      //     }

      const index = cell.replace("A", "");
      return {
        cell: cell.slice(1),
        firstname: sheet[cell].v,
        email: sheet["B" + index].v,
      };
    });
  };

  const extractFromWorkbook = (workbook) => {
    const sheet = workbook.Sheets[props.sheet];

    if (!sheet) {
      props.onError("NO_SHEET");

      return;
    }

    if (!(sheet["A1"].v === "Voornaam" && sheet["B1"].v === "Email") || !(sheet["A1"].v === "Voornaam" && sheet["B1"].v === "Email")) {
      props.onError("NOT_CORRECT_EXCEL");
      return;
    }

    props.onError("");

    const columns = extractColumns(sheet).filter((x) => x);

    props.onChange(columns);
  };

  const onChange = (event) => {
    var file = event.target.files[0];

    if (!file) {
      return;
    }

    var reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);

      const workbook = XLSX.read(data, { type: "array" });

      extractFromWorkbook(workbook);
    };

    reader.readAsArrayBuffer(file);
    document.getElementById("uploadFile").value = "";
  };

  return <UploadButton className="excelinput" onChange={onChange} />;
};

export default ExcelExtractor;
