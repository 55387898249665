import React, {useContext} from 'react';

import styled from 'styled-components';

import {LocalizationContext} from '../../global/Localization/LocalizationState';
import { ErrorValidation } from "../containers/Error";

const Input = ({
  name,
  label,
  placeholder,
  value,
  onChange,
  maxLength,
  onInput = null,
  required = true,
  isInvalid = false,
  type = "text",
  pattern = undefined,
  onBlur,
  onFocus,
  marginLeft,
  backgroundColor,
  error,
  className,
  password,
  readOnly,
}) => {
  const { localizationProvider } = useContext(LocalizationContext);

  return (
    <div className={className}>
      <Container
        name={name || label}
        type={type}
        maxLength={maxLength}
        pattern={pattern}
        required={required}
        onBlur={onBlur}
        onFocus={onFocus}
        autoComplete="off"
        placeholder={localizationProvider.getLocalization(placeholder)}
        value={value}
        isInvalid={isInvalid}
        onChange={onChange}
        onInput={onInput}
        marginLeft={marginLeft}
        backgroundColor={backgroundColor}
        error={error}
        readOnly={readOnly}
      ></Container>
      {error && !password && <ErrorValidation input value={error} />}
    </div>
  );
};

export default Input;

const Container = styled.input`
  font-family: "Century Gothic";
  color: inherit;
  border-width: 0px;
  height: 48px;
  align-items: flex-start;
  background-color: ${(props) => (props.error ? "#D4111180" : props.backgroundColor ? props.backgroundColor : "var(--color-tertiary)")};
  border-radius: var(--corner-radius-small);
  mix-blend-mode: normal;
  margin-bottom: ${(props) => (props.error ? "0px" : "15px")};
  width: 100%;
  padding: 0px 25px;
  font-size: 1em;
  @media (min-width: 820px) {
    margin-left: ${(props) => props.marginLeft && "10px"};
  }
  ${(props) => {
    if (props.isInvalid === true) return `background-color: var(--color-invalid);`;
  }}

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-grey);
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--color-grey);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--color-grey);
  }

  &[type="date"] {
    padding: 10px;
    text-align: center;
  }

  &[type="date"]::-webkit-calendar-picker-indicator {
    background: url("../library/icons/datepicker.svg") 0 0 / 24px 24px no-repeat var(--color-white);
  }
`;