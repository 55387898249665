import { useContext, useEffect, useState, useMemo } from "react";
import { WebServiceContext } from "../../global/WebService/WebServiceState";
import { ModalContext } from "../../global/Modal/ModalState";
import { StorageContext } from "../../global/Storage/StorageState";
import { useNavigate } from "react-router-dom";
import { getStepURL, validateDate } from "../../components/progress/ProgressStepBar";
const ProjectQuestionsViewModel = () => {
  const { clientWebService, error } = useContext(WebServiceContext);
  const { storageProvider } = useContext(StorageContext);
  const { modalProvider } = useContext(ModalContext);
  const projectGlobalId = storageProvider.project.globalId;
  const navigate = useNavigate();
  const [isBusy, setIsBusy] = useState(false);
  const [message, setMessage] = useState(false);

  const configurationSteps = storageProvider.project.configurationSteps.data.sort((a, b) => a.order - b.order) || [];
  const currentOrder = configurationSteps.find((x) => x.name === "Extra vragen" || x.name === "Extra questions").order;

  const project = storageProvider.project;

  function nextStep() {
    return getStepURL(configurationSteps.find((x) => x.order === currentOrder + 1).name);
  }

  const [dependancies] = useState({
    isBusy: isBusy,
    clientWebService: clientWebService,
    storageProvider: storageProvider,
  });

  function orderAnswerOptions(questions) {
    return questions.map((question) => {
      if (question.answerOptions && question.answerOptions.length > 1) {
        question.answerOptions.sort((a, b) => a.index - b.index);
      }
      return question;
    });
  }

  useEffect(() => {
    const fetchData = async function (clientWebService, storageProvider) {
      try {
        let defaultQ = [];
        try {
          defaultQ = await clientWebService.getDefaultQuestions();
        } catch (error) {
          console.error("Error fetching project questions:", error);
          defaultQ = [];
        }

        const defaultGlobalIdsQuestion = defaultQ.map((x) => ({ ...x, defaultGlobalId: x.globalId }));

        let projectQ = [];
        try {
          projectQ = addIsModifiedTrue(await clientWebService.getProjectQuestions(projectGlobalId)).map((x) => ({ ...x, defaultGlobalId: x.questionGlobalId }));
        } catch (error) {
          console.error("Error fetching project questions:", error);
          projectQ = [];
        }

        let defaulQFiltered = defaultGlobalIdsQuestion;
        if (projectQ.length) {
          defaulQFiltered = deleteDuplicates(defaultGlobalIdsQuestion, projectQ);
        }
        let combinedQuestions = [...addAnswerOptions(defaulQFiltered), ...projectQ];
        let orderedQuestions = orderAnswerOptions(combinedQuestions);
        storageProvider.setQuestions(orderedQuestions);
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };

    if (!dependancies.isBusy) {
      setIsBusy(true);
      // if (!dependancies.storageProvider.questions || dependancies.storageProvider.questions.length === 0) {
      fetchData(dependancies.clientWebService, dependancies.storageProvider);
      // }
      setIsBusy(false);
    }

    return () => {};
  }, [dependancies]);

  const addIsModifiedTrue = useMemo(() => (array) => array.map((obj) => ({ ...obj, isModified: true })), []);

  const addAnswerOptions = useMemo(() => (array) => array.map((obj) => ({ ...obj, answerOptions: [] })), []);

  const deleteDuplicates = useMemo(
    () => (defaultQ, projectQ) => {
      const questionGlobalIds = projectQ.map((obj) => obj.questionGlobalId);
      return defaultQ.filter((obj) => !questionGlobalIds.includes(obj.globalId));
    },
    []
  );

  const saveAnswers = async (answer, index, globalId, projectQuestionIdentifier, data, type) => {
    const postData = await clientWebService.postAnswers(answer, index, globalId, projectGlobalId);
    if (postData) {
      storageProvider.setQuestions(updateObject(projectQuestionIdentifier, postData, true, data, null, type));
    }
  };

  const updateObject = (projectQuestionIdentifier, answer, isModified, data, deleteAnswer, type) => {
    if (type === "custom") {
      if (!storageProvider.questions.find((x) => x.globalId === data.globalId) && !answer) {
        return storageProvider.questions.push({ ...data, isModified: isModified, defaultGlobalId: data.questionGlobalId });
      } else {
        let questionExists = storageProvider.questions.some((obj) => obj.globalId === data.globalId);

        if (questionExists) {
          storageProvider.questions = storageProvider.questions.map((obj) => {
            if (obj.globalId === data.globalId) {
              obj.answerOptions = obj.answerOptions || [];
              obj.answerOptions.push(answer);
            }
            return obj;
          });
        } else {
          let newQuestion = {
            ...data,
            isModified: isModified,
            defaultGlobalId: data.questionGlobalId,
            answerOptions: [answer],
          };

          return orderAnswerOptions([...storageProvider.questions, newQuestion]);
        }
      }
    }

    return orderAnswerOptions(
      storageProvider.questions.map((obj) => {
        if (obj.defaultGlobalId === projectQuestionIdentifier) {
          if (deleteAnswer) {
            const modAnswers = obj.answerOptions.filter((x) => x.globalId !== deleteAnswer);
            return { ...obj, answerOptions: modAnswers };
          }
          if (answer) {
            if (obj.answerOptions.length > 0) {
              const existingAnswer = obj.answerOptions.find((x) => x.globalId === answer.globalId);

              if (existingAnswer) existingAnswer.metadata = answer.metadata;
              else obj.answerOptions.push(answer);
            } else obj.answerOptions.push(answer);
            return { ...obj, isModified: isModified, projectGlobalId: data.projectGlobalId, globalId: data.globalId };
          } else if (data) return { ...obj, isModified: isModified, answerOptions: [], projectGlobalId: data.projectGlobalId, globalId: data.globalId, defaultGlobalId: data.questionGlobalId };
          else return { ...obj, isModified: isModified, answerOptions: [] };
        } else if (obj.globalId === projectQuestionIdentifier) {
          if (answer) {
            const existingAnswer = obj.answerOptions.find((x) => x.globalId === answer.globalId);
            if (existingAnswer) existingAnswer.metadata = answer.metadata;
          }
        }

        return obj;
      })
    );
  };

  async function saveDefaultQuestion(projectQuestionIdentifier, answers) {
    if (!isBusy) {
      setIsBusy(true);
      const saveData = await clientWebService.postDefaultQuestion(projectQuestionIdentifier.replace(/-/g, ""), projectGlobalId);
      setIsBusy(false);
      if (saveData) {
        if (!answers.length) {
          storageProvider.setQuestions(updateObject(saveData.questionGlobalId, false, true, saveData));
        } else {
          await Promise.all(
            answers.map(async (answer, index) => {
              saveAnswers(answer, index, saveData.globalId, saveData.questionGlobalId, saveData);
            })
          );
        }
        setMessage("SUCCESS_ADDED_QUESTION");
        setTimeout(() => {
          setMessage(false);
        }, "5000");
      }
    }
  }

  async function saveCustomQuestion(question, answers) {
    if (isBusy) return;

    setIsBusy(true);

    try {
      const saveData = await clientWebService.postCustomQuestion(question.metadata, projectGlobalId);
      if (saveData) {
        const updateParams = [saveData.questionGlobalId, false, true, saveData, null, "custom"];

        updateObject(...updateParams);
        if (answers.length > 0) await Promise.all(answers.map((answer, index) => saveAnswers(answer, index, saveData.globalId, saveData.questionGlobalId, saveData, "custom")));

        displayMessage("SUCCESS_ADDED_QUESTION");
      }
    } catch (error) {
      console.error("Error saving custom question:", error);
    } finally {
      setIsBusy(false);
    }
  }

  function displayMessage(message) {
    setMessage(message);
    setTimeout(() => setMessage(false), 5000);
  }
  async function addedNewValues(globalId, answers, defaultGlobalId) {
    const onlyNew = answers.filter((answer) => !answer.created);
    if (!isBusy) {
      await Promise.all(
        onlyNew.map(async (answer, index) => {
          saveAnswers(answer, index, globalId, defaultGlobalId, null);
        })
      );
    }
    setMessage("SUCCESS_ADDED_ANSWERS");
    setTimeout(() => {
      setMessage(false);
    }, "5000");
  }

  async function changeAnswers(answers) {
    if (!isBusy) {
      await Promise.all(
        answers.map(async (answer) => {
          const postData = await clientWebService.editAnswers({ metadata: answer }, projectGlobalId, answer.projectQuestionGlobalId, answer.globalId);
          if (postData) {
            storageProvider.setQuestions(updateObject(answer.projectQuestionGlobalId, postData, true));
          }
        })
      );
    }
  }

  async function deleteQuestion(projectQuestionIdentifier, defaultGlobalId) {
    const result = await clientWebService.deleteQ(projectQuestionIdentifier.replace(/-/g, ""), projectGlobalId);

    if (result) {
      storageProvider.setQuestions(
        storageProvider.questions
          .map((question) => {
            if (question.defaultGlobalId === defaultGlobalId) {
              if (question.isModified && question.isDefault) {
                return { ...question, isModified: false };
              } else {
                return null;
              }
            }
            return question;
          })
          .filter((question) => question !== null)
      );

      setMessage("SUCCESS_DELETED_QUESTION");
      setTimeout(() => {
        setMessage(false);
      }, "5000");
    }

    modalProvider.hideModal();
  }
  async function deleteAnswer(answer) {
    const result = await clientWebService.deleteAnswer(answer, projectGlobalId);
    if (result) {
      const defaultGlobalId = storageProvider.questions.find((x) => x.globalId === answer.projectQuestionGlobalId).defaultGlobalId;

      storageProvider.setQuestions(updateObject(defaultGlobalId, false, false, null, answer.globalId));
    }
  }
  async function confirmPQ() {
    storageProvider.setQuestions(storageProvider.questions);
    navigate("/bloodpressure");
  }

  async function finish() {
    const steps = storageProvider.project.configurationSteps.data;
    if (validateDate(steps.find((x) => x.name === "Extra vragen" || x.name === "Extra questions").created)) {
      navigate(nextStep());
      return;
    }

    const response = await clientWebService.finishStep(project.globalId, project.configurationSteps.data.find((x) => x.name === "Extra vragen" || x.name === "Extra questions").globalId);
    if (response.globalId) {
      const index = steps.findIndex((step) => step.globalId === response.globalId);
      if (index !== -1) {
        steps[index] = response;
      }

      storageProvider.project.configurationSteps.data = steps;
      storageProvider.setProject(storageProvider.project);
      navigate(nextStep());
      setIsBusy(false);
      return;
    }
  }

  return {
    viewModel: {
      questions: storageProvider.questions,
      error: error,
      isBusy: isBusy,
      confirmPQ: confirmPQ,
      saveDefaultQuestion: saveDefaultQuestion,
      saveCustomQuestion: saveCustomQuestion,
      deleteDuplicates: deleteDuplicates,
      addedNewValues: addedNewValues,
      deleteQuestion: deleteQuestion,
      changeAnswers: changeAnswers,
      deleteAnswer: deleteAnswer,
      message: message,
      finish: finish,
      nextStep: nextStep,
    },
  };
};

export default ProjectQuestionsViewModel;
