import React, { useState, useContext } from "react";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";

import Input from "./Input";
import PasswordValidation from "./PasswordValidation/PasswordValidation";

const Password = ({ username, password, valid, onValidationChange, onChange, errorMessage: err, passwordOld, notEqual }) => {
  const { configurationProvider } = useContext(ConfigurationContext);
  const [state, setState] = useState({
    checkPassword: false,
  });
  return (
    <div>
      {" "}
      <Input
        onBlur={() => setState({ ...state, showPasswordValidation: false })}
        onFocus={() => setState({ ...state, showPasswordValidation: true })}
        required
        name={"password"}
        type="password"
        onChange={onChange}
        placeholder={"FILL_PASSWORD_PLACEHOLDER"}
        error={!valid}
        password
      />{" "}
      {(state.showPasswordValidation || !valid) && (
        <PasswordValidation onChange={onValidationChange} username={username} password={password} passwordOld={passwordOld} closeIcon={configurationProvider.cdn + "client/close.svg"} />
      )}
      <Input onChange={onChange} error={notEqual ? "PASSWORD_MISMATCH" : false} required name="passwordCheck" type="password" key="input" placeholder={"REPEAT_PASSWORD_PLACEHOLDER"} />
    </div>
  );
};

export default Password;
