import React from "react";
import Error from "../../components/containers/Error";
import Page from "../../components/containers/Page";
import FlexBox from "../../components/containers/FlexBox";
import RegisterViewModel from "./RegisterUser.VM";
import { RegisterUserForm } from "./RegisterUser.Components";
import Intro from "../../components/intro/Intro";
import Text from "../../components/text/Text";
import Input from "../../components/inputs/Input";
import Button from "../../components/buttons/Button";
import Password from "../../components/inputs/password";

const RegisterUser = () => {
  const { viewModel } = RegisterViewModel();

  return (
    <Page>
      <FlexBox gap={"0px"}>
        <Intro title="REGISTER_TITLE" text="REGISTER_TEXT" />

        <RegisterUserForm
          onSubmit={async (event) => {
            event.preventDefault();
            viewModel.sendPasswords();
          }}
        >
          <h1>
            <Text value="MAKE_ACCOUNT_TITLE" />
          </h1>
          <Input value={viewModel.username} type="email" readOnly />
          <Password
            errorMessage={viewModel.error}
            password={viewModel.state.password}
            passwordCheck={viewModel.state.passwordCheck}
            onChange={(e) => {
              if (!viewModel.isBusy) viewModel.setState({ ...viewModel.state, [e.target.name]: e.target.value });
              viewModel.onEqual(false);
            }}
            onValidationChange={(e) => viewModel.onValidationChange(e)}
            valid={viewModel.valid}
            notEqual={viewModel.notEqual}
          />
          <Error value={viewModel.pageError} />
          <Error value={viewModel.error} />
          {/* <div className="checkboxes">
            <Checkbox
              label="NEWSLETTER"
              error={viewModel.newsletter}
              onChange={() => {
                if (!viewModel.isBusy) viewModel.setNewsletter(!viewModel.newsletter);
              }}
            />
            <Checkbox
              label="PRIVACY"
              link="PRIVACY_LINK"
              value={viewModel.privacy}
              error={viewModel.privacyError}
              onChange={() => {
                if (!viewModel.isBusy) viewModel.setPrivacy(!viewModel.privacy);
                viewModel.setPrivacyError(false);
              }}
            />
          </div> 
          <ErrorValidation value={viewModel.privacyError} />*/}
          <Button width="45%">
            <Text value="NEXT" />
          </Button>{" "}
          {/* <Back color="white" name="GO_TO_LOGIN" onClick={() => navigate("/login")} /> */}
        </RegisterUserForm>
      </FlexBox>
    </Page>
  );
};

export default RegisterUser;
