import React, { useContext, useEffect, useState } from "react";
import Button from "../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import Text from "../../components/text/Text";
import Page from "../../components/containers/Page";
import FlexBox from "../../components/containers/FlexBox";
import Input from "../../components/inputs/Input";
import ForgotPasswordViewModel from "./ForgotPassword.VM";
import { ForgotPasswordForm } from "./ForgotPassword.Components";
import Intro from "../../components/intro/Intro";
import { Back } from "../../components/back/back";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";

import { SuccessMessage } from "../../components/messages/messages";

const ForgotPassword = () => {
  const { viewModel } = ForgotPasswordViewModel();

  const { configurationProvider } = useContext(ConfigurationContext);
  let navigate = useNavigate();
  const [time, setTime] = useState("");

  useEffect(() => {
    if (viewModel.timer) {
      setTime(30);
    } else setTime(0);
  }, [viewModel.timer]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (time > 0) {
        setTime(time - 1);
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <Page>
      <FlexBox gap={"0px"}>
        <Intro title="WELCOME_CLIENT_PORTAL_TITLE" text="WELCOME_CLIENT_PORTAL_TEXT" subText={["BENEFIT_1", "BENEFIT_2", "BENEFIT_3"]} />
        <ForgotPasswordForm
          onSubmit={
            !viewModel.timer
              ? (event) => {
                  event.preventDefault();
                  viewModel.forgotPassword();
                }
              : (event) => {
                  event.preventDefault();
                }
          }
        >
          <h1>
            <Text value="FORGOT_PASSWORD_TITLE" />
          </h1>

          <div>
            <Text value="FORGOT_PASSWORD_TEXT" />
            <Input
              className="margintop15"
              placeholder={"EMAIL"}
              value={viewModel.email}
              type="email"
              onChange={(e) => {
                if (!viewModel.isBusy) viewModel.setEmail(e.target.value);
                viewModel.setEmailError("");
              }}
              error={viewModel.emailError === "emailError" ? "MANDATORY_INPUT" : viewModel.emailError}
            />
            {viewModel.timer ? (
              <Button width="70%" styleType="notActive">
                <div className="timer">
                  <Text value="EMAIL_SENT" />
                  <div>
                    <img src={configurationProvider.cdn + "client/timer.svg"} alt="timer" /> {time} <span className="sec">sec </span>
                  </div>
                </div>
              </Button>
            ) : (
              <Button width="44%">
                <Text value="SEND_EMAIL" />
              </Button>
            )}{" "}
            {viewModel.success && <SuccessMessage name="FORGOT_PASSWORD_SUCCESS_TEXT" />}
          </div>

          <Back color="white" name="BACK" onClick={() => navigate("/login")} />
        </ForgotPasswordForm>
      </FlexBox>
    </Page>
  );
};

export default ForgotPassword;
