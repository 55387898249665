import React, { useContext } from "react";
import Text from "../../components/text/Text";
import styled from "styled-components";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";

export const StyledIcon = styled.div`
  margin: auto 10px auto 0px;
  svg {
    display: block;
  }
`;

const IntroText = styled.div`
  padding: 40px;
  min-height: ${(props) => (props.minHeight ? props.minHeight : "250px")};
  line-height: 18px;
  h1 {
    line-height: 115%;
  }
  span:first-child {
    color: var(--color-primary);
  }
`;
const Introduction = styled.div`
  height: 100%;
  min-height: 650px;
  color: var(--color-black);
  box-shadow: 0 0 30px var(--color-shadow);
  display: flex;
  flex-direction: column;
  mix-blend-mode: normal;
  width: 50%;
  justify-content: space-between;
  @media (max-width: 820px) {
    width: 100%;
  }
  h2 {
    color: var(--color-primary);
  }
  .phone {
    margin: 0 40px;
    cursor: pointer;
  }
  img:last-child {
    width: 100%;
  }
  .adv {
    display: flex;
    align-items: flex-end;
  }
  .benefit {
    margin: 10px 0;
  }
  marginTop10 {
    margin-top: 10px;
  }
  .grey {
    color: var(--color-black);
  }
`;

export const getStyledIcon = (name) => {
  switch (name) {
    case "mark":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12.493" viewBox="0 0 17.848 12.493">
          <g id="noun-checkmark-56938-E7336F" transform="translate(-153.601 -184.32)">
            <path
              id="Path_31"
              data-name="Path 31"
              d="M215.934,196.813a.892.892,0,0,1-.631-1.523l10.709-10.709a.892.892,0,0,1,1.262,1.262l-10.709,10.709a.89.89,0,0,1-.631.261Z"
              transform="translate(-56.087)"
              fill="#e6336f"
            />
            <path
              id="Path_32"
              data-name="Path 32"
              d="M159.847,252.894a.889.889,0,0,1-.631-.261l-5.354-5.354a.892.892,0,1,1,1.262-1.262l5.354,5.354a.893.893,0,0,1-.631,1.524Z"
              transform="translate(0 -56.081)"
              fill="#e6336f"
            />
          </g>
        </svg>
      );
    case "phone":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16.411" viewBox="0 0 16.411 16.411">
          <path
            id="phone"
            d="M12.89,10.512l3.018,1.677a.992.992,0,0,1,.452,1.216,4.493,4.493,0,0,1-5.785,2.742A16.907,16.907,0,0,1,.289,5.861,4.493,4.493,0,0,1,3.031.076.993.993,0,0,1,4.247.528L5.924,3.546a1.23,1.23,0,0,1-.158,1.435L4.356,6.566A10.339,10.339,0,0,0,9.87,12.08l1.585-1.409a1.23,1.23,0,0,1,1.435-.158Z"
            transform="translate(-0.011 -0.013)"
            fill="#e7336f"
            fillRule="evenodd"
          />
        </svg>
      );
    default:
      return "";
  }
};

const Intro = ({ title, text, subText, minHeight }) => {
  const { configurationProvider } = useContext(ConfigurationContext);

  return (
    <Introduction>
      <IntroText minHeight={minHeight}>
        <h1>
          <Text value={title} />
        </h1>

        <Text value={text} />
        {subText && subText.length > 0 ? (
          React.Children.toArray(
            subText.map((x, i) => (
              <div className="flex benefit">
                <StyledIcon> {getStyledIcon("mark")} </StyledIcon>
                <Text value={x} />
              </div>
            ))
          )
        ) : (
          <Text value={subText} />
        )}
      </IntroText>
      <div>
        <div className="phone marginTop10">
          <h2>
            <Text value="CONTACT_TELEPHONE_QUESTION" />
          </h2>
          <div className="grey">
            <Text value="CONTACT_TELEPHONE_QUESTION_TEXT" />
          </div>
          <a href={"tel:0202610444"}>
            <div className="flex">
              <StyledIcon> {getStyledIcon("phone")} </StyledIcon>
              <h2>
                <Text value="PHONE" />
              </h2>
            </div>
          </a>
        </div>
        <div className="adv">
          <img src={configurationProvider.cdn + "client/adviseurs.png"} alt="advs" />
        </div>
      </div>
    </Introduction>
  );
};

export default Intro;
