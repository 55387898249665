import { useContext } from "react";

import { useNavigate } from "react-router-dom";

import { AppContext } from "../../global/App/AppState";

const DashboardViewModel = () => {
  const navigate = useNavigate();

  const { appProvider } = useContext(AppContext);

  function onNavigate(page) {
    navigate(`/${page}`);
  }

  return {
    viewModel: {
      onNavigate: onNavigate,
      sections: appProvider.sections,
    },
  };
};

export default DashboardViewModel;
