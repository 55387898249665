import React, {useState} from "react";
import styled from "styled-components";

export const AppContext = React.createContext();

export const AppProvider = ({children}) => {

    var parameters = {};

    const [loadCount, setLoadCount] = useState(0);
    const [textSize, setTextSize] = useState(1);
    const [globalError, setGlobalError] = useState(null);
    const [sections] = useState([
      {
        route: "data",
        roles: ["Client"],
        title: "DATA_TITLE",
        help: "DATA_HELP",
        index: 0,
      },
      {
        route: "Productivity",
        roles: ["Client"],
        title: "PRODUCTIVITY_TITLE",
        help: "PRODUCTIVITY_HELP",
        index: 1,
      },
      {
        route: "Business",
        roles: ["Client"],
        title: "BUSINESS_TITLE",
        help: "BUSINESS_HELP",
        index: 2,
      },
      {
        route: "Significant",
        roles: ["Client", "Servicedesk"],
        title: "SIGNIFICANT_TITLE",
        help: "SIGNIFICANT_HELP",
        index: 3,
      },
      {
        route: "AandeSlag",
        roles: ["Client", "Servicedesk"],
        title: "ACTION_TITLE",
        help: "ACTION_HELP",
        index: 4,
      },
      {
        route: "Enquetes",
        roles: ["Client", "Servicedesk"],
        title: "ENQUETES_TITLE",
        help: "ENQUETES_HELP",
        index: 5,
      },
      {
        route: "Offers",
        roles: ["Client", "Servicedesk"],
        title: "OFFERS_TITLE",
        help: "OFFERS_HELP",
        index: 6,
      },
    ]); 

    const provider = {
        appProvider: {
            globalError,
            textSize,
            setGlobalError,
            addLoader,
            removeLoader,
            clearLoader,
            setTextSize,
            setParameter,
            getParameter,
            clearParameters,
            sections
        }
    };

    function addLoader() {
        setLoadCount(loadCount + 1);
    }

    function removeLoader() {
        if(loadCount <= 1)setLoadCount(0);
        else setLoadCount(loadCount - 1);
    }

    function clearLoader() {
        setLoadCount(0);
    }

    // Clears all query parameters.
    function clearParameters() {
        parameters = {};
    }

    // Adds a new query parameter.
    function setParameter(key, value) {
        key = key.toLowerCase();
        // Only allow expected parameters!
        switch(key) {
            case "rfid":
            case "cc":
            break;
            default: 
                console.warn("Unsupported query parameter found: [" + key + "]");
                return;
        }
        parameters[key] = value;
    }

    function getParameter(key, clear = false) {
        var value = parameters[key];
        if(clear) setParameter(key, null);
        return value;
    }

    return (
        <AppContext.Provider value={provider}>
            {(loadCount <= 0) ? '' : 
                <LoadingScreen>
                    <div className="loader"></div>
                </LoadingScreen>
            }
            {children}
        </AppContext.Provider>
    )
};

const LoadingScreen = styled.div`
    background: var(--color-overlay); 
    width:      100%;
    height:     100%; 
    z-index:    10;
    top:        0; 
    left:       0; 
    position:   fixed; 
    align-items: center;
    justify-content: center;
    display: flex;

`;