import React, {useContext} from 'react';
import styled from 'styled-components';
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";

import { LocalizationContext } from "../../global/Localization/LocalizationState";
import Text from "../text/Text";

const LanguageToggleButton = () => {
  const { localizationProvider } = useContext(LocalizationContext);

  const { configurationProvider } = useContext(ConfigurationContext);
  return (
    <Container
      onClick={() => {
        localizationProvider.toggleLocalization();
      }}
    >
      <img src={configurationProvider.cdn + "icons/globe8.svg"} alt={localizationProvider.nextLocale} />
      <Text value={localizationProvider.nextLocale.toUpperCase()} />
    </Container>
  );
};
export default LanguageToggleButton;

const Container = styled.div`
  img {
    height: 25px;
    width: 25px;
    cursor: pointer;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 5px;
  }

  color: var(--color-pink);
  font-weight: bold;
  cursor: pointer;
`;