import React, { useState, useContext } from "react";
import Text from "../../components/text/Text";
import { FullPage } from "../../components/containers/Page";
import InviteesViewModel from "./Invitees.VM";
import { DeleteImg, DownloadSection, InviteesForm, OverviewEmployees, StyledBlock, UploadSection, DeleteSelectedButton } from "./Invitees.Components";

import Checkbox from "../../components/inputs/Checkbox";
import { validEmail } from "../../utils/inviteesUtils";
import AddEmployee from "./addemployee";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";
import { SvgIcon } from "../../components/svgIcon/svgIcon";
import { DataGrid, nlNL } from "@mui/x-data-grid";
import { Box, ThemeProvider } from "@mui/material";
import { LocalizationContext } from "../../global/Localization/LocalizationState";
import { fontStyle } from "../../utils/common";
import ProgressStepBar from "../../components/progress/ProgressStepBar";
import Button, { ButtonGroup } from "../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import Error from "../../components/containers/Error";
import { StyledSuccess } from "../../components/messages/messages";

const Invitees = () => {
  const { viewModel } = InviteesViewModel();

  const [error, onError] = useState(false);

  const { configurationProvider } = useContext(ConfigurationContext);

  const { localizationProvider } = useContext(LocalizationContext);

  const [selectedRows, setRowSelectionModel] = useState([]);

  const navigate = useNavigate();

  const DeleteSelected = (props) => {
    return (
      <DeleteSelectedButton onClick={props.onClick}>
        <Text value="DELETE_SELECTED" />
      </DeleteSelectedButton>
    );
  };
  const deleteValues = (selectedRows) => {
    setRowSelectionModel([]);
    viewModel.deleteInvitees(selectedRows);
  };

  const columns = [
    { field: "firstname" || "firstName", headerName: localizationProvider.getLocalization("FIRST_NAME"), flex: 0.2, minWidth: 150, headerClassName: "headerName", editable: true },
    {
      field: "email",
      headerName: localizationProvider.getLocalization("EMAIL"),
      flex: 0.2,
      minWidth: 200,
      headerClassName: "headerName",
      editable: true,
    },
    {
      key: "action",
      sortable: false,

      renderCell: (params) => {
        return (
          selectedRows.length < 1 && (
            <DeleteImg src={configurationProvider.cdn + "icons/trash.svg"} onClick={() => viewModel.deleteInvitees([params.row.globalId ? params.row.globalId : params.row.email])}></DeleteImg>
          )
        );
      },
    },
  ];
  return (
    <FullPage width="true" parentMargin="0 -10%">
      <InviteesForm
        onSubmit={async (event) => {
          event.preventDefault();
          viewModel.postInvitees();
        }}
      >
        <StyledBlock className="fullsize">
          <div className="form marginTop">
            <ProgressStepBar currentStep={2}></ProgressStepBar>
          </div>{" "}
          <div className="form ">
            <h1>
              <Text value="ADD_EMPLOYEES_TITLE" />
            </h1>
            {/* <div className="flex spaceBetween">
            {" "}
            <Back margin name="BACK" />
            <Back reverse margin name="NEXT_PROJECT" />
          </div> */}
            <DownloadSection>
              <h1 className="margin20">
                <Text value="DOWNLOAD_TEMPLATE_TITLE" />
              </h1>
              <div className="margin20">
                {" "}
                <Text value="DOWNLOAD_TEMPLATE_TEXT" />
              </div>

              <a target="_blank" rel="noopener noreferrer" href={configurationProvider.templates + "cp_template.xlsx"}>
                <div className="download_button" width="35%" download>
                  {" "}
                  <Text value="DOWNLOAD_EXCEL" />
                  <SvgIcon>
                    <svg xmlns="http://www.w3.org/2000/svg" className="download" width="21.673" height="18.916" viewBox="0 0 21.673 18.916">
                      <g id="noun-upload-1016073-FFFFFF" transform="translate(-28.794 -102.002)">
                        <path
                          id="Path_193"
                          data-name="Path 193"
                          d="M49.146,692.4a1.323,1.323,0,0,0-1.322,1.322v3.76H31.438v-3.76a1.322,1.322,0,1,0-2.644,0v5.059a1.323,1.323,0,0,0,1.322,1.322h19.03a1.323,1.323,0,0,0,1.322-1.322v-5.059a1.323,1.323,0,0,0-1.322-1.322Z"
                          transform="translate(0 -579.185)"
                          fill="#fff"
                        />
                        <path
                          id="Path_194"
                          data-name="Path 194"
                          d="M328.321,108.178l1.664-1.664v8.341a1.322,1.322,0,1,0,2.644,0v-8.387l1.709,1.709a1.321,1.321,0,1,0,1.869-1.869l-3.92-3.92a1.321,1.321,0,0,0-1.869,0l-3.92,3.92a1.307,1.307,0,0,0,0,1.869,1.253,1.253,0,0,0,1.823,0Z"
                          transform="translate(-291.664)"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                  </SvgIcon>
                </div>
              </a>
            </DownloadSection>
            <UploadSection>
              <h1 className="margin20">
                <Text value="UPLOAD_TEMPLATE_TITLE" />
              </h1>
              <div className="margin20">
                <Text value="UPLOAD_TEMPLATE_TEXT" />
              </div>

              <div className="marginTop">
                <AddEmployee
                  onError={onError}
                  onAdd={viewModel.addEmployee}
                  icon={configurationProvider.cdn + "client/upload.svg"}
                  error={error ? error : viewModel.error}
                  success={viewModel.success}
                />
              </div>
            </UploadSection>
            <StyledSuccess value={viewModel.success} />
          </div>
        </StyledBlock>
        <div className="form marginTop emplOverview">
          {/* {viewModel.data.filter((x) => x.email).length > 0 && ( */}
          <OverviewEmployees>
            <h1 className="margin20">
              <Text value="EMPLOYEES_OVERVIEW_TITLE" />
            </h1>
            <div className="margin20">
              <Text value="EMPLOYEES_OVERVIEW_TEXT" />
            </div>
            <div className="margin20">
              {(viewModel.showFilter || viewModel.onlyShowIncorrect) && (
                <Checkbox color={"pink"} value={viewModel.onlyShowIncorrect} checked={viewModel.onlyShowIncorrect} onChange={viewModel.toggleShowIncorrect} label="ONLY_SHOW_INCORRECT" />
              )}{" "}
            </div>

            {/* <EmployeeGrid cdn={configurationProvider.cdn} filter={viewModel.onlyShowIncorrect} onRemove={viewModel.onRemove} onUpdate={viewModel.onUpdate} data={viewModel.data} /> */}
            <ThemeProvider theme={fontStyle}>
              <Box
                sx={{
                  "& .wrongRow": {
                    background: "#ffc2c240",
                  },
                  "& .wrong": {
                    color: "red",
                  },
                  "& .successIcon": {
                    color: "green",
                  },
                  "& .headerName": {
                    fontSize: "16px",
                    fontFamily: "Bold",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    minHeight: "100px",
                  },
                }}
              >
                <DataGrid
                  checkboxSelection
                  disableRowSelectionOnClick
                  isCellEditable={(params) => params.row.add}
                  localeText={localizationProvider.locale === "nl" ? nlNL.components.MuiDataGrid.defaultProps.localeText : {}}
                  rows={viewModel.data}
                  columns={columns}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  // isRowSelectable={(params) => validEmail(params.row.email)}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10, 20, 50, 100]}
                  // checkboxSelection
                  getRowClassName={(params) => {
                    if (params.row.firstname && params.row.email) {
                      if (!validEmail(params.row.email)) {
                        return "wrongRow";
                      }
                    }
                  }}
                  getCellClassName={(params) => {
                    if (params.field === "email") {
                      return validEmail(params.value) ? "" : "wrong";
                    }
                  }}
                  getRowId={(row) => (row.globalId ? row.globalId : row.email)}
                />
                {selectedRows.length > 0 && <DeleteSelected onClick={() => deleteValues(selectedRows)} />}
                <Error value={viewModel.pageError} />
                <Error value={viewModel.error} />
              </Box>
            </ThemeProvider>
          </OverviewEmployees>
          {/* )} */}
        </div>
        <div className="form marginTop emplOverview">
          <div className="invitees_buttons">
            {viewModel.data.filter((x) => x.email).length > 0 && <Text value="INVITEES_AUTOMATICALLY_SAVED" />}
            <ButtonGroup spaceBetween>
              <Button
                styleType={5}
                type="button"
                width="48%"
                onClick={() => {
                  navigate(viewModel.nextStep());
                }}
              >
                <Text value="LATER_CONTINUE" />
              </Button>
              <Button
                width="48%"
                onClick={async (event) => {
                  event.preventDefault();
                  viewModel.finish();
                }}
              >
                <Text value="NEXT" />
              </Button>
            </ButtonGroup>
          </div>
        </div>
        {/* <Checkbox label={"SKIP_ADD_EMPLOYEES"} onChange={() => setState({ ...state, skipped: !state.skipped })} />
        {!state.skipped && (
          <div>
            <AddEmployee showFilter={showFilter} onFilterToggle={toggleShowIncorrect} filter={state.onlyShowIncorrect} onError={onError} onAdd={addEmployee} />
            <EmployeeGrid filter={state.onlyShowIncorrect} onRemove={viewModel.onRemove} onUpdate={viewModel.onUpdate} data={data} />{" "}
          </div>
        )}
        <ButtonGroup>
          <Button width="48%" marginRight>
            <Text value="FINISH" />
          </Button>
          <Button width="48%" styleType={Secondary}>
            <Text value="GO_TO_REPORT" />
          </Button>
          
        </ButtonGroup> */}
      </InviteesForm>
    </FullPage>
  );
};

export default Invitees;
