export async function doApiCall (appProvider, isBusy, setIsBusy, setError, action) {
    if(isBusy) return;
    else setIsBusy(true); 

    setError(null);

    appProvider.addLoader();
    try {
        await action()
    } catch (e) {
        appProvider.setGlobalError(e);
    }
    finally {
        appProvider.removeLoader();
        setIsBusy(false); 
    }
}

function formatError (statusCode, data) {
    try {
        if(data.ErrorCodes) 
            return "ERROR_" + data.ErrorCodes;

        return "ERROR_" + statusCode.toString().padEnd(6, '0');
    }
    catch {
        return "ERROR_" + statusCode.toString().padEnd(6, '0');
    }
}

export async function handleResponse(result, setError) {
  if (!result) {
    console.error(`The result was returned by the endpoint.`);
    setError("ERROR_UNAVAILABLE");
    return false;
  }
  switch (result.status) {
    case 200: // 200 OK : The request succeeded.
    case 201: // 201 Created : The request succeeded, and a new resource was created as a result. Usually happens with a post.
    case 202: // 202 Accepted : The request has been received but not yet acted upon.
    case 204: // 204 No Content : There is no content to send for this request. Usually happens with a delete.
      break;
    case 400: // 400 Bad Request : Input Validation failed.
      setError(formatError(result.status, result.data));
      break;
    case 401: // 401 Unauthorized : User should login first.
      setError(formatError(result.status, result.data));
      break;
    case 403: // 403 Forbidden : User does not have access rights to the content.
      setError(formatError(result.status, result.data));
      break;
    case 404: // 404 Not Found : The server can not find the requested resource.
      setError(formatError(result.status, result.data));
      break;
    case 409: // 409 Conflict : This response is sent when a request conflicts with the current state of the server. Like somthing already exists in the database.
      setError(formatError(result.status, result.data));
      break;
    case 410: // 410 Gone : The requested resource is no longer avaiable. Like when somthing is marked as 'disabled'.
      setError(formatError(result.status, result.data));
      break;
    case 500: // 500 Internal Server Error : The requested resource is no longer avaiable. Like when somthing is marked as 'disabled'.
      setError(formatError(result.status, result.data));
      break;
    case 501: // 501 Not Implemented : The requested resource is no longer avaiable. Like when somthing is marked as 'disabled'.
      setError(formatError(result.status, result.data));
      break;
    default:
      console.error(`The http status code ${result.status} is not implemented`);
      break;
  }

  return result.status >= 200 && result.status < 300;
}