import React, {useState} from "react";

export const StorageContext = React.createContext();

export const StorageProvider = ({ children }) => {
  const [packages, setPackages] = useState(false);
  const [user, setUserDetails] = useState(false);

  const [quotes, setQuotes] = useState(false);

  const [quote, setQuote] = useState(false);
  const [project, setProject] = useState(false);
  const [projects, setProjects] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [shipments, setShipments] = useState([]);

  const provider = {
    storageProvider: {
      packages: packages,
      quotes: quotes,
      quote: quote,
      user: user,
      employees: employees,
      project: project,
      projects: projects,
      questions: questions,
      setProjects: setProjects,
      setPackages: setPackages,
      setQuotes: setQuotes,
      setQuote: setQuote,
      setUserDetails: setUserDetails,
      setProject: setProject,
      setQuestions: setQuestions,
      setEmployees: setEmployees,
      setShipments: setShipments,
      shipments: shipments,
    },
  };

  return <StorageContext.Provider value={provider}>{children}</StorageContext.Provider>;
};