import styled from 'styled-components';

const Title = styled.h1`
  user-select: none;
  color: ${(props) => props.color};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "42px")};
  padding: 10px 0px;
  letter-spacing: 0px;
  line-height: 105%;
  min-height: 40px;
  mix-blend-mode: normal;
  word-wrap: break-word;
  font-weight: bold;
`;

export default Title;