import styled from "styled-components";

export const Child = styled.div`
  background-color: white;
  font-size: 12px;
  color: var(--color-primary);
  ${(props) =>
    props.active &&
    `
  background-color:  var(--color-pink);color:white;`};
  padding: 10px;
  cursor: pointer;
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  display: flex;
  height: 50px;
  :hover {
    color: white;
    background-color: var(--color-pink);
    opacity: 0.7;
  }
`;

export const Overview = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;
export const StatisticBlock = styled.div`
  height: fit-content;
  min-height: 240px;
  top: ${(props) => props.top};
  display: flex;
  width: 100%;
  margin-left: ${(props) => props.marginLeft};
`;
export const Line = styled.div`
  width: ${(props) => (props.i === 0 ? "50%" : "25%")};

  text-align: ${(props) => props.i !== 0 && "center"};
  background-color: ${(props) => (props.color ? props.color : "#0060a8")};
  color: ${(props) => (props.textcolor ? props.textcolor : "white")};
  padding: 20px;
`;
export const Row = styled.div`
  background-color: ${(props) => props.i % 2 === 0 && "var(--color-light-grey)"};
  display: flex;
  width: 100%;
  padding: 15px;

  p:first-child {
    width: 50%;
  }
  p:nth-child(2) {
    width: 25%;
    text-align: center;
  }
  p:nth-child(3) {
    width: 25%;
    text-align: center;
  }
`;
