import { useState } from "react";

import axios from "axios";

import { doApiCall, handleResponse } from "../../../utils/services";

function useIdentityWebService(appProvider, identityBaseUrl, domain, setAccessToken, createHeaders, setError, setSuccessResetPassword, setDataValues) {
  const uri = identityBaseUrl.replace("{domain}", domain);
  const [isBusy, setIsBusy] = useState(false);
  async function getAccounts(token) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.get(`${uri}/accounts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept-Language": "nl",
        },
      });
    });
    if (result.status === 200) {
      setDataValues({ accounts: result.data });
    }
    return null;
  }

  async function postLogin(username, password) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      const params = new URLSearchParams();
      params.append("username", username);
      params.append("password", password);
      params.append("grant_type", "password");

      result = await axios.post(`${uri}/connect/token`, params, {
        headers: {
          Authorization: "Basic UEdDOg==",
        },
        validateStatus: null,
      });
    });

    if (await handleResponse(result, setError)) {
      setAccessToken(result.data.access_token);

      await getAccounts(result.data.access_token);

      return result.data;
    }

    return null;
  }

  async function forgotPassword(email) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.post(
        `${uri}/accounts/password/reset`,
        {
          username: email,
          email: email,
        },
        {
          headers: createHeaders(),
        }
      );
    });
    if (result.status === 202) return "Accepted";
    if (handleResponse(result, setError)) {
      return result.data.data;
    }
    return null;
  }

  async function resetPassword(username, password, token) {
    var result;
    await doApiCall(appProvider, isBusy, setIsBusy, setError, async () => {
      result = await axios.put(
        `${uri}/accounts/password/${token}`,
        {
          username: username,
          password: password,
        },
        {
          headers: createHeaders(),
        }
      );
    });

    if (result && result.status === 200) {
      setSuccessResetPassword(true);
      return result.data;
    }
    if (handleResponse(result, setError)) {
      return result.data.data;
    }
    return null;
  }

  return {
    postLogin,
    forgotPassword,
    resetPassword,
  };
}

export default useIdentityWebService;
