import { useContext, useState, useEffect, useMemo } from "react";
import { WebServiceContext } from "../../global/WebService/WebServiceState";
import { StorageContext } from "../../global/Storage/StorageState";
import { useNavigate } from "react-router-dom";
import { getStepURL, validateDate } from "../../components/progress/ProgressStepBar";
const FinishPageViewModel = () => {
  const { clientWebService, error, info } = useContext(WebServiceContext);
  const navigate = useNavigate();
  const { storageProvider } = useContext(StorageContext);
  const [isBusy, setIsBusy] = useState(false);
  const clientGlobalId = storageProvider.project.clientGlobalId;
  const projectGlobalId = storageProvider.project.globalId;
  const project = storageProvider.project;

  const addIsModifiedTrue = useMemo(() => (array) => array.map((obj) => ({ ...obj, isModified: true })), []);

  useEffect(() => {
    const fetchData = async function (clientWebService, storageProvider) {
      const projectQ = addIsModifiedTrue(await clientWebService.getProjectQuestions(projectGlobalId));
      const shipments = await clientWebService.getShipments(clientGlobalId, projectGlobalId);
      const invitees = await clientWebService.getInvitees(projectGlobalId, clientGlobalId);
      storageProvider.setShipments(shipments);
      storageProvider.setQuestions(projectQ);
      storageProvider.setEmployees(invitees);
    };

    setIsBusy(true);
    fetchData(clientWebService, storageProvider);
    setIsBusy(false);

    return () => {};
  }, [clientGlobalId, projectGlobalId]);

  async function finish(projectGlobalId) {
    const steps = storageProvider.project.configurationSteps.data;
    if (validateDate(steps.find((x) => x.name.includes("Contr")).created)) {
      navigate(nextStep());
      return;
    }

    if (!isBusy) {
      setIsBusy(true);

      const response = await clientWebService.postState(projectGlobalId, 2);

      const result = await clientWebService.finishStep(project.globalId, project.configurationSteps.data.find((x) => x.name.includes("Contr")).globalId);
      if (result.globalId) {
        const index = steps.findIndex((step) => step.globalId === response.globalId);
        if (index !== -1) {
          steps[index] = response;
        }
        storageProvider.project.configurationSteps.data = steps;
        storageProvider.setProject(storageProvider.project);
      }

      if (response.created) {
        navigate("/home");
      }

      setIsBusy(false);
    }
  }

  function nextStep() {
    return "/home";
  }
  return {
    viewModel: {
      error: error,
      isBusy: isBusy,
      user: info.accounts,
      project: storageProvider.project,
      bloodpressure: storageProvider.shipments,
      questions: storageProvider.questions,
      invitees: storageProvider.employees || 0,
      interventions: "",
      finish: finish,
    },
  };
};

export default FinishPageViewModel;
