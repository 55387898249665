import React, { useContext } from "react";
import styled from "styled-components";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";
import Text from "../text/Text";

const ContactContainer = styled.div`
  position: absolute;
  z-index: 2;
  width: 160px;
  height: 400px;
  margin: 60px -200px;
  box-shadow: rgba(0, 0, 0, 0.15) 4px 6px 15px;
  background-color: var(--color-white);

  .card-client {
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    transition: all 0.3s ease;
  }
  div:first-child {
    margin-top: 10px;
  }

  .user-picture {
    overflow: hidden;
    object-fit: cover;
    width: 5rem;
    height: 5rem;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .name-client {
    margin: 0;
    margin-top: 10px;
    color: var(--color-primary);
  }
  h4 {
    font-family: bold;
    margin-top: 0;
    margin-bottom: 0;
  }
  .name-client span {
    display: block;
    font-weight: 100;
  }
  .links {
    text-align: center;
    img {
      margin-right: 5px;
    }
    color: var(--color-pink);
    a {
      cursor: pointer;
    }
    a:link,
    a:active,
    a:visited {
      color: var(--color-pink);
      text-decoration: none;
    }
  }

  @media (max-width: 1204px) {
    visibility: hidden;
    width: 0;
  }
`;

const ContactDetails = ({  }) => {

  const { configurationProvider } = useContext(ConfigurationContext);
  return (
    <ContactContainer>
      <div className="card-client">
        <div className="user-picture">
          <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"></path>
          </svg>
        </div>
        <div className="name-client">
          <h4> Danielle</h4>
          <span>Adviseur</span>
        </div>
      </div>
      <div className="card-client">
        <div className="user-picture">
          <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg">
            <path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"></path>
          </svg>
        </div>
        <div className="name-client">
          <h4> Eva</h4>
          <span>Success manager</span>
        </div>
      </div>
      <div className="links">
        <a href={"mailto:"}>
          <img src={configurationProvider.cdn + "icons/clock.svg"} alt="check" />

          <Text value="DIRECT_MAIL" />
        </a>
        <br />
        <a href={"tel:"}>
          <img src={configurationProvider.cdn + "icons/clock.svg"} alt="check" />

          <Text value="PHONE_NUMBER" />
        </a>
      </div>
    </ContactContainer>
  );
};

export default ContactDetails;
