import React from "react";
// import Page from "../../components/containers/Page";
// import { B, InsideBlock, SectionBadge, StatisticBlock, StyledBlock } from "../home/Home.Components";
// import Title from "../../components/text/Title";
// import Text from "../../components/text/Text";
// import styled from "styled-components";
// import { Block } from "../../components/containers/Block";
// import Accordion from "../../components/accordion/Accordion";
// import Checkbox from "../../components/inputs/Checkbox";
// import { Arrow, StatSection } from "./Macroreport.Components";
// import MacroreportIcon from "../../components/icons/MacroreportIcons";
// import { Barchart, MainBarcharts } from "../../components/chart/barchart";
// import Carousel, { shiftLeft, shiftRight } from "../../components/carousel/carousel";
// import Grid from "../../components/containers/Grid";
// import { GenderChart } from "./GenderChart";
// import { LineChart } from "../../components/chart/linechart";
// import { PieChart } from "../../components/chart/piechart";

// const Legenda = styled.div`
//     width: 60%;
//     margin: 1% auto;
//  .legendaInfo{ box-sizing: border-box;
// }
// .legendaTitle{
//   font-size: 16px;
//   font-weight:bold;
// color:var(--color-black)
// }
//   .column {
//     float: left;
//     width: 33.33%;
//       @media (max-width: 720px) {

//             width: 100%;

//     }
//   }}
//   .icon_text{
//     display:flex;
//     padding: 5px 0;
//     >p{margin-left: 10px;
//       font-size: 13px;
//       font-weight: 200;
//     display: flex;
// align-items: center;
// }

// `;
// const LegendaIcon = styled.div`
//   min-height: 20px;
//   min-width: 20px;
//   ${(props) => (props.color ? `background: ${props.color};` : `background-image: url("data:image/svg+xml,${props.url}");`)}
//   background-repeat: no-repeat;
//   border-radius: ${(props) => props.color && "50%"};
//   display: inline-block;

//   margin: 3px 0;
// `;

const Macroreport = (props) => {
  return <></>;
};
//   const [state, setState] = useState({
//     filters: {
//       year: { 2017: false },
//       gender: { m: false, f: false },
//       region: {
//         Limburg: false,
//         "Noord-Brabant": false,
//         Utrecht: false,
//         Overijssel: false,
//         Drenthe: false,
//         Zeeland: false,
//         Friesland: false,
//         "Zuid-Holland": false,
//         "Noord-Holland": false,
//         Groningen: false,
//         Gelderland: false,
//         Flevoland: false,
//       },
//       ageGroup: {
//         "18-30": false,
//         "31-40": false,
//         "41-50": false,
//         "51-60": false,
//         "61-70": false,
//         "70+": false,
//       },
//       educationLevel: {
//         Low: false,
//         Medium: false,
//         High: false,
//         Other: false,
//       },
//     },
//     groups: {
//       lifestyle: false,
//       mental: false,
//       work: false,
//       meetwaarden: false,
//       ziekteprocessen: false,
//       sleep: false,
//       medewerkers: false,
//       other: false,
//     },
//     // filteredByCategory: this.props.data,
//   });
//   const elements = (
//     <div className="cards__container">
//       {["Bewegen", "b", "c", "d"].map((x, i) => (
//         <div className="box" key={"box" + i}>
//           <div className="barchart">
//             <MainBarcharts icons name={x} data={{ red: 40, orange: 20, green: 100 }} />
//           </div>
//         </div>
//       ))}
//     </div>
//   );
//   const getHead = (props) => {
//     return (
//       <div>
//         <InsideBlock>
//           <SectionBadge badge>
//             <div>
//               <MacroreportIcon index={props.position} color={"var(--color-button-primary)"} />
//             </div>
//             <div>
//               <Text value={props.name}></Text>
//             </div>
//           </SectionBadge>
//         </InsideBlock>
//         <StatisticBlock top="-25px" marginLeft="8em">
//           <div className="whiteStat">
//             <div className="arrows">
//               <Arrow big left onClick={() => shiftRight()}></Arrow>
//               <Arrow big onClick={() => shiftLeft()}></Arrow>
//             </div>
//             <div className="flex customWidth">
//               {/* change for mobile device */}
//               <Carousel elements={elements} />
//             </div>

//             <div className="btnBenchmark" onClick={() => setState({ ...state, ...{ [props.name]: !state[props.name] } })}>
//               Benchmark
//             </div>
//           </div>
//         </StatisticBlock>
//       </div>
//     );
//   };
//   const fillColor = (svg, color) => {
//     if (color) return svg.replace("fill", `fill='${color}'`);
//     return svg;
//   };
//   const getStatistics = (props) => {
//     return (
//       <div>
//         {/* change for mobile device */}
//         <div className="multipleBadges">
//           {Array.from({ length: 8 }).map((x) => (
//             <StatSection
//             // key={d.route}
//             // textSize={appProvider.textSize}
//             // onClick={(r) => {
//             //   viewModel.onNavigate(d.route);
//             // }}
//             >
//               <div>
//                 <div>
//                   <Barchart data={{ orange: 20, green: 100 }}></Barchart>
//                 </div>
//                 <div>
//                   <Barchart benchmark data={{ orange: 20, green: 100 }}></Barchart>
//                 </div>
//               </div>
//               <div>
//                 <Text value={"Ontbijt"}></Text>
//               </div>
//             </StatSection>
//           ))}
//         </div>
//         <StatisticBlock className="overlay">
//           <B minWidth="45%">
//             <h3>
//               <Text value="GENDER" />
//             </h3>
//             <GenderChart m={{ orange: 20, green: 80 }} f={{ orange: 20, green: 80 }} />
//           </B>
//           <B minWidth="45%">
//             {" "}
//             <h3>
//               <Text value="EDUCATION" />
//             </h3>
//             <LineChart
//               data={{
//                 high: { green: 60, orange: 30, red: 10 },
//                 medium: { green: 60, orange: 30, red: 10 },
//                 low: { green: 60, orange: 30, red: 10 },
//                 other: { green: 60, orange: 30, red: 10 },
//               }}
//             />
//           </B>
//         </StatisticBlock>
//         <StatisticBlock>
//           <B width="100%">
//             <h3>
//               <Text value="AGE_GROUP" />
//             </h3>

//             <PieChart
//               data={{
//                 "18-30": { green: 60, orange: 30, red: 10 },
//                 "31-40": { green: 60, orange: 30, red: 10 },
//                 "41-50": { green: 60, orange: 30, red: 10 },
//                 "51-60": { green: 60, orange: 30, red: 10 },
//                 "61-70": { green: 60, orange: 30, red: 10 },
//                 "70+": { green: 60 },
//               }}
//             />
//           </B>
//         </StatisticBlock>
//         <StatisticBlock className="overlay">
//           <B width="100%">
//             {" "}
//             <h3>
//               <Text value="GENDER" />
//             </h3>
//             <Text value="DESCRIPTION" />
//           </B>
//         </StatisticBlock>
//       </div>
//     );
//   };
//   const dropDown = `%3Csvg id='drop_down' data-name='drop down' xmlns='http://www.w3.org/2000/svg' height='18' viewBox='0 0 21.933 21.933'%3E%3Cpath id='drop_down-2' data-name='drop down' d='M106.967,99.68l2.976-2.551a1.044,1.044,0,1,1,1.36,1.586l-3.656,3.133a1.045,1.045,0,0,1-1.36,0l-3.656-3.133a1.044,1.044,0,0,1,1.36-1.586Zm0,10.253a10.967,10.967,0,1,1,10.967-10.967A10.967,10.967,0,0,1,106.967,109.933Zm0-2.089a8.878,8.878,0,1,0-8.878-8.878A8.878,8.878,0,0,0,106.967,107.844Z' transform='translate(-96 -88)' fill='%23e6336f' /%3E%3C/svg%3E`;

//   const information = `%3Csvg id='information' xmlns='http://www.w3.org/2000/svg' height='18' viewBox='0 0 22.175 22.175'%3E%3Cpath id='Path_11' data-name='Path 11' d='M236.319,235.21a1.107,1.107,0,0,0-1.108,1.108v4.435a1.108,1.108,0,1,0,2.216,0v-4.435A1.107,1.107,0,0,0,236.319,235.21Z' transform='translate(-225.233 -225.232)' fill='%23e6336f' /%3E%3Cpath id='Path_12' data-name='Path 12' d='M59.116,48.027A11.088,11.088,0,1,0,70.205,59.113,11.084,11.084,0,0,0,59.116,48.027Zm0,19.956a8.87,8.87,0,1,1,8.87-8.87A8.87,8.87,0,0,1,59.116,67.983Z' transform='translate(-48.03 -48.027)' fill='%23e6336f' /%3E%3Cpath id='Path_13' data-name='Path 13' d='M237.428,153.119a1.108,1.108,0,1,1-1.108-1.108,1.108,1.108,0,0,1,1.108,1.108' transform='translate(-225.234 -146.468)' fill='%23e6336f' /%3E%3C/svg%3E`;
//   const difference = `%3Csvg id='significant_difference' data-name='significant difference' xmlns='http://www.w3.org/2000/svg' height='18' viewBox='0 0 25.155 22.006'%3E%3Cpath id='significant_difference-2' data-name='significant difference' d='M139.58,143.15a2.216,2.216,0,0,0-1.936,1.09q-5.154,8.794-10.335,17.585a2.181,2.181,0,0,0,0,2.23,2.233,2.233,0,0,0,2.053,1.1H149.8a2.231,2.231,0,0,0,2.053-1.1,2.18,2.18,0,0,0,0-2.23c-3.414-5.878-6.941-11.7-10.335-17.585a2.215,2.215,0,0,0-1.936-1.09Zm0,1.886a.449.449,0,0,1,.3.167l10.345,17.575a.392.392,0,0,1,0,.314c.028-.048-.331.177-.432.177H129.362c-.1,0-.46-.225-.432-.177a.392.392,0,0,1,0-.314L139.275,145.2a.449.449,0,0,1,.3-.167Zm0,4.716a.943.943,0,0,0-.943.943v6.916a.943.943,0,0,0,1.886,0V150.7A.943.943,0,0,0,139.58,149.752Zm0,9.746a1.258,1.258,0,1,0,1.258,1.258A1.257,1.257,0,0,0,139.58,159.5Z' transform='translate(-127.003 -143.15)' fill /%3E%3C/svg%3E`;
//   const legenda = [
//     {
//       name: "Gezondheidrisico",
//       values: [
//         { icon: "var(--color-red)", name: "Sterk verhoogd" },
//         { icon: "var(--color-orange)", name: "Verhoogd" },
//         { icon: "var(--color-green)", name: "Laag" },
//       ],
//     },
//     {
//       name: "Verdieping",
//       values: [
//         { url: dropDown, name: "Meer inzicht in dit onderdeel" },
//         { url: information, name: "Meer informatie over deze factor" },
//       ],
//     },
//     {
//       name: "Benchmark vergelijking",
//       values: [
//         { url: difference, color: "%23f33738", name: "Meer hoog gezondheidsrisico dan de benchmark" },
//         { url: difference, color: "%23ff9e01", name: "Meer gezondheidsrisico dan de benchmark" },
//         { url: difference, color: "%238dbf22", name: "Minder gezondheidsrisico dan de benchmark" },
//       ],
//     },
//   ];

//   return (
//     <Page width="95%" minPadding="0% -13% 0px -13%">
//       <StyledBlock className="fullsize">
//         <div className="flex">
//           <Legenda>
//             <Title fontSize="20px" color="var(--color-primary)">
//               <Text value="LEGENDA" />
//             </Title>
//             <div className="legendaInfo">
//               {legenda.map((x, y) => (
//                 <div key={x.name + y} className="column">
//                   <div className="legendaTitle"> {x.name}</div>
//                   {x.values.map((z, s) => (
//                     <div key={x.name + z.name + s} className="icon_text">
//                       <LegendaIcon url={fillColor(z.url, z.color)} color={z.icon} />
//                       <p> {z.name}</p>
//                     </div>
//                   ))}
//                 </div>
//               ))}
//             </div>
//           </Legenda>
//         </div>
//       </StyledBlock>

//       <Block margin="50px">
//         <Grid width="30em">
//           <div className="backgroundGrey">
//             <Text value="FILTERS" />
//             {Object.keys(state.filters).map((a, b) => (
//               <Accordion padding="10px 20px" key={a + b} title={a.toUpperCase()}>
//                 {Object.keys(state.filters[a]).map((x, y) => (
//                   <Checkbox
//                     key={x + y}
//                     onChange={() =>
//                       setState({
//                         ...state,
//                         filters: { ...state.filters, [a]: { ...state.filters[a], [x]: !state.filters[a][x] } },
//                       })
//                     }
//                     checked={state.filters[a][x]}
//                     label={x}
//                   />
//                 ))}
//               </Accordion>
//             ))}
//           </div>
//         </Grid>
//         <Grid margin="20px 40px">
//           {Object.keys(state.groups).map((a, b) => (
//             <StyledBlock key={a + b} close={!state[a]}>
//               {getHead({ name: a, position: b })}

//               {state[a] && getStatistics()}
//             </StyledBlock>
//           ))}
//         </Grid>
//       </Block>
//     </Page>
//   );
// };

export default Macroreport;
