import styled from "styled-components";

import Form from "../../components/containers/Form";

export const WelcomePageForm = styled(Form)`
  .bottomTextBtns {
    margin-top: 20px;
    width: 50%;
    float: right;
    text-align: right;
    font-style: italic;
  }
  .bold {
    font-family: bold;
  }
  .box {
    background: white;
  }
  .back {
    margin: 20px 0;
  }
  h1,
  h3,
  h4 {
    color: var(--color-primary);
  }

  h3 {
    font-size: 20px;
  }

  .form {
    padding: 10px 20%;
  }

  @media (max-width: 1200px) {
    .form {
      padding: 10px 10%;
    }
  }

  .marginTop {
    margin-top: 10px;
  }
  .marginMinTop {
    margin-top: -5%;
  }
  .margin30 {
    margin: 30px 0;
  }
  .add_location_button {
    display: flex;
    .button {
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }
  .flex {
    display: flex;
  }
  .spaceBetween {
    justify-content: space-between;
  }
`;
export const StyledBlock = styled.div`
  background-color: var(--color-back-grey);
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const TwoCol = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    display: block;
  };
  h3{ font-family:bold;}
  .question_title{height: 70px;
  }
  .bottom{vertical-align: bottom;
    display: table-cell;}
    > div {
      :first-child,:last-child
       {
       width:49%;

  @media (max-width: 768px) {width:100%;}
      };
      a{margin:0 5px; color: var(--color-pink); text-decoration:underline;};
     
`;
