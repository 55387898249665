import React, { useState } from "react";
import Form from "../../components/containers/Form";
import Page from "../../components/containers/Page";

import { useNavigate } from "react-router-dom";
import Button from "../../components/buttons/Button";
import Text from "../../components/text/Text";
import { AcceptedPage } from "./accepted.components";
const Accepted = (props) => {
  const navigate = useNavigate();
  return (
    <AcceptedPage>
      <Page width="45%">
        <Form onSubmit={() => navigate("/invitees")}>
          <h1>
            {" "}
            <Text value="WELCOME_DONE" />
          </h1>{" "}
          <img src="" alt="success" />
          <Text value="WELCOME_DESCRIPTION" />
          <Button width="40%">
            <Text value="NEXT" />
          </Button>
        </Form>
      </Page>
    </AcceptedPage>
  );
};

export default Accepted;
