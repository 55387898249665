import styled from "styled-components";

import Form from "../../components/containers/Form";

export const ResetPasswordForm = styled(Form)`
  background-color: var(--color-primary);
  color: var(--color-white);
  margin: 12px 0 12px 0;
  padding: 32px;
  @media (min-width: 768px) {
    width: 50%;
  }

  .button {
    cursor: pointer;
  }
  .margintop15 {
    margin-top: 15px;
  }
  .timer {
    display: flex;
    align-content: space-between;
    img {
    width: 10px;
    margin: 5px;
    }
    span{
      font-family:bold;
    }
    .sec {
      font-size:12px
    }
}
  }
`;
