import { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";

import { WebServiceContext } from "../../global/WebService/WebServiceState";
import { UserContext } from "../../global/User/UserState";
import LoginViewModel from "../login/Login.VM";

const RegisterViewModel = () => {
  const navigate = useNavigate();
  const { viewModel } = LoginViewModel();

  const { clientWebService, error, identityWebService } = useContext(WebServiceContext);

  const { userProvider } = useContext(UserContext);
  const [success, setMessage] = useState("");
  const [username, setUsername] = useState("");
  const [newsletter, setNewsletter] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [pageError, setPageError] = useState("");
  const [state, setState] = useState({ password: "", passwordCheck: "" });
  const [notEqual, onEqual] = useState(false);
  const [valid, onValidationChange] = useState(true);

  function deleteLastSpace(str) {
    return str.replace(/\s+$/, "");
  }
  const validateEmail = (email) => {
    const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return res.test(String(email).toLowerCase());
  };
  async function register() {
    if (!privacy) {
      setPrivacyError("PRIVACY_ERROR");
      return;
    }
    if (state.password !== state.passwordCheck) {
      onEqual(true);
      return;
    } else onEqual(false);
    setUsername(deleteLastSpace(username));
    if (!validateEmail(username)) {
      setUsernameError("NOT_CORRECT_EMAIL_FORMAT");
      return;
    }
    const response = await clientWebService.register(username, state.password, privacy, newsletter);

    if (response.number && response.globalId) {
      const login = await identityWebService.postLogin(username, state.password);
      //getstates. doesnt have started navigate welcome page otherwset

      if (userProvider.initRoles(login)) await viewModel.getProjects(login);
      // else navigate("/home");
    }
    // storageProvider.clear();
    // if (userProvider.initRoles(await identityWebService.postLogin(username, password))) navigate("/home");
    // else setPageError("ERROR_NO_ROLES");
  }

  return {
    viewModel: {
      register: register,
      newsletter: newsletter,
      privacy: privacy,
      setNewsletter: setNewsletter,
      setPrivacy: setPrivacy,
      privacyError: privacyError,
      setPrivacyError: setPrivacyError,
      username: username,
      usernameError: usernameError,
      setUsernameError: setUsernameError,
      setUsername: setUsername,
      success: success,
      onValidationChange: onValidationChange,
      notEqual: notEqual,
      onEqual: onEqual,
      valid: valid,
      state: state,
      setState: setState,
      pageError: pageError,
      setPageError: setPageError,
      error: error,
      isBusy: isBusy,
    },
  };
};

export default RegisterViewModel;
