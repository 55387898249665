import React, { useContext } from "react";
import styled from "styled-components";
import Text from "../text/Text";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";

export const StyledError = styled.span`
  display: block;
  width: 100%;
  padding: 8px;
  color: #9c0006;
  background-color: #ffc7ce;
  text-align: center;
  font-weight: 500;
`;

export const StyledSuccess = styled.div`
  width: 100%;
  color: #8dbe22;
  font-weight: bold;
  padding: 15px 0;
  font-size: 16px;
`;

export const SuccessMessage = ({ name, localizations, locale, children, ...rest }) => {
  if (name) {
    return (
      <StyledSuccess {...rest}>
        <Text value={name} />
      </StyledSuccess>
    );
  }
  return <StyledSuccess {...rest}>{children}</StyledSuccess>;
};

export const ErrorMessage = ({ name, localizations, locale, children, ...rest }) => {
  if (name) {
    return (
      <StyledError {...rest}>
        <Text value={name} />
      </StyledError>
    );
  }
  return <StyledError {...rest}>{children}</StyledError>;
};

const Error = ({ value }) => {
  if (!value) return "";

  return (
    <Container>
      <Text value={value} />
    </Container>
  );
};

export default Error;

const Container = styled.div`
  width: ${(props) => props.width};
  display: flex;
  font-size: 12px;
  flex-direction: row;
  align-items: center;
  position: static;
  margin-bottom: 1em;
  align-items: self-start;
  color: ${(props) => (props.color ? props.color : "var(--color-green)")};
  background-color: #8dbf2261;
  border-radius: 2px;
  padding: 5px;
  height: 30px;
  span {
    font-weight: 400;
    margin: auto 10px;
  }
  img {
    width: 12px;
    font-size: 12px;
    margin: auto 10px;
    background: ${(props) => (props.notValid ? "#ff000012" : "")};
    font-size: 12px;
  }

  border-radius: ${(props) => (props.input ? "var(--corner-radius-small-bottom)" : "var(--corner-radius-small")};
`;

export const SuccessValidation = (props) => {
  const { configurationProvider } = useContext(ConfigurationContext);
  if (!props.value) return "";
  return (
    <Container {...props}>
      <img src={configurationProvider.cdn + "icons/successIcon.svg"} alt="check" />

      <Text value={props.value} />
    </Container>
  );
};
