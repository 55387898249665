import styled from "styled-components";
import Form from "../../components/containers/Form";

export const RegisterUserForm = styled(Form)`
  background-color: var(--color-primary);
  color: var(--color-white);
  margin: 12px 0 12px 0;
  padding: 32px;
  width: 50%;

  h3 {
    font-size: 20px;
  }
  .checkboxes {
    padding: 10px;
    label {
      margin-bottom: 5px;
    }
  }
  @media (max-width: 820px) {
    width: 100%;
  }
`;

export const Adviseurs = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
`;
