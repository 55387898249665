import { useContext, useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import { WebServiceContext } from "../../global/WebService/WebServiceState";
import { UserContext } from "../../global/User/UserState";
import { LocalizationContext } from "../../global/Localization/LocalizationState";
import { getQueryVariable } from "../../utils/common";
import LoginViewModel from "../login/Login.VM";

const RegisterUserViewModel = () => {
  const navigate = useNavigate();

  const { viewModel } = LoginViewModel();

  const { clientWebService, error, identityWebService } = useContext(WebServiceContext);

  const { userProvider } = useContext(UserContext);
  const [success] = useState("");
  const [username, setUsername] = useState("");
  const [newsletter, setNewsletter] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [privacyError, setPrivacyError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [pageError, setPageError] = useState("");
  const [state, setState] = useState({ password: "", passwordCheck: "" });
  const [notEqual, onEqual] = useState(false);
  const [valid, onValidationChange] = useState(true);

  const { localizationProvider } = useContext(LocalizationContext);

  const [token, setToken] = useState(false);
  useEffect(() => {
    const rfid = getQueryVariable("rfid");
    const token = getQueryVariable("token");
    const cc = getQueryVariable("cc");
    if (cc && localizationProvider.locale !== cc.toLowerCase()) {
      localizationProvider.toggleLocalization();
    }
    if (rfid) {
      getUsername(rfid);
    }
    if (token) setToken(token);
  }, []);

  async function sendPasswords() {
    if (state.password !== state.passwordCheck) {
      onEqual(true);
      return;
    } else onEqual(false);

    if (!isBusy) {
      const response = await identityWebService.resetPassword(username, `${state.password}`, token);

      if (response && response.dateTime) {
        const login = await identityWebService.postLogin(username, state.password);

        if (userProvider.initRoles(login)) await viewModel.getProjects(login.access_token);
      }

      setIsBusy(false);
    }
  }
  async function getUsername(rfid) {
    if (!isBusy) {
      const response = await clientWebService.getUsername(rfid);

      if (response.email) {
        setUsername(response.email);
      }
      setIsBusy(false);
    }
  }

  return {
    viewModel: {
      newsletter: newsletter,
      privacy: privacy,
      setNewsletter: setNewsletter,
      setPrivacy: setPrivacy,
      privacyError: privacyError,
      setPrivacyError: setPrivacyError,
      username: username,
      usernameError: usernameError,
      setUsernameError: setUsernameError,
      setUsername: setUsername,
      success: success,
      onValidationChange: onValidationChange,
      notEqual: notEqual,
      onEqual: onEqual,
      valid: valid,
      state: state,
      setState: setState,
      pageError: pageError,
      setPageError: setPageError,
      error: error,
      isBusy: isBusy,
      sendPasswords: sendPasswords,
    },
  };
};

export default RegisterUserViewModel;
