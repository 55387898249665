import { useEffect, useReducer, useState } from 'react';

import axios from 'axios';

import { delay } from '../../utils/common';

import configurationReducer from './configurationReducer';

function useConfigurationDataManager(appProvider) {

  const [dependancies] = useState({
    appProvider: appProvider
  });

  const [
    {
      isLoading,
      configuration,
      hasErrored,
      error
    },
    dispatch,
  ] = useReducer(configurationReducer, {
    isLoading: true,
    configuration: null,
    hasErrored: false,
    error: null
  });

  useEffect(() => {
    const fetchData = async function (appProvider) {
      try {
        appProvider.addLoader();
        let result = await axios.get("/config.json");
        if (result.data) {
          applyEnvironment(result.data);
        }
        await delay(1);
        dispatch({ type: "setConfiguration", data: result.data });
      } catch (e) {
        dispatch({ type: "errored", error: e });
      } finally {
        appProvider.removeLoader();
      }
    };
    fetchData(dependancies.appProvider);

    return () => {};
  }, [dependancies]);

  function applyEnvironment(data) {
    data.domains.identity = getEnvironmentItem("REACT_APP_URLS_IDENTITY");
    data.domains.client = getEnvironmentItem("REACT_APP_URLS_CLIENT");

    return data.domains;
  }
  function getEnvironmentItem(name) {
    if (!process.env[name]) {
      console.error(`The Environment Variable [${name}] does not exist.`);
      return null;
    }
    return process.env[name];
  }

  return {
    isLoading,
    configuration,
    hasErrored,
    error
  };
}
export default useConfigurationDataManager;