import styled from 'styled-components';


const ArrowImg =
  "PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2Ljg1NyAxMS4xMzkiPgogIDxwYXRoIGlkPSJhcnJvdyIgZD0iTTEwNi41NSwyMy42YTEuMzIxLDEuMzIxLDAsMCwwLDIuMjU1LjkzOWw0LjIxNS00LjIyYTEuMzE5LDEuMzE5LDAsMCwwLC4zODYtLjk0di0uMDQzYTEuMzE2LDEuMzE2LDAsMCwwLS4zODUtLjk3N0wxMDguOCwxNC4xNDdhMS4zMjEsMS4zMjEsMCwwLDAtMS44NjcsMS44NjlsMy4zMjQsMy4zMjMtMy4zMjQsMy4zMjNhMS4zMTksMS4zMTksMCwwLDAtLjM4Ni45MzRaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTA2LjU1IC0xMy43ODQpIiBmaWxsPSIjZTYzMzZmIi8+Cjwvc3ZnPgo=";

export const Arrow = styled.button`
  background-image: url(data:image/svg+xml;base64,${ArrowImg});
  background-repeat: no-repeat;
  background-color: transparent;
  height: ${(props) => (props.big ? "20px" : "13px")};
  border: none;
  width: ${(props) => (props.big ? "20px" : "13px")};
  margin: 0 4px;
  ${(props) => props.left && "transform: rotate(180deg)"};
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;


export const StyledBlock = styled.div`
  background-color: ${(props) => (props.close ? "" : "var(--color-back-grey)")};
  position: relative;
  width: 100%;
  @media (m-width: 768px) {
    width: 80%;
  }
  .flex {
    display: flex;
    overflow: hidden;
  }
  .customWidth {
    width: 92%;
    margin: 13px auto;
  }
  .multipleBadges {
    display: block;
    max-width: 1200px;
    text-align: center;
    margin: 0 auto;
    @media (max-width: 768px) {
      text-align: left;
    }
  }
  .overlay {
    width: 115%;
    position: relative;
    left: -3vw;
    @media (max-width: 768px) {
      width: 100%;
      display: block;
      left: 0;
    }
  }
`;

export const InsideBlock = styled.div`
  width: 150px;
  position: absolute;
  top: -10px;
  background: var(--color-back-grey);
  left: ${(props) => (props.left ? props.left : "-40px")};
  @media (max-width: 1024px) {
    left: 0px;
    position: relative;
    background: transparent;
  }

  box-shadow: 4px 6px 35px #0000001a;
`;

export const StatisticBlock = styled.div`
  height: fit-content;
  min-height: 240px;
  top: ${(props) => props.top};
  display: flex;
  width: 100%;
  margin-left: ${(props) => props.marginLeft};
  @media (max-width: 1024px) {
    margin-left: 0;
    display: block;
  }
  .whiteStat {
    z-index: 1000;
    background-color: white;
    height: 220px;
    position: absolute;
    top: -30px;
    box-shadow: 4px 6px 35px #0000001a;
    width: 93%;

    @media (max-width: 768px) {
      left: 0px;
      top: 0px;
      position: relative;

      height: auto;
    }
  }
  .btnBenchmark {
    position: absolute;
    padding: 5px 10px;
    background: white;
    bottom: -15px;
    left: 40%;
    font-size: 1em;
    color: var(--color-pink);
    cursor: pointer;
  }
  .arrows {
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
    @media (max-width: 768px) {
      top: "";
    }
  }
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
export const B = styled.div`
  min-width: ${(props) => props.minWidth};
  width: ${(props) => (props.width ? props.width : "42%")};
  margin: 20px;
  background-color: white;
  padding: 30px;
  box-shadow: 4px 6px 35px #0000001a;
  display: ${(props) => (props.flex ? "flex" : "")};
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    display: block;
    margin: 0;
  }
  .padding5 {
    padding: 1% 7%;
  }
`;
export const HorizontalStatsContainer = styled.div`
 box-sizing: border-box;
 width:100%;
 position:relative;
 :after {
  content: "";
  display: table;
  clear: both;

}
width: ${(props) => props.width}
  padding: 20px;
  .column{ 
    float: left;
  width: 50%;
  padding: 10px;

  @media (max-width: 1024px) {
    width: 100%;
  }
  
  }
  .cat {
    border: 1px solid transparent;
    border-radius: 5px;
    display: flex;
    font-size: 16px;
    align-items: center;
  }

  .pair{
    margin: 20px 0px;
  display:flex;
    .num{
      padding:0 10px;
      font-size:13px;
      font-weight:400;
    }
  }

  .fullWidth{width:100%;    margin-bottom: 14px;color: var(--color-primary)}
  .text {
    width: 30%;
  }
  .navig{
   position: absolute;
    bottom: 0; 
    right: 0;
  }
  .percent{font-family: bold}
`;
export const Progress = styled.div`
  width: ${(props) => props.done / 1.5 + "%"};
  border-radius: 5px;
  position: relative;
  margin: 0 10px;
  height: 10px;
`;
export const TitleBlock = styled.div`
  letter-spacing: -0.07px;
  font-size: 20px;
  font-family: bold;
  color: var(--color-primary);
`;
export const ProgressDone = styled.div`
  background: ${(props) => props.color};

  opacity: ${(props) => (props.benchmark ? "0.5" : "")};
  border-radius: 10px;
  height: 13px;
  transition: width 1s ease 0.3s;
`;

export const SectionBadge = styled.div`
  word-break: break-all;
  width: ${(props) => (props.width ? props.width : props.badge ? "180px" : "200px")};
  height: ${(props) => (props.height ? props.height : props.badge ? "190px" : "")};
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  align-items: flex-start;
  background-color: var(--color-light-grey);
  box-sizing: border-box;
  color: var(--color-primary);
  box-shadow: 0 0 30px var(--color-shadow);
 
  @media (max-width: 1024px) {
     box-shadow: 0;
  }
  > div:first-child {
    ${(props) => (props.badge ? "padding: 25px 25px 0px 25px; width: 90%; margin:0 auto" : "padding: 12px; width: 100%;")}}

    > div:first-child {
      > svg {
        width: 100%;

        padding: 8px;
      }
    }
    ${(props) =>
      props.badge
        ? "cursor:none;"
        : `
  :hover {
    border-color: var(--color-primary-selected);
    color: var(--color-primary-selected);
cursor:pointer;
    > div:first-child {
      > svg g {
        fill: var(--color-primary-selected);
      }
    }`}
  }

  > div:last-child {
    ${(props) => (props.badge ? "" : "background-color:white;")};
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    position: static;
    width: 100%;
    height: 42px;
    left: 16px;
    top: 140px;
    flex: 0 0 auto;
    order: 1;
    align-self: stretch;
    -webkit-box-flex: 0;
  }

  > div:last-child span {
    line-height: 25px;
    font-size: 20px;
    padding: 10px;
    margin: auto;
    text-align: center;
  }
 
`;

