import { createTheme } from "@mui/material";

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function getQueryVariable(name, url, decode = true) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decode === true ? decodeURIComponent(results[2]) : results[2];
}

export const fontStyle = createTheme({
  typography: {
    fontFamily: ['"Century Gothic"', '"Bold"'].join(","),
  },
});

export function generatedIdByTime() {
  return Date.now().toString();
}