import styled from "styled-components";

import Form from "../../components/containers/Form";

export const BloodpressureForm = styled.div`
  .box {
    background: white;
  }
  .back {
    margin: 20px 0;
  }
  h1 {
    color: var(--color-primary);
  }

  h3 {
    font-size: 20px;
  }

  .form {
    padding: 10px 20%;
    @media (max-width: 768px) {
      padding: 10px 10%;
    }
  }
  .marginTop {
    margin-top: 10px;
  }
  .marginMinTop {
    margin-top: -5%;
  }
  .margin20 {
    margin: 20px 0;
  }
  .add_location_button {
    display: flex;
    .button {
      border-radius: 25px;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }
  .buttons {
    @media (max-width: 768px) {
      width: 100%;
    }
    div {
      width: auto;
    }
  }

  .bloodpressure_buttons {
    margin-top: 10px;
    width: 50%;
    float: right;
    text-align: right;
    > :first-child {
      font-style: italic;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
  }
  .noData {
    text-align: center;
    font-size: 14px;
    margin: 10px;
  }
`;

export const StyledBlock = styled.div`
  background-color: var(--color-back-grey);
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const TwoCol = styled.div`
  display: block;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  * {
    margin-top: 10px;
    margin-right: 10px;
  }
  .hidden {
    visibility: hidden;
  }
`;
