import { useContext, useState } from "react";
import { WebServiceContext } from "../../global/WebService/WebServiceState";
import { StorageContext } from "../../global/Storage/StorageState";
import { useNavigate } from "react-router-dom";
import { getStepURL, validateDate } from "../../components/progress/ProgressStepBar";
const CommunicationViewModel = () => {
  const { clientWebService, info, error } = useContext(WebServiceContext);
  const navigate = useNavigate();
  const [isBusy, setIsBusy] = useState(false);
  const { storageProvider } = useContext(StorageContext);

  const project = storageProvider.project;

  async function finish() {
    const steps = storageProvider.project.configurationSteps.data;
    if (validateDate(steps.find((x) => x.name === "Communicatie" || x.name === "Communication").created)) {
      navigate(nextStep());
      return;
    }

    const response = await clientWebService.finishStep(project.globalId, project.configurationSteps.data.find((x) => x.name === "Communicatie" || x.name === "Communication").globalId);
    if (response.globalId) {
      const index = steps.findIndex((step) => step.globalId === response.globalId);
      if (index !== -1) {
        steps[index] = response;
      }

      storageProvider.project.configurationSteps.data = steps;
      storageProvider.setProject(storageProvider.project);
      navigate(nextStep());
      setIsBusy(false);
      return;
    }
  }

  const configurationSteps = storageProvider.project.configurationSteps.data.sort((a, b) => a.order - b.order) || [];
  const currentOrder = configurationSteps.find((x) => x.name === "Communicatie" || x.name === "Communication").order;

  function nextStep() {
    return getStepURL(configurationSteps.find((x) => x.order === currentOrder + 1).name);
  }
  return {
    viewModel: {
      error: error,
      isBusy: isBusy,
      user: info.accounts,
      finish: finish,
      nextStep: nextStep,
    },
  };
};

export default CommunicationViewModel;
