import React, { useState, useContext } from "react";
import Text, { Bullet } from "../../components/text/Text";
import {FullPage} from "../../components/containers/Page";
import ProjectQuestionsViewModel from "./ProjectQuestions.VM";
import { ProjectQuestionsForm, StyledBlock } from "./ProjectQuestions.Components";
import { TextField } from "@mui/material";
import { LocalizationContext } from "../../global/Localization/LocalizationState";
import {  generatedIdByTime } from "../../utils/common";
import Button, { ButtonGroup } from "../../components/buttons/Button";
import AddQuestion from "./addquestion";
import ProjectTable from "./projecttable";
import Modal from "../../components/modals/Modal";
import { TwoCol } from "./ProjectQuestions.Components";
import { useEffect } from "react";
import { ModalContext } from "../../global/Modal/ModalState";
import { White } from "../../core/enums/ButtonType";
import { SvgIcon } from "../../components/svgIcon/svgIcon";
import { SuccessMessage } from "../../components/messages/messages";
import ProgressStepBar from "../../components/progress/ProgressStepBar";
import { useNavigate } from "react-router-dom";

const RenderModal = ({ close, question, answers, onSave, onDelete }) => {
  const [selectedAnswers, setAnswers] = useState([
    {
      metadata: [
        {
          cultureCode: "EN",
          text: "",
        },
        {
          cultureCode: "NL",
          text: "",
        },
      ],
    },
    {
      metadata: [
        {
          cultureCode: "EN",
          text: "",
        },
        {
          cultureCode: "NL",
          text: "",
        },
      ],
    },
    {
      metadata: [
        {
          cultureCode: "EN",
          text: "",
        },
        {
          cultureCode: "NL",
          text: "",
        },
      ],
    },
  ]);
  const [customQuestion, setCustomQuestion] = useState({
    name: "",
    isDefault: false,
    metadata: [
      {
        cultureCode: "EN",
        text: "",
      },
      {
        cultureCode: "NL",
        text: "",
      },
    ],
    answerOptions: [],
  });
  useEffect(() => {
    setCustomQuestion(question);
    if (answers) {
      setAnswers(question.answerOptions);
    }
  }, [question.answerOptions, answers, question]);
  const { localizationProvider } = useContext(LocalizationContext);

  const addAnswerOptions = (id) => {
    const answer = {
      id: id,
      isNew: true,
      index: selectedAnswers.length,
      metadata: [
        { cultureCode: "EN", text: "" },
        { cultureCode: "NL", text: "" },
      ],
    };
    setAnswers((answers) => [...answers, answer]);
  };
  const onChangeAnswer = (e) => {
    const id = e.target.id.toString();
    const existingAnswer = selectedAnswers[id].globalId ? { isChanged: true } : null;
    const updatedMetadata = selectedAnswers[id].metadata.map((meta) => {
      if (meta.cultureCode === e.target.name.substring(0, 2)) {
        return { ...meta, text: e.target.value };
      }
      return meta;
    });
    const updatedSelectedAnswers = [...selectedAnswers];
    updatedSelectedAnswers[id] = {
      ...updatedSelectedAnswers[id],
      metadata: updatedMetadata,
      ...existingAnswer,
    };
    setAnswers(updatedSelectedAnswers);
  };

  const onChangeQuestion = (e) => {
    const updatedMetadata = customQuestion.metadata.map((meta) => {
      if (meta.cultureCode === e.target.name.substring(0, 2)) {
        return { ...meta, text: e.target.value };
      }
      return meta;
    });
    setCustomQuestion({ ...question, metadata: updatedMetadata });
  };

  const copyNl2En = (index) => {
    const updatedAnswers = [...selectedAnswers];
    const answerToUpdate = updatedAnswers[index];
    const nlText = answerToUpdate.metadata.find((item) => item.cultureCode === "NL").text;
    const enMetadataIndex = answerToUpdate.metadata.findIndex((item) => item.cultureCode === "EN");
    answerToUpdate.metadata[enMetadataIndex].text = nlText;
    setAnswers(updatedAnswers);
  };
  const metaText = (obj, lang) => {
    return obj.metadata.find((q) => q.cultureCode === lang).text;
  };
  const getTabIndex = (index, value) => value + index * 2;

   return (
     <Modal onClose={close} className="modal">
       <h1 className="margin20">
         <Text value="ADD_QUESTION_TITLE" />
       </h1>
       <div className="margin20">
         {" "}
         <Text value="ADD_QUESTION_TEXT" />
       </div>
       <TwoCol>
         <div>
           <div className="question_title">
             <h3>
               <Text value="DUTCH" />
             </h3>
           </div>
           <TextField
             label="QUESTION_NL"
             name="NL"
             variant="filled"
             fullWidth
             value={metaText(customQuestion, "NL").toString()}
             lang="NL"
             onChange={(e) => {
               !question.globalId && onChangeQuestion(e);
             }}
             inputProps={{
               style: {
                 height: 50,
               },
               tabIndex: 101,
             }}
             helperText={localizationProvider.getLocalization("EXAMPLE_DUTCH")}
           />
           <div className="ABCorder">
             <ol type="A" className="bold" key={"olnl"}>
               {selectedAnswers.map((x, id) => (
                 <div key={"ABC_nl_" + id}>
                   <li>
                     <TextField
                       label="ANSWER_OPTION"
                       name={"NL" + id}
                       variant="filled"
                       fullWidth
                       value={metaText(x, "NL").toString()}
                       id={id}
                       key={id.toString()}
                       onChange={(e) => {
                         onChangeAnswer(e);
                       }}
                       inputProps={{
                         tabIndex: getTabIndex(id, 103),
                       }}
                     />
                   </li>
                   {x.globalId ? (
                     <SvgIcon
                       className="delete-icon"
                       onClick={(e) => {
                         e.preventDefault();
                         onDelete(x);
                       }}
                     >
                       <svg className="trash" viewBox="0 0 110.61 122.88">
                         <path d="M39.27,58.64a4.74,4.74,0,1,1,9.47,0V93.72a4.74,4.74,0,1,1-9.47,0V58.64Zm63.6-19.86L98,103a22.29,22.29,0,0,1-6.33,14.1,19.41,19.41,0,0,1-13.88,5.78h-45a19.4,19.4,0,0,1-13.86-5.78l0,0A22.31,22.31,0,0,1,12.59,103L7.74,38.78H0V25c0-3.32,1.63-4.58,4.84-4.58H27.58V10.79A10.82,10.82,0,0,1,38.37,0H72.24A10.82,10.82,0,0,1,83,10.79v9.62h23.35a6.19,6.19,0,0,1,1,.06A3.86,3.86,0,0,1,110.59,24c0,.2,0,.38,0,.57V38.78Zm-9.5.17H17.24L22,102.3a12.82,12.82,0,0,0,3.57,8.1l0,0a10,10,0,0,0,7.19,3h45a10.06,10.06,0,0,0,7.19-3,12.8,12.8,0,0,0,3.59-8.1L93.37,39ZM71,20.41V12.05H39.64v8.36ZM61.87,58.64a4.74,4.74,0,1,1,9.47,0V93.72a4.74,4.74,0,1,1-9.47,0V58.64Z" />
                       </svg>
                     </SvgIcon>
                   ) : x.metadata.find((q) => q.cultureCode === "NL").text !== "" && x.metadata.find((q) => q.cultureCode === "EN").text === "" ? (
                     <SvgIcon
                       className="delete-icon copy"
                       onClick={(e) => {
                         e.preventDefault();
                         copyNl2En(id);
                       }}
                     >
                       <svg fill="#000000" className="trash" viewBox="0 0 330 330">
                         <path
                           d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255
	                        s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0
	                        c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"
                         />
                       </svg>
                       <div className="small">
                         <Text value="COPY" />
                       </div>
                     </SvgIcon>
                   ) : (
                     <SvgIcon className="delete-icon">
                       <svg className="trash" viewBox="0 0 110.61 122.88"></svg>
                     </SvgIcon>
                   )}
                 </div>
               ))}
             </ol>
           </div>

           {/* <ol type="A" className="bold" key={row.id + generatedIdByTime}>
            {answers[1].map((answer) => (
              <li key={answer + generatedIdByTime()}>
                <div className="normal"> {answer}</div>
              </li>
            ))}
          </ol> */}
         </div>
         <div>
           <div className="question_title">
             <h3>
               <Text value="ENGLISH" />
             </h3>
             <div className="">
               <Text value="ENGLISH_QUESTION_TEXT" />{" "}
               <a href="https://translate.google.com/" target="_blank" rel="noreferrer">
                 <Text value="GOOGLE_TRANSLATE" />
               </a>
             </div>
           </div>
           <TextField
             label="QUESTION_EN"
             name="EN"
             fullWidth
             inputProps={{
               style: {
                 height: 50,
               },
               tabIndex: 102,
             }}
             error={question.globalId ? false : customQuestion.metadata.find((q) => q.cultureCode === "NL").text !== "" && customQuestion.metadata.find((q) => q.cultureCode === "EN").text === ""}
             required={question.globalId ? false : customQuestion.metadata.find((q) => q.cultureCode === "NL").text !== "" && customQuestion.metadata.find((q) => q.cultureCode === "EN").text === ""}
             onChange={(e) => {
               !question.globalId && onChangeQuestion(e);
             }}
             helperText={localizationProvider.getLocalization("EXAMPLE_ENGLISH")}
             variant="filled"
             value={metaText(customQuestion, "EN").toString()}
           />
           <div className="ABCorder">
             <ol type="A" className="bold" key={"olen"}>
               {selectedAnswers.map((x, id) => (
                 <div key={"ANSWER_" + id}>
                   <li>
                     <TextField
                       label="ANSWER_OPTION"
                       name={"EN" + id}
                       variant="filled"
                       error={x.metadata.find((q) => q.cultureCode === "NL").text !== "" && x.metadata.find((q) => q.cultureCode === "EN").text === ""}
                       required={x.metadata.find((q) => q.cultureCode === "NL").text !== "" && x.metadata.find((q) => q.cultureCode === "EN").text === ""}
                       fullWidth
                       value={metaText(x, "EN").toString()}
                       id={id}
                       key={id}
                       onChange={(e) => {
                         onChangeAnswer(e);
                       }}
                       inputProps={{
                         tabIndex: getTabIndex(id, 104),
                       }}
                     />
                   </li>
                   {x.globalId ? (
                     <SvgIcon
                       className="delete-icon"
                       onClick={(e) => {
                         e.preventDefault();
                         onDelete(x);
                       }}
                     >
                       <svg className="trash" viewBox="0 0 110.61 122.88">
                         <path d="M39.27,58.64a4.74,4.74,0,1,1,9.47,0V93.72a4.74,4.74,0,1,1-9.47,0V58.64Zm63.6-19.86L98,103a22.29,22.29,0,0,1-6.33,14.1,19.41,19.41,0,0,1-13.88,5.78h-45a19.4,19.4,0,0,1-13.86-5.78l0,0A22.31,22.31,0,0,1,12.59,103L7.74,38.78H0V25c0-3.32,1.63-4.58,4.84-4.58H27.58V10.79A10.82,10.82,0,0,1,38.37,0H72.24A10.82,10.82,0,0,1,83,10.79v9.62h23.35a6.19,6.19,0,0,1,1,.06A3.86,3.86,0,0,1,110.59,24c0,.2,0,.38,0,.57V38.78Zm-9.5.17H17.24L22,102.3a12.82,12.82,0,0,0,3.57,8.1l0,0a10,10,0,0,0,7.19,3h45a10.06,10.06,0,0,0,7.19-3,12.8,12.8,0,0,0,3.59-8.1L93.37,39ZM71,20.41V12.05H39.64v8.36ZM61.87,58.64a4.74,4.74,0,1,1,9.47,0V93.72a4.74,4.74,0,1,1-9.47,0V58.64Z" />
                       </svg>
                     </SvgIcon>
                   ) : (
                     <SvgIcon className="delete-icon">
                       <svg className="trash" viewBox="0 0 110.61 122.88"></svg>
                     </SvgIcon>
                   )}
                 </div>
               ))}
             </ol>
           </div>
         </div>
       </TwoCol>
       <div className="flex spaceBetween">
         {selectedAnswers.length < 7 ? (
           <button className="margin30 addAnwerButton" onClick={() => addAnswerOptions(generatedIdByTime())}>
             {" "}
             +
             <Text value="ADD_ANSWER" />
           </button>
         ) : (
           <div />
         )}
         <ButtonGroup width="60%" spaceBetween>
           <Button styleType={5} type="button" width="40%" onClick={close}>
             <Text value="CANCEL" />
           </Button>
           &nbsp;
           <Button
             onClick={(e) => {
               e.preventDefault();
               onSave(customQuestion, selectedAnswers);
             }}
           >
             <Text value="SAVE" />
           </Button>
         </ButtonGroup>
       </div>
     </Modal>
   );
};

const ProjectQuestions = () => {
  const { viewModel } = ProjectQuestionsViewModel();
  const navigate = useNavigate();
  const { localizationProvider } = useContext(LocalizationContext);
  const [modal, showModal] = useState(false);
  const { modalProvider } = useContext(ModalContext);
  const [selectedQuestion, setSelectedQuestion] = useState(false);
  const [customQuestion] = useState({
    name: "",
    isDefault: false,
    metadata: [
      {
        cultureCode: "EN",
        text: "",
      },
      {
        cultureCode: "NL",
        text: "",
      },
    ],
    answerOptions: [],
  });
  const [prefilledAnswers, setPrefilledAnswers] = useState(false);
  const [isDefault, byDefault] = useState(false);

  const addQuestion = (id, type) => {
    if (type === "custom") {
      setPrefilledAnswers(false);
      byDefault(false);
      setSelectedQuestion(type);
      showModal(true);
      return;
    } else if (type === "add") {
      setPrefilledAnswers(false);
      byDefault(true);
    } else {
      setPrefilledAnswers(true);
    }
    setSelectedQuestion(id);
    showModal(true);
  };

  function filterEmptyText(data) {
    return data.filter((item) => item.metadata.some((metadata) => metadata.text.trim() !== ""));
  }
  const onDelete = (answer) => {
    viewModel.deleteAnswer(answer);
  };

  function findObjectsWithEmptyText(arr) {
    return arr.filter((obj) => {
      if (obj.metadata) {
        const hasNLText = obj.metadata.some((meta) => meta.cultureCode === "NL" && meta.text !== "");
        const hasENEmptyText = obj.metadata.some((meta) => meta.cultureCode === "EN" && meta.text === "");
        return hasNLText && hasENEmptyText;
      }
      return false;
    });
  }

  const onSave = (question, answers) => {
    if (findObjectsWithEmptyText(answers).length) {
      return;
    }
    const changedMetadataValues = answers.filter((answer) => answer.isChanged && answer.globalId);
    const isNew = answers.filter((answer) => answer.isNew);
    const valueAnswers = filterEmptyText(answers);

    if (changedMetadataValues.length) {
      viewModel.changeAnswers(changedMetadataValues);
    }

    if (!question.isDefault && !question.created) {
      if (!(question.metadata.find((q) => q.cultureCode === "NL").text.length && question.metadata.find((q) => q.cultureCode === "EN").text.length)) {
        return;
      }
      viewModel.saveCustomQuestion(question, valueAnswers);
      showModal(false);
      return;
    }

    if (!isDefault && !isNew.length && !changedMetadataValues.length) {
      return showModal(false);
    }

    if (question.isModified) {
      viewModel.addedNewValues(question.globalId, valueAnswers, question.defaultGlobalId);
    } else if (isDefault) {
      viewModel.saveDefaultQuestion(question.defaultGlobalId, valueAnswers);
      byDefault(false);
    }
    showModal(false);
  };
  return (
    <FullPage width="true" parentMargin="0 -10%">
      <ProjectQuestionsForm>
        <StyledBlock className="fullsize">
          <div className="form marginTop">
            <div className="progressContact">
              <ProgressStepBar currentStep={3} />
            </div>
            <div> </div>
            <h1 className="margin30">
              <Text value="EXTRA_QUESTIONS_TITLE" />
            </h1>
            <div className="margin30">
              {" "}
              <Text value="EXTRA_QUESTIONS_TEXT" />
            </div>
            <h3 className="margin30">
              <Text value="SELECT_QUESTIONS" bold />
            </h3>
            {modal && (
              <RenderModal
                close={(e) => {
                  e.preventDefault();
                  showModal(false);
                }}
                answers={prefilledAnswers}
                onSave={onSave}
                question={selectedQuestion === "custom" ? customQuestion : viewModel.questions.find((question) => question.defaultGlobalId === selectedQuestion)}
                onDelete={onDelete}
              />
            )}
            <div className="add_location_button margin20 two-columns">
              {viewModel.questions.length > 0 &&
                viewModel.questions
                  .filter((question) => !question.isModified && question.isDefault)
                  .map((question, i) => (
                    <AddQuestion
                      key={question.globalId + i + generatedIdByTime()}
                      id={question.defaultGlobalId}
                      addQuestion={(id) => {
                        addQuestion(id, "add");
                      }}
                      name={question.metadata.find((obj) => obj.cultureCode === localizationProvider.locale.toUpperCase()).text}
                    />
                  ))}
            </div>
            <h3 className="margin30">
              <Text value="TIPS" bold />
            </h3>
            <Bullet>
              <Text value="MAX_QUESTIONS" />
            </Bullet>
            <Bullet>
              <Text value="QUESTIONS_PERSONAL" />
            </Bullet>
            <Bullet>
              <Text value="MANUAL_ENTRY_QUESTIONS" />{" "}
              <button onClick={() => addQuestion(null, "custom")}>
                <Text value="ADD_QUESTION_LINK" />{" "}
              </button>
            </Bullet>
            <Bullet>
              <Text value="CHANGE_QUESTIONS_BEFORE_START" />
            </Bullet>
            <h3 className="margin30">
              <Text value="SELECTED_QUESTIONS" bold />
            </h3>
            {viewModel.message && <SuccessMessage name={viewModel.message} />}
            {viewModel.questions.length > 0 && viewModel.questions.filter((x) => x.isModified) && (
              <ProjectTable
                rows={viewModel.questions.filter((x) => x.isModified)}
                editQuestion={(id) => {
                  addQuestion(id);
                }}
                deleteQuestion={(id, defaultGlobalId) =>
                  modalProvider.showModal(
                    "DELETE_QUESTION_TITLE",
                    "DELETE_QUESTION_DESCRIPTION",
                    [
                      { value: viewModel.questions.find((x) => x.defaultGlobalId === defaultGlobalId).metadata.find((q) => q.cultureCode === "NL").text },
                      { value: viewModel.questions.find((x) => x.defaultGlobalId === defaultGlobalId).metadata.find((q) => q.cultureCode === "EN").text },
                    ],
                    () => viewModel.deleteQuestion(id, defaultGlobalId),
                    modalProvider.hideModal,
                    "YES_CONFIRM_DELETE",
                    null,
                    null,
                    { width: "95%", buttonsWidth: "80%", styleTypeCancel: White, styleTypeConfirm: "Pink" }
                  )
                }
              ></ProjectTable>
            )}
            <div className="bottomTextBtns">
              <Text value="ALL_INFORMATION_SAVED"></Text>
              <ButtonGroup spaceBetween>
                <Button
                  styleType={5}
                  type="button"
                  width="48%"
                  onClick={() => {
                    navigate(viewModel.nextStep());
                  }}
                >
                  <Text value="LATER_CONTINUE" />
                </Button>
                <Button
                  width="48%"
                  onClick={async (event) => {
                    event.preventDefault();
                    viewModel.finish();
                  }}
                >
                  <Text value="NEXT" />
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </StyledBlock>
      </ProjectQuestionsForm>
    </FullPage>
  );
};

export default ProjectQuestions;
