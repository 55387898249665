import styled from "styled-components";

import Form from "../../components/containers/Form";

export const WhiteListForm = styled(Form)`
  h1 {
    color: var(--color-primary);
  }

  h3 {
    font-size: 20px;
  }

  .form {
    padding: 10px 20%;
  }
  .marginTop {
    margin-top: 10px;
  }
`;
export const StyledBlock = styled.div`
  background-color: var(--color-back-grey);
  position: relative;
  width: 100%;

  .whiteList {
    margin: 40px 0;
  }
  .bottomTextBtns {
    margin-top: 20px;
    width: 50%;
    float: right;
    text-align: right;
    font-style: italic;
    @media (max-width: 400px) {
      width: 100%;
    }
  }
`;
