import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Text from "../../components/text/Text";
import HomeViewModel from "./Home.VM";
import { AppContext } from "../../global/App/AppState";
import { Block, HomeForm, HorizontalStatsContainer, SectionBadge, StyledBlock } from "./Home.Components";
import { FullPage } from "../../components/containers/Page";
import StyledLineData from "../../components/linedata/linedata";
import DoughnutChart from "../../components/donut/donut";
import { Back } from "../../components/back/back";
// import Calendar from "../../components/calendar/calendar";
// import Details from "../../components/calendar/calendarDetails";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";

const UserData = [
  {
    id: 1,
    year: 2016,
    userGain: 80000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345,
  },
  {
    id: 3,
    year: 2018,
    userGain: 78888,
    userLost: 555,
  },
  {
    id: 4,
    year: 2019,
    userGain: 90000,
    userLost: 4555,
  },
];
const Gender = [
  { label: "Female", percent: 56 },
  { label: "Male", percent: 44 },
];

const AgeGroup = [
  {
    id: 1,
    label: "18-30",
    percent: 28,
  },
  {
    id: 2,
    label: "31-40",
    percent: 33,
  },
  {
    id: 3,
    label: "41-50",
    percent: 20,
  },
  {
    id: 4,
    label: "50+",
    percent: 19,
  },
];
const Education = [
  {
    id: 1,
    label: "HBO/WO/Postdoctoraal",
    percent: 28,
  },
  {
    id: 2,
    label: "MBO/HAVO/VWO",
    percent: 33,
  },
  {
    id: 3,
    label: "Lager onderwijs/LBO/MAVO",
    percent: 20,
  },
  {
    id: 4,
    label: "Anders",
    percent: 19,
  },
];
const Naslagwerk = [
  {
    id: 1,
    name: "Meetwaarden",
    text: "Goede eetgewoonten zijn van belang om gezond te blijven. Door gezond gedrag te belonen of gezamenlijk aan leefstijlfactoren te werken",
  },
  {
    id: 2,
    name: "Referenties",
    text: "Goede eetgewoonten zijn van belang om gezond te blijven. Door gezond gedrag te belonen of gezamenlijk aan leefstijlfactoren te werken",
  },
  {
    id: 3,
    name: "Risicocriteria",
    text: "Goede eetgewoonten zijn van belang om gezond te blijven. Door gezond gedrag te belonen of gezamenlijk aan leefstijlfactoren te werken",
  },
  {
    id: 4,
    name: "Meetinstrumenten",
    text: "Goede eetgewoonten zijn van belang om gezond te blijven. Door gezond gedrag te belonen of gezamenlijk aan leefstijlfactoren te werken",
  },
];

function convertToLineData(data) {
  if (!Object.keys(data).length) return [];
  const invited = data.invitedParticipants;
  let result = [];
  Object.keys(data).map((x, y) => result.push({ id: y + 1, name: x, percent: Math.trunc((Number(data[x]) * 100) / Number(invited)), count: data[x] }));

  return result;
}
const Home = (props) => {
  const { configurationProvider } = useContext(ConfigurationContext);

  const { appProvider } = useContext(AppContext);
  const { viewModel } = HomeViewModel();
  const [selectedDates, setSelectedDates] = useState(["2024-07-15", "2024-07-22"]);
  const [selectedDate, setSelectedDate] = useState("");

  const navigate = useNavigate();
  const [state] = useState({
    projects: [],
    gender: {
      labels: Gender.map((data) => data.label),
      datasets: {
        label: "Gender",
        data: Gender.map((data) => data.percent),
      },
    },
    ageGroup: {
      labels: AgeGroup.map((data) => data.label),
      datasets: {
        label: "ageGroup",
        data: AgeGroup.map((data) => data.percent),
      },
    },
    education: {
      labels: Education.map((data) => data.label),
      datasets: {
        label: "education",
        data: Education.map((data) => data.percent),
      },
    },
  });
  //  let dates = [...new Set(state.projects.map((i) => moment(i.periodFrom).format("gggg")))].sort().reverse();
  let dates = [2022, 2021, 2020];
  const fetchStat = (id) => {
    this.props.fetchDatabyId(this.props.webapis, id);
  };
  const selectedDateValue = (value) => {
    setSelectedDate(value);
  };
  const hasData = Object.keys(viewModel.stats).length && viewModel.stats.invitedParticipants > 0;

  function convertToLineData(data) {
    if (!Object.keys(data).length) return [];
    const invited = data.invitedParticipants;
    let result = [];
    Object.keys(data).map((x, y) => result.push({ id: y + 1, name: x, percent: Math.trunc((Number(data[x]) * 100) / Number(invited)), count: data[x] }));

    return result;
  }


  return (
    <FullPage width="true" parentMargin="0 -8%">
      <HomeForm>
        {" "}
        <div className="form marginTop">
          {" "}
          <h1>
            <Text value="HOME" bold />
          </h1>
        </div>
        {/* <StyledBlock className="fullsize">
          <div className="form marginTop">
            <div className="planning">
              <h1>
                <Text value="PLANNING_TITLE" bold />
              </h1>
              <div className="calendar">
                <Calendar selectedDates={selectedDates} setSelectedDates={setSelectedDates} selectDate={selectedDateValue} />
                <Details dates={selectedDates} selectedDate={selectedDate} />
              </div>
            </div>
          </div>
        </StyledBlock>{" "} */}
        <div className="form marginTop">
          {" "}
          <h1>
            <Text value="PROGRESS_TITLE" bold />
          </h1>
          {!hasData && (
            <div className="centered-text">
              <img width="28px" src={`${configurationProvider.cdn}client/no_data_icon.svg`} alt="icon" />
              <br />
              <Text value="NO_AVAILABLE_DATA_FOR_THIS_PROJECT" />
            </div>
          )}
          <div className={!hasData ? "blur" : ""}>
            <div className="flex data">
              <Block width="60%">
                <h2>
                  <Text value="PARTICIPATION" />
                </h2>
                <HorizontalStatsContainer className="padding5">
                  <StyledLineData colors={["var(--color-primary)", "var(--color-secondary)", "var(--color-pink)"]} data={convertToLineData(viewModel.stats)} />
                </HorizontalStatsContainer>
              </Block>
              <Block width="40%">
                <h2>
                  <Text value="Gender" />
                </h2>
                {/* <DoughnutChart chartData={state.gender} /> */}
              </Block>
            </div>
            <div className="flex data">
              <Block width="40%">
                <h2>
                  <Text value="Age" />
                </h2>
                {/* <DoughnutChart chartData={state.ageGroup} /> */}
              </Block>
              <Block width="60%">
                {" "}
                <h2>
                  <Text value="Education" />
                </h2>{" "}
                {/* <DoughnutChart chartData={state.education} /> */}
              </Block>
            </div>
            <div className="bottomTextBtns">
              <Back onClick={!hasData ? "" : false} name="MORE_INFORMATION" reverse />
            </div>
          </div>{" "}
        </div>
      </HomeForm>
    </FullPage>

    // <Page width="80%" minPadding="50px -23% 0px -23%">
    //   <div className="title">
    //     <Title>
    //       <Text value="DASHBOARD" />
    //     </Title>
    //     <Text value={"DASHBOARD_TEXT"}></Text>
    //   </div>
    //   <Block>
    //     <Grid margin="10px 80px">
    //       <StyledBlock>
    //         <InsideBlock>
    //           <SectionBadge badge>
    //             <div>
    //               <HomeIcon index={0} color={"var(--color-button-primary)"} />
    //             </div>
    //             <div>
    //               <Text value={"Participation"}></Text>
    //             </div>
    //           </SectionBadge>
    //         </InsideBlock>
    //         <StatisticBlock top="25px" marginLeft="15%">
    //           <B flex width="50%">
    //             <HorizontalStatsContainer className="padding5">
    //               <StyledLineData colors={["var(--color-primary)", "var(--color-secondary)", "var(--color-pink)"]} data={LineData} />
    //             </HorizontalStatsContainer>
    //           </B>
    //           <B width="35%">
    //             <TitleBlock>
    //               <Text value="Gender" />
    //             </TitleBlock>
    //             <DoughnutChart chartData={state.gender} />
    //           </B>
    //         </StatisticBlock>
    //         <br />
    //         <StatisticBlock top="300px" marginLeft="3%">
    //           <B width="36%">
    //             <TitleBlock>
    //               <Text value="Age" />
    //             </TitleBlock>
    //             <DoughnutChart chartData={state.ageGroup} />
    //           </B>
    //           <B width="53%">
    //             <TitleBlock>
    //               <Text value="Education" />
    //             </TitleBlock>
    //             <DoughnutChart chartData={state.education} />
    //           </B>
    //         </StatisticBlock>
    //       </StyledBlock>
    //       {/* commented for now, will be changed after new design will be ready part2 */}
    //       {/* <StyledBlock>
    //         <InsideBlock>
    //           {" "}
    //           <SectionBadge badge>
    //             <div>
    //               <HomeIcon index={1} color={"var(--color-button-primary)"} />
    //             </div>
    //             <div>
    //               <Text value={"Aandachtpunten"}></Text>
    //             </div>
    //           </SectionBadge>
    //         </InsideBlock>
    //         <StatisticBlock top="25px" marginLeft="15%" marginTop="30%">
    //           <B width="87%">
    //             <TitleBlock>
    //               <Text value="For work" />
    //             </TitleBlock>

    //             <Text value="For work" />
    //             <HorizontalStatsContainer>
    //               <div className="column">
    //                 <StyledLineData colors={["var(--color-report-orange)"]} benchmark data={LineData} />
    //               </div>
    //               <div className="column">
    //                 <StyledLineData colors={["var(--color-report-orange)"]} benchmark data={LineData} />
    //               </div>
    //               <div className="navig">
    //                 <Arrow></Arrow>
    //               </div>
    //             </HorizontalStatsContainer>
    //           </B>
    //         </StatisticBlock>
    //         <br />
    //         <StatisticBlock top="400px" marginLeft="15%">
    //           <B>
    //             {" "}
    //             <HorizontalStatsContainer>
    //               <TitleBlock>
    //                 <Text value="For work" />
    //               </TitleBlock>
    //               <Text value="For work" />
    //               <StyledLineData colors={["var(--color-report-orange)"]} benchmark data={LineData} />
    //               <div className="navig">
    //                 <Arrow></Arrow>
    //               </div>
    //             </HorizontalStatsContainer>
    //           </B>
    //           <B>
    //             {" "}
    //             <HorizontalStatsContainer>
    //               <TitleBlock>
    //                 <Text value="For work" />
    //               </TitleBlock>

    //               <Text value="For work" />
    //               <StyledLineData colors={["var(--color-report-green)"]} benchmark data={LineData} />
    //             </HorizontalStatsContainer>
    //           </B>
    //         </StatisticBlock>
    //       </StyledBlock>

    //       {viewModel.sections.map((d, i) => (
    //         <SectionBadge
    //           key={d.route + i}
    //           textSize={appProvider.textSize}
    //           onClick={(r) => {
    //             viewModel.onNavigate(d.route);
    //           }}
    //         >
    //           <div>
    //             <HomeIcon index={d.index} color={"var(--color-button-primary)"} />
    //           </div>
    //           <div>
    //             <Text value={d.title}></Text>
    //             <Arrow></Arrow>
    //           </div>
    //         </SectionBadge>
    //       ))} */}
    //     </Grid>
    //   </Block>{" "}
    //   <StyledBlock className="fullsize">
    //     <div className="flex">
    //       <Grid minWidth="18%"></Grid>
    //       <Grid>
    //         <div>
    //           <Title>
    //             <Text value="foot_Title" />
    //           </Title>
    //           <div className="footer_text">
    //             <Text value={"Foot_TEXT"}></Text>
    //             <div>
    //               <Arrow big left onClick={() => shiftLeft()}></Arrow>
    //               <Arrow big onClick={() => shiftRight()}></Arrow>
    //             </div>
    //           </div>{" "}
    //           <Carousel elements={elements} />
    //         </div>
    //       </Grid>
    //     </div>
    //   </StyledBlock>
    // </Page>
  );
};

export default Home;
