import React, { useState, useContext, useEffect } from "react";
import Text from "../../components/text/Text";
import { FullPage } from "../../components/containers/Page";
import BloodpressureViewModel from "./Bloodpressure.VM";
import { BloodpressureForm, StyledBlock } from "./Bloodpressure.Components";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";
import { DataGrid, nlNL } from "@mui/x-data-grid";
import { Box, Stack, ThemeProvider } from "@mui/material";
import { LocalizationContext } from "../../global/Localization/LocalizationState";
import { fontStyle } from "../../utils/common";
import Button, { ButtonGroup } from "../../components/buttons/Button";
import AddBloodpressure from "./addbloodpressure";
import ProgressStepBar from "../../components/progress/ProgressStepBar";
import { useNavigate } from "react-router-dom";

function CustomNoRowsOverlay() {
  return (
    <div className="noData">
      <Text value="NO_DATA"></Text>
    </div>
  );
}

const convertJsonToRows = (x) => {
  return x.map((item) => {
    return {
      id: item.globalId,
      address: `${item.street} ${item.houseNumber} ${item.houseNumberExt ? item.houseNumberExt : ""}, ${item.zipCode}  ${item.city}`,
      description: item.description,
      total: item.products.data.find((product) => product.shipmentGlobalId === item.globalId).quantity || "",
      action: true,
    };
  });
};

const Bloodpressure = () => {
  const { viewModel } = BloodpressureViewModel();

  const [rows, setRows] = useState([]);

  const { configurationProvider } = useContext(ConfigurationContext);
  const { localizationProvider } = useContext(LocalizationContext);
  const navigate = useNavigate();

  const columns = [
    { field: "address", headerName: localizationProvider.getLocalization("ADDRESS"), width: 300, headerClassName: "headerName" },
    { field: "total", headerName: localizationProvider.getLocalization("TOTAL"), flex: 0.3, headerClassName: "headerName" },
    {
      field: "description",
      headerName: localizationProvider.getLocalization("DESCRIPTION"),
      flex: 1,
      minWidth: 300,
      headerClassName: "headerName",
      editable: true,
    },
    {
      field: "action",
      headerName: " ",
      sortable: false,
      filters: false,
      flex: 0.3,
      maxWidth: 50,
      headerClassName: "headerName",
      renderCell: (params) => {
        return (
          <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }} onClick={() => viewModel.deleteShipment(params.row)}>
            {params.row.action ? "x" : ""}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (viewModel.shipments && Array.isArray(viewModel.shipments)) {
      setRows(convertJsonToRows(viewModel.shipments));
    }
  }, [viewModel.shipments]);

  return (
    <FullPage width="true" parentMargin="0 -10%">
      <BloodpressureForm>
        <StyledBlock className="fullsize">
          <div className="form marginTop">
            <ProgressStepBar currentStep={4}></ProgressStepBar>

            <br />
            <h1>
              <Text value="BLOODPRESSURE_TITLE" />
            </h1>
            <h1 className="margin20">
              <Text value="LOCATIONS_BLOODPRESSURE_TITLE" />
            </h1>
            <div className="margin20">
              {" "}
              <Text value="LOCATIONS_BLOODPRESSURE_TEXT" />
            </div>
            <div className="add_location_button margin20">
              <AddBloodpressure addShipment={viewModel.addShipment} icon={configurationProvider.cdn + "client/upload.svg"} error={viewModel.error} success={viewModel.success} />
            </div>
            <div>
              <ThemeProvider theme={fontStyle}>
                <Box
                  bgcolor="white"
                  sx={{
                    "& .headerName": {
                      fontSize: "16px",
                      fontFamily: "Bold",
                    },
                    "& .MuiDataGrid-cell": {
                      whiteSpace: "nowrap",
                    },
                    "@media (max-width: 400px)": {
                      width: "280px",
                      display: "block",
                    },
                  }}
                >
                  <DataGrid
                    rowHeight={40}
                    localeText={localizationProvider.locale === "nl" ? nlNL.components.MuiDataGrid.defaultProps.localeText : {}}
                    rows={rows}
                    columns={columns}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                    }}
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    sx={{ "& .MuiDataGrid-virtualScroller": { height: rows.length ? "100%" : "40px" } }}
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    getRowId={(row) => row.id}
                    disableColumnMenu={true}
                  />
                </Box>
              </ThemeProvider>
            </div>
            <div className="bloodpressure_buttons">
              <ButtonGroup spaceBetween>
                <Button
                  styleType={5}
                  type="button"
                  width="48%"
                  onClick={() => {
                    navigate(viewModel.nextStep());
                  }}
                >
                  <Text value="LATER_CONTINUE" />
                </Button>
                <Button
                  width="48%"
                  onClick={async (event) => {
                    event.preventDefault();
                    viewModel.finish();
                  }}
                >
                  <Text value="NEXT" />
                </Button>
              </ButtonGroup>
            </div>
          </div>
        </StyledBlock>
      </BloodpressureForm>
    </FullPage>
  );
};

export default Bloodpressure;
