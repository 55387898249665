import styled from "styled-components";
import Form from "../../components/containers/Form";

export const SelectedModules = styled.div`
  margin: 80px 10px;
  .selected_modules_title {
    @media (min-width: 768px) {
      padding: 10px 80px;
    }
    color: var(--color-primary);
  }
  .Table {
    box-shadow: 4px 6px 35px #0000001f;
    background: white;
    padding-bottom: 20px;
    .TableRow {
      z-index: 10;
      position: relative;
      align-items: start;
      margin-top: 0px;
      display: flex;
      transition: all 0.2s ease-out;
    }
    .total {
      display: flex;
      justify-content: space-between;
    }
    .TableHead {
      color: white;
      background: var(--color-primary-selected);
      padding: 15px 20px;
    }
    .HeadValue {
      @media (max-width: 768px) {
        div: not(: first-child) {
          writing-mode: vertical-lr;
          padding: 0 15px;
          display: flex;
          align-items: center;
        }
      }
    }
    .TableCell {
      width: 100%;
    }
    .TableBody > .TableRow {
      margin: 10px 20px;

      border-bottom: 1px solid #f3f4f4;
    }
    .TableFooter > .TableRow {
      margin: 20px;
    }
    .TableFooter > :last-child {
      padding: 20px;
    }
    .TableCell:nth-child(1) {
      width: 60%;
    }

    .TableCell:nth-child(2) {
      width: 20%;
      text-align: center;
    }
    .TableCell:nth-child(3) {
      width: 20%;
      text-align: center;
    }
    .TableCell:nth-child(4) {
      width: 20%;
      text-align: center;
    }
  }
`;

export const Price = styled.div`
  display: inline-block;
  vertical-align: middle;
  float: ${(props) => (props.center ? "" : props.left ? "left" : "right")};
  color: var(--color-primary);
  padding: ${(props) => (props.padding ? props.padding : "10px")};
  width: ${(props) => props.width};
  margin: 10px px 0px;
  line-height: 140%;
  div:first-child {
    font-size: ${(props) => (props.size ? props.size : props.small ? "25px" : "30px")};
  }
  div:last-child {
    font-size: 12px;
  }
`;

export const ChooseModules = styled.div`
  background-color: var(--color-primary);
  height: 300px;
  z-index: -1;
  color: white;
  div:first-child {
    padding: 80px;
  }
`;
export const ModulesFrame = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
  justify-content: center;
  height: auto;
  align-items: flex-start;
  margin: -50px 10px;
  @media (max-width: 740px) {
    margin: -50px auto;
  }
  .box {
    width: 300px;
    position: relative;
    background-color: white;
    box-shadow: 4px 6px 35px #0000001f;
    padding: 0px 20px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
`;
export const CompanyForm = styled(Form)`
  background-color: var(--color-primary);
  color: var(--color-white);
  margin: 12px 0 12px 0;
  padding: 32px;
  width: 50%;

  h3 {
    font-size: 20px;
  }
  > div:last-child {
    margin-left: auto;
  }
  .flexInputs {
    @media (min-width: 820px) {
      display: flex;
    }
  }
  @media (max-width: 820px) {
    width: 100%;
  }
`;
export const Background = styled.div`
  position: absolute;
  background-color: var(--color-light-grey);
  padding: 10px;
  margin: 0 -7%;

  width: 120%;
  z-index: -1;
  height: ${(props) => (props.height ? props.height : "560px")};
`;
export const Type = styled.div`
  color: var(--color-font-grey);
  background-color: var(--color-back-grey);
  padding: 10px;
  border-radius: 20px;
  text-align: center;
`;
export const MinDiv = styled.div`
  .center {
    align-self: center;
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.width ? props.width : "80%")};
    margin: 50px auto;
  }
  .content {
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .packages {
    > div {
      background-color: white;
    }
    h3 {
      text-align: center;
      padding: 20px;
      margin: auto;
      height: 60px;
      color: white;
      background-color: var(--color-secondary);
    }

    .highDiv {
      height: auto;
      min-height: 550px;
      .content {
        padding: 30px 10px 10px 10px;
      }
    }
    div:nth-of-type(2) > h3 {
    }
    > div {
      box-shadow: 0 0 30px var(--color-shadow);
      :first-child,
      :last-child {
        margin: 30px 10px;
      }

      .mostChoosen {
        .label {
          position: sticky;
          margin: -20px auto;
          text-align: center;
          width: 80%;
          height: 40px;
          background-color: var(--color-pink);
          padding: 10px;
          border-radius: 40px;
          z-index: 100;
          color: white;
        }
        h3 {
          z-index: 80;
          height: 80px;
          background-color: var(--color-secondary);
          margin: -20px auto;
          padding-top: 40px;
        }
      }
      width: 300px;
      height: auto;
      min-height: 530px;

      @media (min-width: 1000px) {
        margin: 10px;
      }
    }

    margin: auto;
    display: flex;
    flex-direction: row;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }
`;
