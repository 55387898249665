import React, { useContext } from "react";
import styled from "styled-components";

import Text from "../../components/text/Text";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";

import { LocalizationContext } from "../../global/Localization/LocalizationState";

const Footer = () => {
  const { configurationProvider } = useContext(ConfigurationContext);
  const { localizationProvider } = useContext(LocalizationContext);

  return (
    <div>
      {/* <Container small={true}>
        <a href={"mailto:" + localizationProvider.getLocalization("LINK_EMAIL")}>
          <FontAwesome name="envelope" />
          <Text value="FOOTER_MAIL_US" />
        </a>
      </Container> */}
      <Container>
        <div>
          <footer>
            <Content>
              <Main>
                <Cell>
                  <Logo src={configurationProvider.cdn + "icons/nipedlogo.svg"} alt="&amp;NIPED Logo" />
                  <Row>
                    <Logo src={configurationProvider.cdn + "icons/iso27.png"} alt="ISO Logo" />
                    <Logo src={configurationProvider.cdn + "icons/nen75.png"} alt="NEN Logo" />
                  </Row>
                </Cell>

                <Cell>
                  <address>
                    <a href={"tel:" + localizationProvider.getLocalization("LINK_PHONENUMBER")}>
                      <img src={configurationProvider.cdn + "icons/phone_white.svg"} alt="phone" />
                      <Text value="FOOTER_TELEPHONE" />
                    </a>

                    <a href={"mailto:" + localizationProvider.getLocalization("LINK_EMAIL")}>
                      <img src={configurationProvider.cdn + "icons/mail_white.svg"} alt="mail" />
                      <Text value="FOOTER_EMAIL" />
                    </a>
                    <p>
                      <img src={configurationProvider.cdn + "icons/time_white.svg"} alt="time" />
                      <Text value="WORKING_HOURS" />
                    </p>
                    <a href={"tel:" + localizationProvider.getLocalization("LINK_ADDRESS")}>
                      <img src={configurationProvider.cdn + "icons/location_white.svg"} alt="location" />
                      <Text value="FOOTER_ADDRESS" />
                    </a>
                  </address>
                </Cell>
              </Main>
            </Content>
          </footer>
        </div>
      </Container>
    </div>
  );
};

export default Footer;

const Container = styled.div`
  ${(props) => {
    if (props.small) {
      return `
                display: none;
                background-color: var(--color-white);
                -webkit-box-pack: justify;
                justify-content: space-between;
                 position: sticky;
                bottom: 0px;
                right: 0px;
                left: 0px;
                z-index: 1000;
            
                a {
                    width: 100%;
                    background-color: var(--color-primary);
                    display: block;
                    height: 42px;
                    line-height: 42px;
                    text-align: center;
                    color: var(--color-white);
                    text-decoration: none;
                    font-weight: bold;

                    > span {
                        margin-left: 8px;
                    }
                }

                a:link {
                    color: var(--color-white);
                    text-decoration: none;
                }

                a:visited {
                    color: var(--color-white);
                    text-decoration: none;
                }

                a:hover {
                    color: var(--color-white);
                    text-decoration: none;
                }

                a:active {
                    color: var(--color-white);
                    text-decoration: none;
                }
            
                @media only screen and (max-width: 820px) {
                    display: flex;
                }
            `;
    } else {
      return `
                display: flex;
                flex-direction: column;
                padding: 0px 0px;
                left: 0.07%;
                right: -0.07%;
                bottom: 0%;
            
                footer {
                    color: white;
                    background-color: var(--color-primary);
                    overflow: hidden;
                    border-radius: var(--corner-radius-large) var(--corner-radius-large) 0px 0px;
                    padding: 1% 12% 0;
                }
            
                @media only screen and (max-width: 820px) {
                    visibility: collapse;
                    display:none;
                }
            `;
    }
  }};
`;

const Content = styled.div`
  position: relative;
  padding: 16px;
  font-size: 15px;

  a {
    display: inline-block;
    padding: 4px 0px;
    color: white;
    font-size: 1em;
  }

  a span {
    margin: auto;
  }

  @media (min-width: 1201px) {
    margin: 0px auto;
  }

  @media (min-width: 1201px) {
    margin: 0px auto;
  }

  @media (min-width: 1024px) {
    width: 100%;
    margin: 0px auto;
  }

  @media (min-width: 768px) {
    padding: 5px;
  }
`;

const Main = styled.div`
  margin-bottom: 48px;
  display: flex;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-self: stretch;
`;

const Cell = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.isEnd === true ? "flex-end" : "flex-start")};

  > span:first-child {
    opacity: 0.8;
  }

  address {
    a,
    p {
      display: flex;
      img {
        margin-right: 15px;
      }
    }
    color: white;
    margin-top: 20px;
    margin-bottom: 0px;
    font-style: normal;
    line-height: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Logo = styled.img`
  margin: 20px;
  width: ${(props) => (props.small === true ? "100px" : "160px")};
`;

const Row = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  gap: 4px;
`;
