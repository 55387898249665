import { useContext, useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { ModalContext } from "../../global/Modal/ModalState";
import { WebServiceContext } from "../../global/WebService/WebServiceState";
import { UserContext } from "../../global/User/UserState";
import { StorageContext } from "../../global/Storage/StorageState";
import { getUnique, validEmail, validName } from "../../utils/inviteesUtils";
import { getStepURL, validateDate } from "../../components/progress/ProgressStepBar";

const InviteesViewModel = () => {
  const navigate = useNavigate();
  const { clientWebService, error } = useContext(WebServiceContext);
  const { storageProvider } = useContext(StorageContext);
  const { quoteGlobalId } = useParams();
  const [data, setData] = useState([]);

  const [isBusy, setIsBusy] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [success, setSuccess] = useState("");
  const [employees, setEmployees] = useState(storageProvider.employees);
  const [onlyShowIncorrect, setOnlyShowIncorrect] = useState(false);

  const clientGlobalId = storageProvider.project.clientGlobalId;
  const projectGlobalId = storageProvider.project.globalId;
  const configurationSteps = storageProvider.project.configurationSteps.data.sort((a, b) => a.order - b.order) || [];
  const currentOrder = configurationSteps.find((x) => x.name === "Invitees" || x.name === "Genodigden").order;

  useEffect(() => {
    setData(onlyShowIncorrect ? employees.filter((x) => !validEmail(x.email) || !validName(x.firstname) || !validName(x.lastname)) : employees.length ? employees : storageProvider.employees);
  }, [employees, onlyShowIncorrect, storageProvider.employees]);

  const dependancies = {
    isBusy,
    quoteGlobalId,
    clientWebService,
    clientGlobalId,
    projectGlobalId,
  };

  const project = storageProvider.project;

  const nextStep = useCallback(() => {
    return getStepURL(configurationSteps.find((x) => x.order === currentOrder + 1).name);
  }, [configurationSteps, currentOrder]);

  useEffect(() => {
    setShowFilter(employees.some((x) => !validEmail(x.email) || !validName(x.firstname) || !validName(x.lastname)));
  }, [employees]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await clientWebService.getInvitees(quoteGlobalId, clientGlobalId);
     if (result) {
       setEmployees(result);
       storageProvider.setEmployees(result);
     }
    };

    if (!isBusy) {
      setIsBusy(true);
      fetchData().finally(() => setIsBusy(false));
    }
  }, []);

  const toggleShowIncorrect = () => {
    setOnlyShowIncorrect(!onlyShowIncorrect);
  };

  const onUpdate = (old, newObj) => {
    if (!old && !newObj) {
      return false;
    }
    if (newObj.firstname) {
      newObj.email = old.email;
    } else if (newObj.email) {
      newObj.firstname = old.firstname;
    }
    if (newObj.firstname === old.firstname && newObj.email === old.email) {
      return;
    }

    let updatedEmployees = [...employees];
    const index = updatedEmployees.findIndex((x) => x === old);
    updatedEmployees[index] = { ...old, ...newObj };

    setEmployees(updatedEmployees);

    if (validEmail(newObj.email) && validName(newObj.firstname) && !(validEmail(old.email) && validName(old.firstname))) {
      postEmp(updatedEmployees.filter((x) => validEmail(x.email)));
    }
  };

  const onRemove = (employee) => {
    if (!validEmail(employee.email)) {
      setEmployees(employees.filter((x) => x !== employee));
    } else {
      clientWebService.deleteInvitee(employee, () => setEmployees(employees.filter((x) => x !== employee)), project.globalId);
    }
  };

  const postEmp = async (emp) => {
    if (!emp.length) return;
    const response = await clientWebService.postInvitees(
      emp.filter((obj) => delete obj.cell).filter((x) => !x.globalId),
      clientGlobalId,
      projectGlobalId
    );

    if (response) {
      setSuccess("SUCCESS");
    }
  };

  const addEmployee = (rows) => {
    setEmployees(storageProvider.employees);
    const dirtyRows = rows.map((x) => ({ ...x }));
    let updatedEmployees = [...dirtyRows, ...storageProvider.employees];
    updatedEmployees = getUnique(updatedEmployees, "email");
    setEmployees(updatedEmployees);
    postEmp(updatedEmployees.filter((x) => validEmail(x.email)));
  };

  const postInvitees = async () => {
    storageProvider.setEmployees(employees);
  };

  const finish = async () => {
    const steps = storageProvider.project.configurationSteps.data;
    if (validateDate(steps.find((x) => x.name === "Invitees" || x.name === "Genodigden").created)) {
      navigate(nextStep());
      return;
    }

    const response = await clientWebService.finishStep(project.globalId, project.configurationSteps.data.find((x) => x.name === "Invitees" || x.name === "Genodigden").globalId);
    if (response.globalId) {
      const index = steps.findIndex((step) => step.globalId === response.globalId);
      if (index !== -1) {
        steps[index] = response;
      }

      storageProvider.project.configurationSteps.data = steps;
      storageProvider.setProject(storageProvider.project);
      navigate(nextStep());
      setIsBusy(false);
    }
  };

  return {
    viewModel: {
      data,
      error,
      isBusy,
      onUpdate,
      onRemove,
      toggleShowIncorrect,
      onlyShowIncorrect,
      postInvitees,
      addEmployee,
      showFilter,
      success,
      nextStep,
      finish,
    },
  };
};

export default InviteesViewModel;
