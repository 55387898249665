import React, { useState } from "react";
import Page from "../../components/containers/Page";
import Text from "../../components/text/Text";
import Title from "../../components/text/Title";
import { Block } from "../../components/containers/Block";
import Accordion from "../../components/accordion/Accordion";
import Grid from "../../components/containers/Grid";
import Form from "../../components/containers/Form";
import moment from "moment";
import { Child, Overview, Line, Row } from "./offer.components";
import Button, { ButtonGroup } from "../../components/buttons/Button";
import OfferViewModel from "./offer.VM";

const offer = [
  {
    id: 3413,
    client_ID: 1641,
    package_ID: 4,
    createdByUser_ID: 98209,
    code: null,
    created: "2022-09-06T11:51:23.933",
    employeesCount: 22,
    expectedEmployeesCount: 11,
    receiveNotifications: false,
    teamLeaderDeal_ID: null,
    teamLeaderDeal_GlobalID: null,
    teamLeader_GlobalID: null,
    teamLeaderBranch_ID: null,
    teamLeaderBranch_GlobalID: null,
    states: [],
    products: [
      {
        id: 7,
        name: "Opstartkosten",
        description: "Dit zijn de opstartkosten van een project\r\n",
        quantity: 1,
        price: 175,
      },
      {
        id: 11,
        name: "Vragenlijst Persoonlijke Gezondheidscheck \r\n",
        description: "Vragenlijst",
        quantity: 11,
        price: 13,
      },
      {
        id: 12,
        name: "Module werkvermogen",
        description: "Brengt de risico's in kaart die van invloed zijn op de mate waarin medewerkers in staat zijn om hun werk uit te voeren, zowel mentaal als fysiek",
        quantity: 11,
        price: 10,
      },
      {
        id: 13,
        name: "Module Checkbox",
        description: "Checkbox met materialen voor de vingerprik (en eventueel urintetest)\r\n",
        quantity: 11,
        price: 42,
      },
      {
        id: 14,
        name: "Module mentaal welzijn",
        description: "Brengt de risico's op despressie, burn-out en angstklachten in kaart \r\n",
        quantity: 11,
        price: 14,
      },
      {
        id: 15,
        name: "Module nierfunctie",
        description: "Brengt het risico op een verminderde nierfunctie in kaart",
        quantity: 11,
        price: 23,
      },
    ],
  },
  {
    id: 3412,
    client_ID: 1641,
    package_ID: 2,
    createdByUser_ID: 98209,
    code: null,
    created: "2022-09-06T11:49:47.773",
    employeesCount: 22,
    expectedEmployeesCount: 11,
    receiveNotifications: false,
    teamLeaderDeal_ID: null,
    teamLeaderDeal_GlobalID: null,
    teamLeader_GlobalID: null,
    teamLeaderBranch_ID: null,
    teamLeaderBranch_GlobalID: null,
    states: [
      {
        clientQuotation_ID: 3412,
        user_ID: 98209,
        id: 3836,
        name: "Accepted",
        created: "2022-09-06T11:49:54.71",
        revoked: null,
      },
      {
        clientQuotation_ID: 3412,
        user_ID: 98209,
        id: 3837,
        name: "Finished",
        created: "2022-09-06T11:49:58.073",
        revoked: null,
      },
      {
        clientQuotation_ID: 3412,
        user_ID: 98209,
        id: 3838,
        name: "SkippedSupplyInvitees",
        created: "2022-09-06T11:49:58.073",
        revoked: null,
      },
    ],
    products: [
      {
        id: 7,
        name: "Opstartkosten",
        description: "Dit zijn de opstartkosten van een project\r\n",
        quantity: 1,
        price: 175,
      },
      {
        id: 11,
        name: "Vragenlijst Persoonlijke Gezondheidscheck \r\n",
        description: "Vragenlijst",
        quantity: 11,
        price: 13,
      },
      {
        id: 12,
        name: "Module werkvermogen",
        description: "Brengt de risico's in kaart die van invloed zijn op de mate waarin medewerkers in staat zijn om hun werk uit te voeren, zowel mentaal als fysiek",
        quantity: 11,
        price: 10,
      },
      {
        id: 13,
        name: "Module Checkbox",
        description: "Checkbox met materialen voor de vingerprik (en eventueel urintetest)\r\n",
        quantity: 11,
        price: 42,
      },
      {
        id: 14,
        name: "Module mentaal welzijn",
        description: "Brengt de risico's op despressie, burn-out en angstklachten in kaart \r\n",
        quantity: 11,
        price: 14,
      },
    ],
  },
  {
    id: 3411,
    client_ID: 1641,
    package_ID: 4,
    createdByUser_ID: 98209,
    code: null,
    created: "2022-09-06T11:49:18.99",
    employeesCount: 2,
    expectedEmployeesCount: 1,
    receiveNotifications: false,
    teamLeaderDeal_ID: null,
    teamLeaderDeal_GlobalID: null,
    teamLeader_GlobalID: null,
    teamLeaderBranch_ID: null,
    teamLeaderBranch_GlobalID: null,
    states: [
      {
        clientQuotation_ID: 3411,
        user_ID: 98209,
        id: 3833,
        name: "Accepted",
        created: "2022-09-06T11:49:24.747",
        revoked: null,
      },
      {
        clientQuotation_ID: 3411,
        user_ID: 98209,
        id: 3834,
        name: "Finished",
        created: "2022-09-06T11:49:33.38",
        revoked: null,
      },
      {
        clientQuotation_ID: 3411,
        user_ID: 98209,
        id: 3835,
        name: "SkippedSupplyInvitees",
        created: "2022-09-06T11:49:33.38",
        revoked: null,
      },
    ],
    products: [
      {
        id: 7,
        name: "Opstartkosten",
        description: "Dit zijn de opstartkosten van een project\r\n",
        quantity: 1,
        price: 175,
      },
      {
        id: 11,
        name: "Vragenlijst Persoonlijke Gezondheidscheck \r\n",
        description: "Vragenlijst",
        quantity: 1,
        price: 13,
      },
      {
        id: 12,
        name: "Module werkvermogen",
        description: "Brengt de risico's in kaart die van invloed zijn op de mate waarin medewerkers in staat zijn om hun werk uit te voeren, zowel mentaal als fysiek",
        quantity: 1,
        price: 10,
      },
      {
        id: 13,
        name: "Module Checkbox",
        description: "Checkbox met materialen voor de vingerprik (en eventueel urintetest)\r\n",
        quantity: 1,
        price: 42,
      },
      {
        id: 14,
        name: "Module mentaal welzijn",
        description: "Brengt de risico's op despressie, burn-out en angstklachten in kaart \r\n",
        quantity: 1,
        price: 14,
      },
      {
        id: 15,
        name: "Module nierfunctie",
        description: "Brengt het risico op een verminderde nierfunctie in kaart",
        quantity: 1,
        price: 23,
      },
    ],
  },
];

const Offer = (props) => {
  const { viewModel } = OfferViewModel();

  const [state, setState] = useState({
    activeDate: { id: "", year: "" },
    order: { products: [] },
  });

  let dates = [...new Set(offer.map((i) => moment(i.created).format("gggg")))].sort().reverse();

  const renderDates = () => {
    return dates.map((d, i) => (
      <Accordion margin={"auto"} key={"year" + i} defaultOpen={i === 0} title={d} active={state.activeDate.year === d}>
        {offer.map(
          (x) =>
            moment(x.created).format("gggg") === d && (
              <Child
                key={"offer" + x.id}
                onClick={
                  () => viewModel.selectQuote(x)

                  // setState({
                  //   activeDate: { id: x.id, year: d },
                  //   order: offer.filter((a) => a.id === x.id)[0],
                  // })
                }
                active={state.activeDate.id ? state.activeDate.id === x.id : false}
              >
                {moment(x.created).format("DD MMMM")}
              </Child>
            )
        )}
      </Accordion>
    ));
  };
  return (
    <Page width="80%">
      <Block>
        <Grid width="20%" />
        <Grid>
          <div>
            <Title fontSize="40px" color="var(--color-primary)">
              <Text value="OFFER" />
            </Title>
            <Text value={"OFFER"} />
            data
          </div>
        </Grid>
      </Block>
      <Block>
        <Grid width="20%">
          <div className="backgroundGrey">
            {" "}
            <Title fontSize="20px" color="var(--color-primary)">
              <Text value="YEAR" />
            </Title>
            <div className="years">{renderDates()}</div>
          </div>
        </Grid>
        <Grid>
          <Form>
            <Overview>
              {["PRODUCT", "COUNT", "PRICE"].map((x, i) => (
                <Line key={x + i} i={i}>
                  <Text value={x} />
                </Line>
              ))}
              {state.order.products.map((data, index) => (
                <Row key={data.name + index} i={index}>
                  <p> {data.name}</p>
                  <p> {data.quantity}</p>
                  <p>{data.price}</p>
                </Row>
              ))}

              {["TOTAL", "", "100"].map((y, i) => (
                <Line key={y + i} i={i} color="white" textcolor="black">
                  <Text value={y} />
                </Line>
              ))}
            </Overview>
            <ButtonGroup>
              <div className="half"></div>
              <div className=" flex">
                <Button styleType={2} width="49%" onClick={() => viewModel.putQuoteAccept("example")}>
                  <Text value="START_NEW_OFFER" />
                </Button>
                <Button styleType={1} width="49%" onClick={() => viewModel.putQuoteCancel("example")}>
                  <Text value="CANCEL_OFFER" />
                </Button>
              </div>
            </ButtonGroup>
          </Form>
        </Grid>
      </Block>
    </Page>
  );
};

export default Offer;
