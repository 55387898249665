import React, { useContext } from "react";
import styled from "styled-components";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";
import Text from "../text/Text";

const StyledLinkDashboard = styled.div`
  display: flex;
  margin: 10px 0px 10px auto;
  color: ${(props) => (props.color ? props.color : "var(--color-pink)")};
  cursor: pointer;
  ${(props) => props.float}
`;

const StyledContent = styled.div`
  margin: 0 5px;
  ${(props) => props.reverse && `img{transform: rotate(180deg);}`}
`;

export const Back = (props) => {
  const { configurationProvider } = useContext(ConfigurationContext);
  return props.reverse ? (
    <StyledLinkDashboard className="back" onClick={props.onClick} {...props}>
      <StyledContent>
        <Text value={props.name} />
      </StyledContent>
      {!props.hideIcon && (
        <StyledContent reverse>
          <img src={configurationProvider.cdn + "client/back_pink.svg"} alt="back" />
        </StyledContent>
      )}
    </StyledLinkDashboard>
  ) : (
    <StyledLinkDashboard className="back" onClick={props.onClick} {...props}>
      {!props.hideIcon && (
        <StyledContent>
          <img src={configurationProvider.cdn + "client/back_pink.svg"} alt="back" />
        </StyledContent>
      )}
      <StyledContent>
        <Text value={props.name} />
      </StyledContent>
    </StyledLinkDashboard>
  );
};
