import styled from 'styled-components';

import Form from "../../components/containers/Form";

export const StyledBlock = styled.div`
  background-color: var(--color-back-grey);
  position: relative;
  width: 100%;

  .planning {
    margin-bottom: 40px;
  }
  .calendar {
    box-shadow: 3px 3px 15px #0000001a;
    background-color: white;
  }
`;

export const HomeForm = styled(Form)`
  h1 {
    color: var(--color-primary);
  }

  h3 {
    font-size: 20px;
  }

  .form {
    padding: 10px 15%;
  }
  .marginTop {
    margin-top: 10px;
  }
  .data {
    width: 100%;
  }
  .bottomTextBtns {
    margin-top: 20px;
    > div {
      float: right;
      text-align: right;
    }
    width: 100%;
  }
  .calendar {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
  }
  .blur {
    opacity: 0.3;
    filter: blur(4px);
  }
  .centered-text {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
    color: #000;
    text-align: center;
  }
`;

export const HorizontalStatsContainer = styled.div`
 box-sizing: border-box;
 width:100%;
 position:relative;
 :after {
  content: "";
  display: table;
  clear: both;

}
width: ${(props) => props.width}
  padding: 20px;
  .column{ 
    float: left;
  width: 50%;
  padding: 10px;

  @media (max-width: 1024px) {
    width: 100%;
  }
  
  }
  .cat {
    border: 1px solid transparent;
    border-radius: 5px;
    display: flex;
    font-size: 16px;
    align-items: center;
  }

  .pair{
    margin: 20px 0px;
  display:flex;
    .num{
      padding:0 10px;
      font-size:13px;
      font-weight:400;
    }
  }

  .fullWidth{width:100%;    margin-bottom: 14px;color: var(--color-primary)}
  .text {
    width: 30%;
  }
  .navig{
   position: absolute;
    bottom: 0; 
    right: 0;
  }
  .percent{font-family: bold}
`;

export const Progress = styled.div`
  width: ${(props) => props.done / 1.5 + "%"};
  border-radius: 5px;
  position: relative;
  margin: 0 10px;
  height: 10px;
`;

export const ProgressDone = styled.div`
  background: ${(props) => props.color};

  opacity: ${(props) => (props.benchmark ? "0.5" : "")};
  border-radius: 10px;
  height: 13px;
  transition: width 1s ease 0.3s;
`;
export const Block = styled.div`
  min-width: ${(props) => props.minWidth};
  width: ${(props) => (props.width ? props.width : "42%")};
  margin: 10px;
  background-color: white;
  padding: 30px;
  box-shadow: 4px 6px 35px #0000001a;
  display: ${(props) => (props.flex ? "flex" : "")};
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
    display: block;
    margin: 0;
  }
  .padding5 {
    padding: 1% 7%;
  }
`;