import { useContext, useState } from "react";
import { WebServiceContext } from "../../global/WebService/WebServiceState";
import { StorageContext } from "../../global/Storage/StorageState";
import { useNavigate } from "react-router-dom";
import { getStepURL, validateDate } from "../../components/progress/ProgressStepBar";
const WhiteListViewModel = () => {
  const { clientWebService, error } = useContext(WebServiceContext);
  const navigate = useNavigate();
  const { storageProvider } = useContext(StorageContext);
  const [isBusy, setIsBusy] = useState(false);
  const configurationSteps = storageProvider.project.configurationSteps.data.sort((a, b) => a.order - b.order) || [];
  const currentOrder = configurationSteps.find((x) => x.name.includes("Whitelist")).order;

  const project = storageProvider.project;

  function nextStep() {
    return getStepURL(configurationSteps[Number(currentOrder)].name);
  }

  async function finish() {
    const steps = storageProvider.project.configurationSteps.data;
    if (validateDate(steps.find((x) => x.name.toLowerCase().includes("whitelist")).created)) {
      navigate(nextStep());
      return;
    }

    const response = await clientWebService.finishStep(project.globalId, project.configurationSteps.data.find((x) => x.name === "Whitelisten mail" || x.name === "Whitelist mail").globalId);
    if (response.globalId) {
      const index = steps.findIndex((step) => step.globalId === response.globalId);
      if (index !== -1) {
        steps[index] = response;
      }

      storageProvider.project.configurationSteps.data = steps;
      storageProvider.setProject(storageProvider.project);
      navigate(nextStep());
      setIsBusy(false);
      return;
    }
  }

  return {
    viewModel: {
      error: error,
      isBusy: isBusy,
      nextStep: nextStep,
      finish: finish,
    },
  };
};

export default WhiteListViewModel;
