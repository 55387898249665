import { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { WebServiceContext } from "../../global/WebService/WebServiceState";
import { StorageContext } from "../../global/Storage/StorageState";
import { getStepURL, validateDate } from "../../components/progress/ProgressStepBar";

const BloodpressureViewModel = () => {
  const { clientWebService, error } = useContext(WebServiceContext);
  const [isBusy, setIsBusy] = useState(false);
  const navigate = useNavigate();
  const { storageProvider } = useContext(StorageContext);
  const clientGlobalId = storageProvider.project.clientGlobalId;
  const projectGlobalId = storageProvider.project.globalId;

  const configurationSteps = storageProvider.project.configurationSteps.data.sort((a, b) => a.order - b.order) || [];
  const currentOrder = configurationSteps.find((x) => x.name === "Bloeddrukmeters" || x.name === "Bloodmonitors").order;

  const project = storageProvider.project;

  function nextStep() {
    return getStepURL(configurationSteps.find((x) => x.order === currentOrder + 1).name);
  }

  const [dependancies] = useState({
    isBusy: isBusy,
    clientWebService: clientWebService,
    storageProvider: storageProvider,
  });

  useEffect(() => {
    const fetchData = async function (clientWebService, storageProvider) {
      let data = await clientWebService.getShipments(clientGlobalId, projectGlobalId);
      storageProvider.setShipments(data);
    };

    if (!dependancies.isBusy) {
      setIsBusy(true);
      if (!dependancies.storageProvider.shipments || dependancies.storageProvider.shipments.length === 0) fetchData(dependancies.clientWebService, dependancies.storageProvider);
      setIsBusy(false);
    }

    return () => {};
  }, [dependancies, clientGlobalId, projectGlobalId]);

  const addShipment = async (firstName, middleName, lastName, street, houseNumber, houseNumberExt, zipCode, city, country, description, amount) => {
    setIsBusy(true);
    const response = await clientWebService.newShipment(clientGlobalId, projectGlobalId, {
      firstName,
      middleName,
      lastName,
      street,
      houseNumber,
      houseNumberExt,
      zipCode,
      city,
      country,
      description,
      amount,
    });
    if (response) {
      const newValue = [...storageProvider.shipments, ...[response]];
      storageProvider.setShipments(newValue);
    }
    setIsBusy(false);
  };

  const delShipment = async (params) => {
    setIsBusy(true);
    const response = await clientWebService.deleteShipment(clientGlobalId, projectGlobalId, params.id);
    if (response) {
      const newValue = storageProvider.shipments.filter((x) => x.globalId !== params.id);
      storageProvider.setShipments(newValue);
    }

    setIsBusy(false);
  };
  async function finish() {
    const steps = storageProvider.project.configurationSteps.data;
    if (validateDate(steps.find((x) => x.name === "Bloeddrukmeters" || x.name === "Bloodmonitors").created)) {
      navigate(nextStep());
      return;
    }

    const response = await clientWebService.finishStep(project.globalId, project.configurationSteps.data.find((x) => x.name === "Bloeddrukmeters" || x.name === "Bloodmonitors").globalId);
    if (response.globalId) {
      const steps = storageProvider.project.configurationSteps.data;
      const index = steps.findIndex((step) => step.globalId === response.globalId);
      if (index !== -1) {
        steps[index] = response;
      }

      storageProvider.project.configurationSteps.data = steps;
      storageProvider.setProject(storageProvider.project);
      navigate(nextStep());

      setIsBusy(false);
      return;
    }
  }
  return {
    viewModel: {
      error: error,
      isBusy: isBusy,
      project: storageProvider.project,
      shipments: storageProvider.shipments,
      addShipment: addShipment,
      deleteShipment: delShipment,
      nextStep: nextStep,
      finish: finish,
    },
  };
};

export default BloodpressureViewModel;
