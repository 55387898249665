import React, { useRef } from "react";
import styled from "styled-components";
import { Blue, Secondary, Tertiary, White } from "../../core/enums/ButtonType";
import Text from "../text/Text";
import { SvgIcon } from "../svgIcon/svgIcon";

const Button = styled.button`
  ${(props) => {
    switch (props.styleType) {
      case Secondary:
        return `background-color: var(--color-button-secondary);
                        border: 1px solid;
                        border-color: var(--color-button-secondary);
                        color: var(--color-white);`;
      case Tertiary:
        return `background-color: var(--color-black);
                        border: 0px;
                        color: var(--color-white);`;
      case Blue:
        return `background-color: var(--color-primary-selected);
                    
                        border: 0px;
                        color: var(--color-white);`;
      case White:
        return `background-color: var(--rt-color-white);
                     border: 1px solid var(--color-button-primary);
                        color: var(--color-button-primary);`;
      case "Pink":
        return `background-color: var(--color-pink);
                     border: 1px solid var(--color-pink);
                        color: var(--rt-color-white);`;
      case "notActive":
        return `background-color: transparent;
                        border: 1px solid var(--color-button-primary);
                                                color: var(--color-button-primary);
                                                font-weight:bold;
                                                cursor: not-allowed;
`;
      default:
        return `background-color: var(--color-button-primary);
                        border: 1px solid;
                        border-color: var(--color-button-primary);
                        color: var(--color-white);`;
    }
  }}
  font-family: 'Bold';
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  height: ${(props) => (props.height ? props.height : "50px")};
  mix-blend-mode: normal;
  overflow: hidden;
  width: ${(props) => (props.width ? props.width : "100%")};
  cursor: pointer;
  margin: 10px 0px;
  ${(props) => (props.marginRight ? `margin-right:auto` : "margin-left: auto;")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "bold")};
  font-size: 16px;
  display: block;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 0 10px;
  position: static;
  border-radius: var(--corner-radius-small);
  align-self: stretch;
  -webkit-box-flex: 0;
  flex-grow: 0;
  ${(props) =>
    props.selected &&
    props.styleType !== "notActive" &&
    `background-color: var(--color-white);
                            color: var(--color-pink);`}
  :hover {
    ${(props) => {
      switch (props.styleType) {
        case Secondary:
          return `background-color: var(--color-button-secondary-selected);
                            color: var(--color-button-secondary);`;
        case Tertiary:
          return `background-color: var(--color-black-selected);`;

        case Blue:
          return `background-color: var(--color-primary-selected);
                  color: var(--color-white);
                  border: 1px solid;
                  border-color: var(--color-white);`;
        case "notActive":
          return `background-color: transparent; color: var(--color-button-primary)`;
        default:
          return `background-color: var(--color-white);
                            color: var(--color-button-primary);`;
      }
    }}
  }
  span {
    margin: auto;
  }
  .button {
    justify-content: space-between;
    width: 100%;
  }
  @media (max-width: 400px) {
    width: 100%;
  }
`;

export default Button;

export const ButtonPlus = (props) => {
  return (
    <Button {...props}>
      <div className="flex button">
        <Text value={props.selected ? "DELETE" : "ADD"}></Text>
        {!props.selected ? (
          <SvgIcon>
            <svg className="svg-plus" viewBox="0 0 100 100">
              <line x1="32.5" y1="50" x2="67.5" y2="50" strokeWidth="5"></line>
              <line x1="50" y1="32.5" x2="50" y2="67.5" strokeWidth="5"></line>
            </svg>
          </SvgIcon>
        ) : (
          <SvgIcon>
            <svg className="trash" viewBox="0 0 110.61 122.88">
              <path d="M39.27,58.64a4.74,4.74,0,1,1,9.47,0V93.72a4.74,4.74,0,1,1-9.47,0V58.64Zm63.6-19.86L98,103a22.29,22.29,0,0,1-6.33,14.1,19.41,19.41,0,0,1-13.88,5.78h-45a19.4,19.4,0,0,1-13.86-5.78l0,0A22.31,22.31,0,0,1,12.59,103L7.74,38.78H0V25c0-3.32,1.63-4.58,4.84-4.58H27.58V10.79A10.82,10.82,0,0,1,38.37,0H72.24A10.82,10.82,0,0,1,83,10.79v9.62h23.35a6.19,6.19,0,0,1,1,.06A3.86,3.86,0,0,1,110.59,24c0,.2,0,.38,0,.57V38.78Zm-9.5.17H17.24L22,102.3a12.82,12.82,0,0,0,3.57,8.1l0,0a10,10,0,0,0,7.19,3h45a10.06,10.06,0,0,0,7.19-3,12.8,12.8,0,0,0,3.59-8.1L93.37,39ZM71,20.41V12.05H39.64v8.36ZM61.87,58.64a4.74,4.74,0,1,1,9.47,0V93.72a4.74,4.74,0,1,1-9.47,0V58.64Z" />
            </svg>
          </SvgIcon>
        )}
      </div>
    </Button>
  );
};

export const ButtonImage = (props) => {
  return (
    <Button {...props}>
      <div className="flex button">
        <Text value={props.name}></Text>
        {props.download ? (
          <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" className="download" width="21.673" height="18.916" viewBox="0 0 21.673 18.916">
              <g id="noun-upload-1016073-FFFFFF" transform="translate(-28.794 -102.002)">
                <path
                  id="Path_193"
                  data-name="Path 193"
                  d="M49.146,692.4a1.323,1.323,0,0,0-1.322,1.322v3.76H31.438v-3.76a1.322,1.322,0,1,0-2.644,0v5.059a1.323,1.323,0,0,0,1.322,1.322h19.03a1.323,1.323,0,0,0,1.322-1.322v-5.059a1.323,1.323,0,0,0-1.322-1.322Z"
                  transform="translate(0 -579.185)"
                  fill="#fff"
                />
                <path
                  id="Path_194"
                  data-name="Path 194"
                  d="M328.321,108.178l1.664-1.664v8.341a1.322,1.322,0,1,0,2.644,0v-8.387l1.709,1.709a1.321,1.321,0,1,0,1.869-1.869l-3.92-3.92a1.321,1.321,0,0,0-1.869,0l-3.92,3.92a1.307,1.307,0,0,0,0,1.869,1.253,1.253,0,0,0,1.823,0Z"
                  transform="translate(-291.664)"
                  fill="#fff"
                />
              </g>
            </svg>
          </SvgIcon>
        ) : (
          ""
        )}
      </div>
    </Button>
  );
};

export const ButtonGroup = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : "100%")};
  align-items: center;
  justify-content: ${(props) => (props.spaceBetween ? "space-between" : "center")};
  margin: ${(props) => (props.margin ? props.margin : "20px 0")};
  .half {
    width: 50%;
  }
  @media (max-width: 500px) {
    display: block;
  }
`;
const StyledUploadButton = styled.div`
  margin-bottom: 10px;
  display: flex;
  cursor: pointer;

  > span:first-child {
    padding: 0 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px dashed #c1c1c1;
    border-radius: 5px 0px 0px 5px;
    opacity: 1;
    text-align: center;
    line-height: 50px;
    minheight: 50px;
    // width: 300px;
  }
  .label {
    color: white;
    background: #0060a8 0% 0% no-repeat padding-box;
    border-radius: 0px 5px 5px 0px;
    opacity: 1;
    minheight: 50px;
    text-align: center;
    padding: 0 20px;
    line-height: 50px;
    margin-left: -2px;
    width: 250px;
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }
  .button {
    display: flex;
    justify-content: space-between;
  }
`;

// export const UploadButton = (props) => {
//   const actualBtn = document.getElementById("actual-btn");

//   const fileChosen = document.getElementById("file-chosen");

//   actualBtn.addEventListener("change", function () {
//     fileChosen.textContent = this.files[0].name;
//   });
//   return (
//     <StyledUploadButton>
//       <span id="file-chosen">No file chosen</span>
//       <input type="file" id="actual-btn" hidden />

//       <label for="actual-btn">Choose File</label>
//     </StyledUploadButton>
//   );
// };

export const UploadButton = (props) => {
  const actualBtn = useRef(null);
  const fileChosen = useRef(null);

  const handleChange = (e) => {
    fileChosen.current.textContent = actualBtn.current.files[0].name;
    props.onChange(e);
  };

  return (
    <StyledUploadButton>
      <span ref={fileChosen}>
        <label htmlFor="uploadFile">
          {" "}
          <Text color="#012856" underline value="NO_VALUE_CHOSEN" />
        </label>{" "}
      </span>

      <input accept=".xlsx" type="file" id="uploadFile" hidden ref={actualBtn} onChange={handleChange} />
      <label htmlFor="uploadFile" className="button label">
        <Text value="UPLOAD_EXCEL" />{" "}
        <SvgIcon>
          <svg xmlns="http://www.w3.org/2000/svg" width="21.673" height="18.916" viewBox="0 0 21.673 18.916">
            <g id="noun-upload-1016073-FFFFFF" transform="translate(-28.794 -102.002)">
              <path
                id="Path_193"
                data-name="Path 193"
                d="M49.146,692.4a1.323,1.323,0,0,0-1.322,1.322v3.76H31.438v-3.76a1.322,1.322,0,1,0-2.644,0v5.059a1.323,1.323,0,0,0,1.322,1.322h19.03a1.323,1.323,0,0,0,1.322-1.322v-5.059a1.323,1.323,0,0,0-1.322-1.322Z"
                transform="translate(0 -579.185)"
                fill="#fff"
              />
              <path
                id="Path_194"
                data-name="Path 194"
                d="M328.321,108.178l1.664-1.664v8.341a1.322,1.322,0,1,0,2.644,0v-8.387l1.709,1.709a1.321,1.321,0,1,0,1.869-1.869l-3.92-3.92a1.321,1.321,0,0,0-1.869,0l-3.92,3.92a1.307,1.307,0,0,0,0,1.869,1.253,1.253,0,0,0,1.823,0Z"
                transform="translate(-291.664)"
                fill="#fff"
              />
            </g>
          </svg>
        </SvgIcon>
      </label>
    </StyledUploadButton>
  );
};
