import React from "react";
import Error from "../../components/containers//Error";
import Page from "../../components/containers//Page";
import FlexBox from "../../components/containers//FlexBox";
import MarginBox from "../../components/containers//MarginBox";
import { useNavigate } from "react-router-dom";
import RegistrationViewModel from "./Registration.VM";

import { RegistrationForm } from "./Registration.Components";
import Intro from "../../components/intro/Intro";
import Text from "../../components/text/Text";
import Input from "../../components/inputs/Input";
import Button from "../../components/buttons/Button";
import { Back } from "../../components/back/back";

const Registration = () => {
  const { viewModel } = RegistrationViewModel();

let navigate = useNavigate();
return (
  <Page>
    {" "}
    <FlexBox gap={"0px"}>
      <Intro title="WELCOME_CLIENT_PORTAL_TITLE" text="WELCOME_CLIENT_PORTAL_TEXT" subText={["BENEFIT_1", "BENEFIT_2", "BENEFIT_3"]} />

      <RegistrationForm
        onSubmit={async (event) => {
          event.preventDefault();
          viewModel.sendEmails();
        }}
      >
        <h1>
          <Text value="MAKE_ACCOUNT_TITLE" />
        </h1>

        <MarginBox bottom={"20px"}>
          <Text value={viewModel.success ? "SUCCESS_SEND_EMAILS" : "MAKE_ACCOUNT_SUBTEXT"} />
        </MarginBox>

        {!viewModel.success && (
          <div>
            <Input
              placeholder="EMAIL"
              type="email"
              onChange={(e) => {
                if (!viewModel.isBusy) viewModel.setEmail(e.target.value);
                viewModel.setEmailError(false);
                viewModel.setEmailMismatch(false);
              }}
              error={viewModel.emailError}
            />
            <Input
              placeholder="REPEAT_EMAIL"
              type="email"
              onChange={(e) => {
                if (!viewModel.isBusy) viewModel.setRepeatEmail(e.target.value);
                viewModel.setEmailMismatch(false);
              }}
              error={viewModel.emailMismatch}
            />
            <Error value={viewModel.error} />

            <Button width="38%">
              <Text value="NEXT" />
            </Button>
          </div>
        )}

        <Back color="white" name={viewModel.success ? "BACK" : "GO_TO_LOGIN"} onClick={() => (viewModel.success ? viewModel.setMessage(false) : navigate("/login"))} />
      </RegistrationForm>
    </FlexBox>
  </Page>
);
};

export default Registration;
