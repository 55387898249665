import styled from 'styled-components';

const ButtonRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: ${props => props.$width ? props.$width : "100%"};
  float: ${props => props.$width ? "right" : ""};
  gap: 20px;

  @media (min-width: 720px) {
    button:first-child {
      width: 150px;
    }
  }

  @media (max-width: 720px) {
    flex-flow: column;
    gap: 0;
  }
  @media (max-width: 480px) {
    display: block;
  }
`;

export default ButtonRow;