import styled from "styled-components";
import Text from "../text/Text";
import Subtitle from "../text/Subtitle";
import Accordion from "../accordion/Accordion";

export const AccordionTable = (data) => {
    let titles = {
        green: getTableFieldValue(data.data, "greentitle"),
        orange: getTableFieldValue(data.data, "orangetitle")
    };

    return [
        <Subtitle key={"AccordionTableTitle"}>
            <Text text={getTableFieldValue(data.data, "text")} />
        </Subtitle>,
        <Table key={"AccordionTable"}>
            {[...Array(7)].map((ri, r) => {
                let title = getTableFieldValue(data.data, "itemtitle" + (r + 1));
                let orange = getTableFieldValue(data.data, "itemorangetext" + (r + 1));
                let green = getTableFieldValue(data.data, "itemgreentext" + (r + 1));
                let elements = [
                    {
                        type: 1,
                        value: (
                            <Row key={"h" + r}>
                                <InfoCell
                                    key={"green_h" + r}
                                    width={"50%"}
                                    backgroundColor={"#19da41"}   
                                    color={"var(--color-white)"}
                                    borderWidth="0 1px 1px 0"
                                >
                                    <Text text={titles.green} />
                                </InfoCell>
                                <InfoCell
                                    key={"orange_h" + r}
                                    width={"50%"}
                                    borderWidth="0 0 1px 0"
                                    backgroundColor={"#fe9801"}
                                    color={"var(--color-white)"}
                                >
                                    <Text text={titles.orange} />
                                </InfoCell>
                            </Row>
                        )
                    },
                    {
                        type: 1,
                        value: (
                            <Row key={r}>
                                <Cell
                                    key={"green_" + r}
                                    width={"50%"}
                                    backgroundColor={"#d1f8d9"}
                                    borderWidth="0 1px 0 0"
                                >
                                    <Text text={green} />
                                </Cell>
                                <Cell
                                    key={"orange_" + r}
                                    width={"50%"}
                                    backgroundColor={"#fff0d7"}
                                    borderWidth="0 0 0 0"
                                >
                                    <Text text={orange} />
                                </Cell>
                            </Row>
                        )
                    }
                ];
                return (
                    title &&
                    (orange || green) && (
                        <Accordion
                            key={r}
                            title={title}
                            backgroundColor={"var(--color-medium-grey)"}
                            elements={elements}
                            contentPadding={"0px"}
                        />
                    )
                );
            })}
        </Table>
    ];
}

export const DataTable = (data) => {
    let mapping = {
        0: "rowinfo1_",
        1: "rowtitle1_",
        2: "rowdata1_",
        3: "rowdata2_",
        4: "rowdata3_",
        5: "rowdata4_",
        6: "rowdata5_"
    };

    return [
        <Subtitle key={"DataTableTitle"}>
            <Text text={getTableFieldValue(data.data, "text")} />
        </Subtitle>,
        <Table key={"DataTable"}>
            {[...Array(7)].map((ri, r) => {
                return (
                    <Row key={r}>
                        {[...Array(getTableFieldValueAsInt(data.data, "columncount"))].map((ci, c) => {
                            switch (r) {
                                case 0:
                                    return (
                                        <InfoCell key={c} 
                                                  color={c === 0 ? "var(--color-black)" : null} 
                                                  backgroundColor={c === 0 ? "var(--color-white)" : null}>
                                            <Text text={getTableFieldValue(data.data, mapping[r] + (c + 1))} />
                                        </InfoCell>
                                    );
                                case 1:
                                    return (
                                        <TitleCell key={c}>
                                            <Text text={getTableFieldValue(data.data, mapping[r] + (c + 1))} />
                                        </TitleCell>
                                    );
                                default:
                                    return (
                                        <Cell key={c} 
                                              backgroundColor={r % 2 === 0 ? "var(--color-white)" : "var(--color-light-grey)"} >
                                            <Text text={getTableFieldValue(data.data, mapping[r] + (c + 1))} />
                                        </Cell>
                                    );
                            }
                        })}
                    </Row>
                );}
            )}
        </Table>
    ];
}

function getTableFieldValue(data, name) {
    if (!data || !data.Fields) return "";
    let fields = data.Fields.filter(f => f.Name === name);
    if (!fields || fields.length === 0 || !fields[0].Field) return "";
    return fields[0].Field.Value;
};

function getTableFieldValueAsInt(data, name) {
    let value = getTableFieldValue(data, name);
    if (!value) return 0;
    return parseInt(value, 10);
};

const Table = styled.div`
    margin: 15px 0;
    display: table;
    width: 100%;
    table-layout: fixed;
`;
const Row = styled.div`
    margin: 15px 0;
    display: table-row;
`;

const Cell = styled.div`
    margin: 15px 0;
    padding: 6px 10px;
    display: table-cell;
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : "var(--color-white)")};
    color: ${props => (props.color ? props.color : "var(--color-black)")};
    width: ${props => (props.width ? props.width : null)};
    border: var(--color-white) solid;
    border-width: ${props => (props.borderWidth ? props.borderWidth : "0 1px 1px 0")};
    vertical-align: middle;
    text-align: center;
`;

const InfoCell = styled(Cell)`
    font-weight: bold;
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : "var(--color-primary)")};
    color: ${props => (props.color ? props.color : "var(--color-white)")};
`;

const TitleCell = styled(Cell)`
    background-color: var(--color-grey);
    color: ${props => (props.color ? props.color : "var(--color-white)")};
`;
