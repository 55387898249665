import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import Text from "../../components/text/Text";
import Page from "../../components/containers/Page";
import DashboardViewModel from "./Dashboard.VM";
import { SectionBadge, Arrow, StyledBlock, InsideBlock, StatisticBlock, B, HorizontalStatsContainer, TitleBlock } from "./Dashboard.Components";
import { AppContext } from "../../global/App/AppState";
import HomeIcon from "../../components/icons/Home";
import Title from "../../components/text/Title";
import { Block } from "../../components/containers/Block";
import DoughnutChart from "../../components/donut/donut";
import Carousel, { getIcon, shiftLeft, shiftRight } from "../../components/carousel/carousel";
import StyledLineData from "../../components/linedata/linedata";
import Grid from "../../components/containers/Grid";

const LineData = [
  {
    id: 1,
    name: "Genodingen",
    percent: 68,
    count: 580,
  },
  {
    id: 2,
    name: "Registraties",
    percent: 13,
    count: 190,
  },
  {
    id: 3,
    name: "Algerond",
    percent: 9,
    count: 30,
  },
];
const UserData = [
  {
    id: 1,
    year: 2016,
    userGain: 80000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345,
  },
  {
    id: 3,
    year: 2018,
    userGain: 78888,
    userLost: 555,
  },
  {
    id: 4,
    year: 2019,
    userGain: 90000,
    userLost: 4555,
  },
];
const Gender = [
  { label: "Female", percent: 56 },
  { label: "Male", percent: 44 },
];

const AgeGroup = [
  {
    id: 1,
    label: "18-30",
    percent: 28,
  },
  {
    id: 2,
    label: "31-40",
    percent: 33,
  },
  {
    id: 3,
    label: "41-50",
    percent: 20,
  },
  {
    id: 4,
    label: "50+",
    percent: 19,
  },
];
const Education = [
  {
    id: 1,
    label: "HBO/WO/Postdoctoraal",
    percent: 28,
  },
  {
    id: 2,
    label: "MBO/HAVO/VWO",
    percent: 33,
  },
  {
    id: 3,
    label: "Lager onderwijs/LBO/MAVO",
    percent: 20,
  },
  {
    id: 4,
    label: "Anders",
    percent: 19,
  },
];
const Naslagwerk = [
  {
    id: 1,
    name: "Meetwaarden",
    text: "Goede eetgewoonten zijn van belang om gezond te blijven. Door gezond gedrag te belonen of gezamenlijk aan leefstijlfactoren te werken",
  },
  {
    id: 2,
    name: "Referenties",
    text: "Goede eetgewoonten zijn van belang om gezond te blijven. Door gezond gedrag te belonen of gezamenlijk aan leefstijlfactoren te werken",
  },
  {
    id: 3,
    name: "Risicocriteria",
    text: "Goede eetgewoonten zijn van belang om gezond te blijven. Door gezond gedrag te belonen of gezamenlijk aan leefstijlfactoren te werken",
  },
  {
    id: 4,
    name: "Meetinstrumenten",
    text: "Goede eetgewoonten zijn van belang om gezond te blijven. Door gezond gedrag te belonen of gezamenlijk aan leefstijlfactoren te werken",
  },
];
const elements = (
  <div className="cards__container">
    {Naslagwerk.map((x, key) => (
      <div className="box" key={"box" + key}>
        <div className="boxes">
          <div className="icon"> {getIcon(x.name)} </div>
          <div>
            <div className="name"> {x.name} </div>
            <div className="description"> {x.text} </div>
          </div>
        </div>
      </div>
    ))}
  </div>
);

const Dashboard = (props) => {
  const { appProvider } = useContext(AppContext);
  const { viewModel } = DashboardViewModel();
  const navigate = useNavigate();
  const [state] = useState({
    projects: [],
    gender: {
      labels: Gender.map((data) => data.label),
      datasets: {
        label: "Gender",
        data: Gender.map((data) => data.percent),
      },
    },
    ageGroup: {
      labels: AgeGroup.map((data) => data.label),
      datasets: {
        label: "ageGroup",
        data: AgeGroup.map((data) => data.percent),
      },
    },
    education: {
      labels: Education.map((data) => data.label),
      datasets: {
        label: "education",
        data: Education.map((data) => data.percent),
      },
    },
  });
  //  let dates = [...new Set(state.projects.map((i) => moment(i.periodFrom).format("gggg")))].sort().reverse();
  let dates = [2022, 2021, 2020];
  const fetchStat = (id) => {
    this.props.fetchDatabyId(this.props.webapis, id);
  };

  return (
    <Page width="80%" minPadding="50px -23% 0px -23%">
      <div className="title">
        <Title>
          <Text value="DASHBOARD" />
        </Title>
        <Text value={"DASHBOARD_TEXT"}></Text>
      </div>
      <Block>
        <Grid margin="10px 80px">
          <StyledBlock>
            <InsideBlock>
              <SectionBadge badge>
                <div>
                  <HomeIcon index={0} color={"var(--color-button-primary)"} />
                </div>
                <div>
                  <Text value={"Participation"}></Text>
                </div>
              </SectionBadge>
            </InsideBlock>
            <StatisticBlock top="25px" marginLeft="15%">
              <B flex width="50%">
                <HorizontalStatsContainer className="padding5">
                  <StyledLineData colors={["var(--color-primary)", "var(--color-secondary)", "var(--color-pink)"]} data={LineData} />
                </HorizontalStatsContainer>
              </B>
              <B width="35%">
                <TitleBlock>
                  <Text value="Gender" />
                </TitleBlock>
                <DoughnutChart chartData={state.gender} />
              </B>
            </StatisticBlock>
            <br />
            <StatisticBlock top="300px" marginLeft="3%">
              <B width="36%">
                <TitleBlock>
                  <Text value="Age" />
                </TitleBlock>
                <DoughnutChart chartData={state.ageGroup} />
              </B>
              <B width="53%">
                <TitleBlock>
                  <Text value="Education" />
                </TitleBlock>
                <DoughnutChart chartData={state.education} />
              </B>
            </StatisticBlock>
          </StyledBlock>
          {/* commented for now, will be changed after new design will be ready part2 */}
          {/* <StyledBlock>
            <InsideBlock>
              {" "}
              <SectionBadge badge>
                <div>
                  <HomeIcon index={1} color={"var(--color-button-primary)"} />
                </div>
                <div>
                  <Text value={"Aandachtpunten"}></Text>
                </div>
              </SectionBadge>
            </InsideBlock>
            <StatisticBlock top="25px" marginLeft="15%" marginTop="30%">
              <B width="87%">
                <TitleBlock>
                  <Text value="For work" />
                </TitleBlock>

                <Text value="For work" />
                <HorizontalStatsContainer>
                  <div className="column">
                    <StyledLineData colors={["var(--color-report-orange)"]} benchmark data={LineData} />
                  </div>
                  <div className="column">
                    <StyledLineData colors={["var(--color-report-orange)"]} benchmark data={LineData} />
                  </div>
                  <div className="navig">
                    <Arrow></Arrow>
                  </div>
                </HorizontalStatsContainer>
              </B>
            </StatisticBlock>
            <br />
            <StatisticBlock top="400px" marginLeft="15%">
              <B>
                {" "}
                <HorizontalStatsContainer>
                  <TitleBlock>
                    <Text value="For work" />
                  </TitleBlock>
                  <Text value="For work" />
                  <StyledLineData colors={["var(--color-report-orange)"]} benchmark data={LineData} />
                  <div className="navig">
                    <Arrow></Arrow>
                  </div>
                </HorizontalStatsContainer>
              </B>
              <B>
                {" "}
                <HorizontalStatsContainer>
                  <TitleBlock>
                    <Text value="For work" />
                  </TitleBlock>

                  <Text value="For work" />
                  <StyledLineData colors={["var(--color-report-green)"]} benchmark data={LineData} />
                </HorizontalStatsContainer>
              </B>
            </StatisticBlock>
          </StyledBlock>

          {viewModel.sections.map((d, i) => (
            <SectionBadge
              key={d.route + i}
              textSize={appProvider.textSize}
              onClick={(r) => {
                viewModel.onNavigate(d.route);
              }}
            >
              <div>
                <DashboardIcon index={d.index} color={"var(--color-button-primary)"} />
              </div>
              <div>
                <Text value={d.title}></Text>
                <Arrow></Arrow>
              </div>
            </SectionBadge>
          ))} */}
        </Grid>
      </Block>{" "}
      <StyledBlock className="fullsize">
        <div className="flex">
          <Grid minWidth="18%"></Grid>
          <Grid>
            <div>
              <Title>
                <Text value="foot_Title" />
              </Title>
              <div className="footer_text">
                <Text value={"Foot_TEXT"}></Text>
                <div>
                  <Arrow big left onClick={() => shiftLeft()}></Arrow>
                  <Arrow big onClick={() => shiftRight()}></Arrow>
                </div>
              </div>{" "}
              <Carousel elements={elements} />
            </div>
          </Grid>
        </div>
      </StyledBlock>
    </Page>
  );
};

export default Dashboard;
