import React, {useState} from "react";

import jwt_decode from "jwt-decode";

export const UserContext = React.createContext();

export const UserProvider = ({children}) => {

    const [roles, setRoles] = useState([]);

    const provider = {
        userProvider: {
            roles: roles,
            initRoles: initRoles,
            containsRole: containsRole,
            containsRoles: containsRoles,
            clear: clear
        }
    };

    function initRoles(jwt) {
      if (!jwt.access_token) return false;

      let jwtRoles = jwt_decode(jwt.access_token)["role"];

      if (!jwtRoles || jwtRoles.length === 0) return false;

      setRoles(jwtRoles);

      return true;
    }

    // Checks if the logged in user has at least one of the roles from the 'roleList'. 
    function containsRole(roleList) {
        if(!roles) return false;

        for(let i = 0; i < roleList.length; i++) {
            if(roles.indexOf(roleList[i]) > -1) return true;
        }

        return false;
    }

    // Checks if the logged in user has all the roles in the 'roleList'. 
    function containsRoles(roleList) {
        if(!roles) return false;

        for(let i = 0; i < roleList.length; i++) {
            if(roles.indexOf(roleList[i]) <= 0) return false;
        }

        return true;
    }

    function clear() {
        setRoles([]);
    }

    return (
        <UserContext.Provider value={provider}>
            {children}
        </UserContext.Provider>
    )
};