import React, { useState } from "react";
import styled from "styled-components";

const Progress = styled.div`
  margin: 20px;
  height: 80px;
`;
const ProgressWrapper = styled.div`
  background: rgb(233 233 233);
  border-radius: 100px;
  height: 6px;
  width: 100%;
  margin: auto;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1) inset;
  width: 80%;

  .progress-bar {
    background: #2e1098;
    height: 4px;
  }

  .progress-bar-width {
    border-radius: 100%;
    height: 6px;
    width: ${(props) => props.width}%;
  }

  .progress-bar-anim {
    background: ${(props) =>
      props.width < 30
        ? "rgba(1, 40, 86, 1)"
        : props.width < 70
        ? "linear-gradient(90deg, rgba(1, 40, 86, 1) 30%, rgba(39, 116, 174, 1) 60%)"
        : "linear-gradient(90deg, rgba(1, 40, 86, 1) 30%, rgba(39, 116, 174, 1) 50%, rgba(231, 51, 111, 1) 100%)"};

    border-radius: 10px;
    height: inherit;
    width: 100%;
  }
  .stepper-item {
    width: 33.33%;
    .step-name {
      @media (max-width: 550px) {
        writing-mode: vertical-lr;
        height: 10px;
        margin: auto;
      }
    }
  }
  .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .aa {
    width: 98%;
    margin-top: -12px;
    .stepper-item:first-child > div:last-child {
      @media (max-width: 550px) {
        margin: 0;
      }
      
    } 
    .stepper-item:last-child > div:last-child {
      @media (max-width: 550px) {
        float: right;
      }
  }
  .center0 {
    margin-left: 10px;
    float: left;
    .step-counter {
      background: rgba(1, 40, 86, 1);
    }
  }
  .center1 {
    text-align: center;
    .step-counter {
      background: ${(props) => (props.width > 49 ? "rgba(39, 116, 174, 1)" : "rgb(233 233 233)")};
    }
    div:first-child {
      margin: auto;
    }
  }
  .center2 {
    text-align: right;
    .step-counter {
      float: right;
      background: ${(props) => (props.width > 90 ? "rgba(231, 51, 111, 1)" : "rgb(233 233 233)")};
    }
    .step-name {
      margin-top: 20px;
    }
  }
`;

const Wizard = (props) => {
  return (
    <Progress>
      <ProgressWrapper width={props.done}>
        <div className="progress-bar progress-bar-width">
          <div className="progress-bar-anim"></div>
        </div>
        <div className="flex aa">
          {" "}
          {props.textSteps.map((x, i) => (
            <div className={"stepper-item center" + i} key={x + i}>
              <div className={"step-counter"}></div>
              <br />
              <div className="step-name">{x}</div>
            </div>
          ))}
        </div>
      </ProgressWrapper>
    </Progress>
  );
};

export default Wizard;
