import styled from "styled-components";

import Form from "../../components/containers/Form";

export const FinishPageForm = styled(Form)`
  .bottomText {
    margin: 20px 0;
  }
  .overview {
    height: auto;
    padding: 10px 20%;
    margin-top: -100px;
    z-index: 99;
  }
  .finalOverview {
    margin-top: 20px;
    background: white;
    padding: 50px 70px;
    box-shadow: 4px 6px 35px #0000001f;
    @media (max-width: 400px) {
      padding: 10px 20px;
    }
  }

  .three-columns {
    @media (max-width: 768px) {
      display: block;
    }

    display: flex;
    border-bottom: 1px solid var(--color-medium-grey);
    .modify {
      color: var(--color-pink);
      cursor: pointer;
      > :last-child {
        margin: auto 5px;
      }

      img {
        width: 15px;
      }
    }
  }

  .three-columns {
    div:first-child {
      @media (min-width: 768px) {
        width: 30%;
        min-height: 100px;
      }

      padding: 1rem;
    }
    div:nth-child(2) {
      @media (min-width: 768px) {
        width: 50%;
      }
      padding: 1rem;
    }
    div:last-child {
      @media (min-width: 768px) {
        width: 20%;
      }

      padding: 1rem;
    }
  }

  .bold {
    font-family: bold;
  }
  .box {
    background: white;
  }
  .back {
    margin: 20px 0;
  }
  h1,
  h2,
  h3,
  h4 {
    color: var(--color-primary);
  }

  h3 {
    font-size: 20px;
  }

  .form {
    padding: 10px 20%;
    background-color: var(--color-back-grey);
  }

  @media (max-width: 1200px) {
    .form,
    .overview {
      padding: 10px 10%;
    }
  }

  .marginTop {
    margin-top: 10px;
  }
  .marginMinTop {
    margin-top: -5%;
  }
  .margin30 {
    margin: 30px 0;
  }
  .add_location_button {
    display: flex;
    .button {
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
  }
  .flex {
    display: flex;
  }
  .spaceBetween {
    justify-content: space-between;
  }
  .middle > span {
    margin: auto 0;
  }
  .divider {
    background-color: var(--color-back-grey);
    height: 100px;
  }
`;
export const StyledBlock = styled.div`
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    width: 80%;
  }
`;
