import React from "react";
import Error from "../../components/containers/Error";
import Page from "../../components/containers/Page";
import FlexBox from "../../components/containers/FlexBox";
import Wizard from "../../components/wizard/wizard";
import Intro from "../../components/intro/Intro";
import Text from "../../components/text/Text";
import Input from "../../components/inputs/Input";
import Button from "../../components/buttons/Button";
import CodeViewModel from "./Code.VM";
import { CodeForm } from "./Code.Components";
import MarginBox from "../../components/containers/MarginBox";

const Code = () => {
  const { viewModel } = CodeViewModel();
  const textSteps = ["ACCOUNT", "COMPANY_DETAILS", "MODULES"];
  return (
    <Page>
      {" "}
      <Wizard done={40} textSteps={textSteps}></Wizard>
      <FlexBox gap={"0px"}>
        <Intro title="YOUR_PRICES_TITLE" text="YOUR_PRICES_TEXT" />

        <CodeForm
          onSubmit={async (event) => {
            event.preventDefault();
            viewModel.sendCode();
          }}
        >
          <h1>
            <Text value="FILL_CODE" />
          </h1>
          <MarginBox bottom={"20px"}>
            <Text value="FILL_CODE_TEXT" />
          </MarginBox>
          <Input
            placeholder="PLACEHOLDER_CODE"
            value={viewModel.code}
            onChange={(e) => {
              if (!viewModel.isBusy) viewModel.setCode(e.target.value);
            }}
          />
          <Error value={viewModel.pageError} />
          <Error value={viewModel.error} />
          <Button width="45%">
            <Text value="NEXT" />
          </Button>
          {/* <Back name="BACK" /> */}
        </CodeForm>
      </FlexBox>
    </Page>
  );
};

export default Code;
