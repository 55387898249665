import { useContext, useState } from "react";
import { WebServiceContext } from "../../global/WebService/WebServiceState";
import { EmailAddressRegEx } from "../../core/RegEx";

const ForgotPasswordViewModel = () => {
  const { identityWebService, error } = useContext(WebServiceContext);

  const [pageError, setPageError] = useState("");
  const [email, setEmail] = useState("");

  const [success, setSuccess] = useState(false);
  const [timer, setTimer] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [isBusy, setIsBusy] = useState(false);
  async function forgotPassword() {
    if (!email.match(EmailAddressRegEx)) {
      setEmailError("INVALID_EMAIL_ADDRESS");
      return;
    }
    if (!isBusy) {
      setSuccess(false);
      setIsBusy(true);
      setTimer(true);
      setTimeout(function () {
        setTimer(false);
      }, 30.0 * 1000);
      // storageProvider.clear();
      const response = await identityWebService.forgotPassword(email);
      if (response === "Accepted") {
        setSuccess(true);
      }
      // if (userProvider.initRoles(await identityWebService.postLogin(username, password))) navigate("/home");
      // else setPageError("ERROR_NO_ROLES");

      setIsBusy(false);
    }
  }

  return {
    viewModel: {
      forgotPassword: forgotPassword,
      success: success,
      email: email,
      timer: timer,
      setEmail: setEmail,
      emailError: emailError,
      setEmailError: setEmailError,
      pageError: pageError,
      setPageError: setPageError,
      error: error,
      isBusy: isBusy,
    },
  };
};

export default ForgotPasswordViewModel;
