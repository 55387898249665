import { useContext, useState } from "react";
import { ModalContext } from "../../global/Modal/ModalState";
import { StorageContext } from "../../global/Storage/StorageState";

const CodeViewModel = () => {
  const { modalProvider } = useContext(ModalContext);
  const { storageProvider } = useContext(StorageContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  const [email, setEmail] = useState("");

  const [companyName, setCompanyName] = useState("");

  const [employeesTotal, setEmployeesTotal] = useState("");
  const [poNumber, setPoNumber] = useState("");

  const [isBusy, setIsBusy] = useState(false);

  async function sendCompanyDetails() {
    if (!isBusy) {
      setIsBusy(true);

      storageProvider.clear();

      // if (userProvider.initRoles(await identityWebService.postLogin(username, password))) navigate("/home");
      // else setPageError("ERROR_NO_ROLES");

      setIsBusy(false);
    }
  }

  return {
    viewModel: {
      sendCompanyDetails: sendCompanyDetails,
      firstName: firstName,
      setFirstName: setFirstName,
      lastName: lastName,
      setLastName: setLastName,
      phone: phone,
      setPhone: setPhone,
      email: email,
      setEmail: setEmail,
      companyName: companyName,
      setCompanyName: setCompanyName,
      employeesTotal: employeesTotal,
      setEmployeesTotal: setEmployeesTotal,
      poNumber: poNumber,
      setPoNumber: setPoNumber,
      isBusy: isBusy,
    },
  };
};

export default CodeViewModel;
