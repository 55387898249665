import React, { useState, useEffect } from "react";
import Button from "../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import Text from "../../components/text/Text";
import { ErrorValidation } from "../../components/containers/Error";
import Page from "../../components/containers/Page";
import FlexBox from "../../components/containers/FlexBox";
import Input from "../../components/inputs/Input";
import { getQueryVariable } from "../../utils/common";
import LoginViewModel from "./Login.VM";

import { LoginForm } from "./Login.Components";
import Intro from "../../components/intro/Intro";
import { SuccessMessage } from "../../components/messages/messages";

const Login = () => {
  const { viewModel } = LoginViewModel();

  let navigate = useNavigate();
  const [rfid, setResetToken] = useState(false);
  useEffect(() => {
    const token = getQueryVariable("rfid");
    setResetToken(token);
    if (token) navigate(`/register?rfid=${token}&cc=${getQueryVariable("cc")}`);
  }, []);

  return (
    <Page>
      <FlexBox gap={"0px"}>
        <Intro title="WELCOME_CLIENT_PORTAL_TITLE" text="WELCOME_CLIENT_PORTAL_TEXT" subText={["BENEFIT_1", "BENEFIT_2", "BENEFIT_3"]} />
        <LoginForm
          onSubmit={(event) => {
            event.preventDefault();
            viewModel.onLogin();
          }}
        >
          <h1>
            <Text value="LOGIN_TITLE" />
          </h1>

          {viewModel.successResetPassword && <SuccessMessage name="RESET_PASSWORD_SUCCESS" />}

          <Input
            placeholder="LOGIN_PLACEHOLDER_USERNAME"
            value={viewModel.username}
            required={false}
            onChange={(e) => {
              if (!viewModel.isBusy) viewModel.setUsername(e.target.value);
              viewModel.setUsernameError("");
              viewModel.error && viewModel.setError(false);
            }}
            error={viewModel.usernameError === "usernameError" ? "MANDATORY_INPUT" : false}
          />
          <Input
            placeholder="LOGIN_PLACEHOLDER_PASSWORD"
            type="password"
            required={false}
            value={viewModel.password}
            onChange={(e) => {
              if (!viewModel.isBusy) viewModel.setPassword(e.target.value);
              viewModel.setPasswordError("");
              viewModel.error && viewModel.setError(false);
            }}
            error={viewModel.passwordError === "passwordError" ? "MANDATORY_INPUT" : false}
          />
          <ErrorValidation value={viewModel.error} />
          <Button width="38%">
            <Text value="LOGIN_BUTTON_SUBMIT" />
          </Button>
          <div onClick={() => navigate("/forgotpassword")} className="margin10">
            <div className="whiteLink">
              <Text value="FORGOT_PASSWORD" />
            </div>
          </div>
          {/* <div className="separator" />
          <h1>
            <Text value="MAKE_ACCOUNT" />
          </h1>
          <Text value="MAKE_ACCOUNT_TEXT" />
          <Button width="58%" styleType={4} className="margin10" onClick={() => navigate("/registration")}>
            <Text value="NEW_REGISTRATION" />
          </Button> */}
        </LoginForm>
      </FlexBox>
    </Page>
  );
};

export default Login;
