import React from 'react';
import styled from 'styled-components';

import Text from '../../components/text/Text';

const Checkbox = ({ label, id, name, onChange, required = false, error, link, color }) => {
  return (
    <Container>
      <Text value={label} />
      <CheckMark color={color} error={error}>
        <input type="checkbox" id={id} name={name} onChange={onChange} required={required} />
        <span />
      </CheckMark>

      {link && (
        <a className="link" href={"https://www.persoonlijkegezondheidscheck.nl/"} rel="noreferrer" target="_blank">
          {<Text value={link} />}
        </a>
      )}
    </Container>
  );
};

export default Checkbox;

const Container = styled.label`
  display: flex;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  .link {
    color: var(--color-white);
    text-decoration: underline;
  }
`;

const mark_check =
  "PHN2ZyBpZD0idGlja2VkX2NoZWNrYm94IiAgZGF0YS1uYW1lPSJ0aWNrZWQgY2hlY2tib3giIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE2Ljc4IiBoZWlnaHQ9IjE2Ljc4IiB2aWV3Qm94PSIwIDAgMjEuNDggMjEuNDgiPgogIDxwYXRoIGlkPSJQYXRoXzE5NCIgZGF0YS1uYW1lPSJQYXRoIDE5NCIgZD0iTTE2My4xNTMsNjQuNDIyYTEuMTMxLDEuMTMxLDAsMSwxLDEuNzY2LDEuNDEzbC05LjA0NCwxMS4zMDVhMS4xMzEsMS4xMzEsMCwwLDEtMS42ODIuMDkzTDE0OS42Nyw3Mi43MWExLjEzMSwxLjEzMSwwLDEsMSwxLjYtMS42bDMuNjI5LDMuNjI5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE0My42ODcgLTYzLjk5NykiIGZpbGw9IiNmZmYiLz4KICA8cGF0aCBpZD0iUGF0aF8xOTUiIGRhdGEtbmFtZT0iUGF0aCAxOTUiIGQ9Ik01OS42MjgsMTE2LjI3N2ExLjEzLDEuMTMsMCwxLDEsMi4yNjEsMHY2LjM1OWEzLjI1LDMuMjUsMCwwLDEtMy4yNSwzLjI1SDQ1LjkyYTMuMjUsMy4yNSwwLDAsMS0zLjI1LTMuMjVWMTA5LjkxOGEzLjI1LDMuMjUsMCwwLDEsMy4yNS0zLjI1aDYuODg5YTEuMTMsMS4xMywwLDEsMSwwLDIuMjYxSDQ1LjkyYS45ODkuOTg5LDAsMCwwLS45ODkuOTg5djEyLjcxOGEuOTg5Ljk4OSwwLDAsMCwuOTg5Ljk4OUg1OC42MzlhLjk4OS45ODksMCwwLDAsLjk4OS0uOTg5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQyLjY3IC0xMDQuNDA3KSIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4K";
const pink_mark =
  "PHN2ZyBpZD0idGlja2VkX2NoZWNrYm94IiAgZGF0YS1uYW1lPSJ0aWNrZWQgY2hlY2tib3giIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE2Ljc4IiBoZWlnaHQ9IjE2Ljc4IiB2aWV3Qm94PSIwIDAgMjEuNDggMjEuNDgiPgogIDxwYXRoIGlkPSJQYXRoXzE5NCIgZGF0YS1uYW1lPSJQYXRoIDE5NCIgZD0iTTE2My4xNTMsNjQuNDIyYTEuMTMxLDEuMTMxLDAsMSwxLDEuNzY2LDEuNDEzbC05LjA0NCwxMS4zMDVhMS4xMzEsMS4xMzEsMCwwLDEtMS42ODIuMDkzTDE0OS42Nyw3Mi43MWExLjEzMSwxLjEzMSwwLDEsMSwxLjYtMS42bDMuNjI5LDMuNjI5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE0My42ODcgLTYzLjk5NykiIGZpbGw9IiNlNzMzNmYiLz4KICA8cGF0aCBpZD0iUGF0aF8xOTUiIGRhdGEtbmFtZT0iUGF0aCAxOTUiIGQ9Ik01OS42MjgsMTE2LjI3N2ExLjEzLDEuMTMsMCwxLDEsMi4yNjEsMHY2LjM1OWEzLjI1LDMuMjUsMCwwLDEtMy4yNSwzLjI1SDQ1LjkyYTMuMjUsMy4yNSwwLDAsMS0zLjI1LTMuMjVWMTA5LjkxOGEzLjI1LDMuMjUsMCwwLDEsMy4yNS0zLjI1aDYuODg5YTEuMTMsMS4xMywwLDEsMSwwLDIuMjYxSDQ1LjkyYS45ODkuOTg5LDAsMCwwLS45ODkuOTg5djEyLjcxOGEuOTg5Ljk4OSwwLDAsMCwuOTg5Ljk4OUg1OC42MzlhLjk4OS45ODksMCwwLDAsLjk4OS0uOTg5WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQyLjY3IC0xMDQuNDA3KSIgZmlsbD0iI2U3MzM2ZiIvPgo8L3N2Zz4K";
const CheckMark = styled.div`
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ span {
    border: white;
  }

  span {
    position: absolute;
    top: 3px;
    left: 0;
    height: 17px;
    width: 17px;
    border: ${(props) => (props.color ? `2px solid var(--color-${props.color})` : props.error ? "2px solid var(--color-pink)" : "2px solid white")};
    border-radius: 4px;
  }

  span:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ span:after {
    display: block;
  }

  span:after {
    width: 100%;
    height: 100%;

    background: url(data:image/svg+xml;base64,${(props) => (props.color ? pink_mark : mark_check)});
    background-repeat: no-repeat;
    border-width: 0 3px 3px 0;
  }
`;
