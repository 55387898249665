import { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";
import { StorageContext } from "../../global/Storage/StorageState";
import { WebServiceContext } from "../../global/WebService/WebServiceState";

const CodeViewModel = () => {
  const { configurationProvider } = useContext(ConfigurationContext);
  const { clientWebService } = useContext(WebServiceContext);

  const { globalId } = useParams();
  const { storageProvider } = useContext(StorageContext);
  const [number, setNumber] = useState(15);
  const [selected, setSelect] = useState("");

  const [products, setProducts] = useState([]);
  const [isBusy, setIsBusy] = useState(false);

  const [dependancies] = useState({
    isBusy: isBusy,
    globalId: globalId,
    clientWebService: clientWebService,
    packages: storageProvider.packages,
  });

  useEffect(() => {
    if (!storageProvider.packages) {
      const fetchData = async function (clientWebService, number) {
        storageProvider.setPackages(await clientWebService.getPackages(number, "NL"));
      };
      if (!dependancies.isBusy || !storageProvider.packages) {
        setIsBusy(true);
        fetchData(dependancies.clientWebService, number);
        setIsBusy(false);
      }
      return () => {};
    }
  }, [dependancies]);

  function selectPackage(globalId) {
    if (globalId === selected.globalId) {
      setSelect("");
    } else setSelect(storageProvider.packages.filter((x) => x.globalId === globalId)[0]);
  }
  function selectProducts(product) {
    if (products.length && products.filter((x) => x.globalId === product.globalId).length) {
      setProducts(products.filter((x) => x.globalId !== product.globalId));
    } else {
      const newProducts = [...products, product];
      setProducts(newProducts);
    }
  }
  return {
    viewModel: {
      packages: storageProvider.packages,
      selected: selected,
      selectPackage: selectPackage,
      products: products,
      selectProducts: selectProducts,
    },
  };
};

export default CodeViewModel;
