import React, { useEffect, useState } from "react";
import Button from "../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import Text from "../../components/text/Text";
import Page from "../../components/containers/Page";
import FlexBox from "../../components/containers/FlexBox";
import ResetPasswordViewModel from "./ResetPassword.VM";
import { ResetPasswordForm } from "./ResetPassword.Components";
import Intro from "../../components/intro/Intro";
import { Back } from "../../components/back/back";
import Password from "../../components/inputs/password";
import { getQueryVariable } from "../../utils/common";
import { ErrorValidation } from "../../components/containers/Error";
import Input from "../../components/inputs/Input";

const ResetPassword = () => {
  const { viewModel } = ResetPasswordViewModel();

  let navigate = useNavigate();

  const [resetToken, setResetToken] = useState(false);
  useEffect(() => {
    const token = getQueryVariable("reset-token");
    setResetToken(token);
    if (!token) navigate("/login");
  }, []);
  return (
    <Page>
      <FlexBox gap={"0px"}>
        <Intro title="WELCOME_CLIENT_PORTAL_TITLE" text="WELCOME_CLIENT_PORTAL_TEXT" subText={["BENEFIT_1", "BENEFIT_2"]} />
        <ResetPasswordForm
          onSubmit={(event) => {
            event.preventDefault();
            viewModel.sendPasswords(resetToken);
          }}
        >
          <h1>
            <Text value="RESET_PASSWORD_TITLE" />
          </h1>

          <Input
            placeholder="LOGIN_PLACEHOLDER_USERNAME"
            value={viewModel.username}
            required
            onChange={(e) => {
              if (!viewModel.isBusy) viewModel.setUsername(e.target.value);
            }}
          />

          <Password
            errorMessage={viewModel.error}
            password={viewModel.state.password}
            passwordCheck={viewModel.state.passwordCheck}
            onChange={(e) => {
              if (!viewModel.isBusy) viewModel.setState({ ...viewModel.state, [e.target.name]: e.target.value });
              viewModel.onEqual(false);
            }}
            onValidationChange={(e) => viewModel.onValidationChange(e)}
            valid={viewModel.valid}
            notEqual={viewModel.notEqual}
          />

          <Button width="48%">
            <Text value="RESET_PASSWORD" />
          </Button>
          <ErrorValidation value={viewModel.error} />

          <Back color="white" name="BACK" onClick={() => navigate("/login")} />
        </ResetPasswordForm>
      </FlexBox>
    </Page>
  );
};

export default ResetPassword;
