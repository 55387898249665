import {useContext, useState} from 'react';

import { useNavigate  } from "react-router-dom";

import {ModalContext} from '../../global/Modal/ModalState';
import {WebServiceContext} from '../../global/WebService/WebServiceState';
import {UserContext} from '../../global/User/UserState';
import {StorageContext} from '../../global/Storage/StorageState';
import { getStepURL, validateDate } from "../../components/progress/ProgressStepBar";
export function getDirectionOnboarding(project) {
  const filteredSteps = project.configurationSteps.data
    .sort((a, b) => a.order - b.order)
    .filter((step) => {
      const createdDate = new Date(step.created);
      return createdDate.getFullYear() < 2000;
    });
  if (filteredSteps.length) return getStepURL(filteredSteps[0].name);
}

const LoginViewModel = () => {
  const navigate = useNavigate();

  const { modalProvider } = useContext(ModalContext);

  const { storageProvider } = useContext(StorageContext);

  const { identityWebService, error, setError, successResetPassword, clientWebService } = useContext(WebServiceContext);
  const { userProvider } = useContext(UserContext);
  const [pageError, setPageError] = useState("");
  const [username, setUsername] = useState("");

  const [usernameError, setUsernameError] = useState("");
  const [password, setPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [isBusy, setIsBusy] = useState(false);

  const compareStartDates = (projects) => {
    if (projects.length === 0) {
      return null;
    }

    let latestProject = projects[0];
    let latestStartDate = new Date(projects[0].startDate);

    for (const project of projects) {
      const { startDate } = project;
      const projectStartDate = new Date(startDate);
      if (projectStartDate > latestStartDate) {
        latestStartDate = projectStartDate;
        latestProject = project;
      }
    }

    return latestProject;
  };

  async function onLogin() {
    if (username === "" || !username) {
      setUsernameError("usernameError");
      return;
    }
    if (password === "" || !password) {
      setPasswordError("passwordError");
      return;
    }

    const response = await identityWebService.postLogin(username, password);

    if (userProvider.initRoles(response)) {
      getProjects(response.access_token);
    } else setPageError("ERROR_NO_ROLES");
  }

  async function getProjects(token) {
    if (!isBusy) {
      setIsBusy(true);

      const response = await clientWebService.getAllProjects(token);

      let project;

      storageProvider.setProjects(response.data);
      if (response.data.length > 1) {
        project = compareStartDates(response.data);
        storageProvider.setProject(project);
      } else {
        project = response.data[0];
        storageProvider.setProject(project);
      }
      if (project) {
        if (project.states.data.some((item) => item.state === 2)) {
          navigate("/home");
        } else if (!project.states.data.some((item) => item.state === 1)) {
          navigate("/welcome");
        } else if (project.states.data.some((item) => item.state === 1) && !project.states.data.some((item) => item.state === 2)) {
          navigate(getDirectionOnboarding(project));
        }

        // navigate("/communication");
        else navigate("/controle");
      } else setPageError("NO_PROJECTS");

      setIsBusy(false);
    }
  }

  async function onInfo(name, description) {
    modalProvider.showModal(name, description);
  }

  return {
    viewModel: {
      onLogin: onLogin,
      onInfo: onInfo,
      successResetPassword: successResetPassword,
      username: username,
      usernameError: usernameError,
      setUsername: setUsername,
      setUsernameError: setUsernameError,
      setError: setError,
      password: password,
      passwordError: passwordError,
      setPassword: setPassword,
      setPasswordError: setPasswordError,
      pageError: pageError,
      setPageError: setPageError,
      error: error,
      isBusy: isBusy,
      getProjects: getProjects,
    },
  };
};

export default LoginViewModel;