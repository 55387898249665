import { useContext, useState } from "react";
import { WebServiceContext } from "../../global/WebService/WebServiceState";
import { StorageContext } from "../../global/Storage/StorageState";
import { useNavigate } from "react-router-dom";
const WelcomeViewModel = () => {
  const { clientWebService, error, info } = useContext(WebServiceContext);
  const navigate = useNavigate();
  const { storageProvider } = useContext(StorageContext);
  const [isBusy, setIsBusy] = useState(false);

  async function start(projectGlobalId) {
    if (!isBusy) {
      setIsBusy(true);
      const response = await clientWebService.postState(projectGlobalId, 1);
      if (response.created) {
        navigate("/communication");
      }
      setIsBusy(false);
    }
  }
  return {
    viewModel: {
      error: error,
      isBusy: isBusy,
      user: info.accounts,
      project: storageProvider.project,
      start: start,
    },
  };
};

export default WelcomeViewModel;
