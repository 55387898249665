import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ConfigurationContext } from "../../global/Configuration/ConfigurationState";
import { StorageContext } from "../../global/Storage/StorageState";
import { WebServiceContext } from "../../global/WebService/WebServiceState";

const OfferViewModel = () => {
  const { clientWebService } = useContext(WebServiceContext);
  const { quoteGlobalId } = useParams();
  const { clientGlobalId } = useParams();
  const { storageProvider } = useContext(StorageContext);
  const [isBusy, setIsBusy] = useState(false);
  const [selectedQuote, setSelectQuote] = useState(false);

  const [dependancies] = useState({
    isBusy: isBusy,
    quoteGlobalId: quoteGlobalId,
    clientGlobalId: clientGlobalId,
    clientWebService: clientWebService,
  });

  useEffect(() => {
    if (!storageProvider.packages) {
      const fetchData = async function (clientWebService, clientGlobalId) {
        storageProvider.setQuotes(await clientWebService.getAllQuotes(clientGlobalId));
      };
      if (!dependancies.isBusy || !storageProvider.packages) {
        setIsBusy(true);
        fetchData(dependancies.clientWebService, clientGlobalId);
        setIsBusy(false);
      }
      return () => {};
    }
  }, [dependancies]);

  function selectQuote(data) {
    setSelectQuote(data);
    const putData = async function (clientWebService, quoteGlobalId) {
      storageProvider.setQuote(await clientWebService.getQuoteData(quoteGlobalId));
    };
    if (!dependancies.isBusy || !storageProvider.packages) {
      setIsBusy(true);
      putData(dependancies.clientWebService, quoteGlobalId);
      setIsBusy(false);
    }
    return () => {};
  }

  function putQuoteAccept(quoteGlobalId) {
    const putData = async function (clientWebService, quoteGlobalId) {
      await clientWebService.putQuoteAccept(quoteGlobalId);
    };
    if (!dependancies.isBusy || !storageProvider.packages) {
      setIsBusy(true);
      putData(dependancies.clientWebService, quoteGlobalId);
      setIsBusy(false);
    }
    return () => {};
  }
  function putQuoteCancel(quoteGlobalId) {
    const putData = async function (clientWebService, quoteGlobalId) {
      await clientWebService.putQuoteCancel(quoteGlobalId);
    };
    if (!dependancies.isBusy || !storageProvider.packages) {
      setIsBusy(true);
      putData(dependancies.clientWebService, quoteGlobalId);
      setIsBusy(false);
    }
    return () => {};
  }

  return {
    viewModel: {
      putQuoteAccept: putQuoteAccept,
      putQuoteCancel: putQuoteCancel,
      quotes: storageProvider.quotes,
      selectQuote: selectQuote,
      selectedQuote: selectedQuote,
      quote: storageProvider.quote,
    },
  };
};

export default OfferViewModel;
